/*

? First, let's import the interface that will describe the shape of our business profile object.

*/

import StateInterface from "../state-interface";

/*

& Now let's describe an initial state for the business profile object. This is the state that will be used when the app first fires up. Think of it like a placeholder.

*/

const business_data: StateInterface["business_profile"] = {
	employee_details: [
		{
			first_name: "",
			last_name: "",
			profile_picture_link: "",
			status: "",
		},
	],
	_id: "",
	title: "",
	slug: "",
	search_metrics: {
		search_string: "",
		search_points: 1,
		search_string_delimiter: " ",
		location_coordinates: [],
		search_filter_hashes: [],
	},
	business_profile_type: "",
	verified: false,
	cover_image: {
		file_id: "",
		url: "",
		video_metadata: {
			duration: 2,
			height: 0,
			width: 0,
		},
		mime_type: "",
		original_file_server_path: "",
		exists: false,
	},
	display_picture_url: {
		file_id: "",
		url: "",
		video_metadata: {
			duration: 2,
			height: 0,
			width: 0,
		},
		mime_type: "",
		original_file_server_path: "",
		exists: false,
	},
	phone: {
		phone_number: "",
		country_code: "",
	},
	email: {
		email: "",
		verified: false,
	},
	is_profile_completed: false,
	precise_location_enabled: false,
	admins: [
		{
			user_id: "",
			access_level: "",
			added_at: "",
		},
	],
	admin_user_details: [
		{
			first_name: "",
			last_name: "",
			profile_picture_link: "",
		},
	],
	about: {
		year_established: 2020,
		short_description: "",
		long_description: "",
		office_address: [],
		website_url: "",
		education: "",
		organization: "",
		designation: "",
	},
	locations_of_operation: [],
	services_offered: [],
	expertise: [],
	why_choose_us: [],
	offers: [],
	hiring: [],
	awards_and_honors: [],
	licences_and_certifications: [],
	achievements: [],
	employees: [],
	no_of_employees: 0,
	faqs: [
		{
			question: "",
			answer: "",
		},
	],
	languages: [
		{
			language: "",
			proficiency: "",
		},
	],
	developer_projects: {
		total_projects: 1,
		completed_projects: 0,
		ongoing_projects: 1,
		total_area: "",
	},
	agent_brokerage: [],
	kyc_info: {
		kyc_status: "",
		kyc_comment: "",
		kyc_profile_type: "",
		kyc_type: "",
		assigned_to: [],
		pan: {
			pan_number: "",
			front_image_file_id: "",
			back_image_file_id: "",
		},
		selected_document: "",
		aadhaar: {
			aadhaar_number: "",
			front_image_file_id: "",
			back_image_file_id: "",
		},
		voter_id: {
			voter_id_number: "",
			front_image_file_id: "",
			back_image_file_id: "",
		},
		driving_license: {
			driving_license_number: "",
			front_image_file_id: "",
			back_image_file_id: "",
		},
		passport: {
			passport_number: "",
			front_image_file_id: "",
			back_image_file_id: "",
		},
		tin_certificate: {
			tin_number: "",
			front_image_file_id: "",
		},
		gst_certificate: {
			gst_number: "",
			front_image_file_id: "",
		},
		license_from_legal_authority: {
			license_number: "",
			front_image_file_id: "",
		},
		memorandum_of_agreement: {
			memorandum_of_agreement_number: "",
			front_image_file_id: "",
		},
		rera_certificate: {
			rera_number: "",
			front_image_file_id: "",
		},
		partnership_deed: {
			partnership_deed_number: "",
			front_image_file_id: "",
		},
		partnership_registration_certificate: {
			partnership_reg_certificate_number: "",
			front_image_file_id: "",
		},
		article_of_association: {
			article_of_association_number: "",
			front_image_file_id: "",
		},
		certificate_of_incorporation: {
			certificate_of_incorporation_number: "",
			front_image_file_id: "",
		},
		llp_agreement: {
			llp_agreement_number: "",
			front_image_file_id: "",
		},
		internal_use_documents: [],
	},
	portfolio: [],
	followers: [
		// {
		//   _id: "",
		//   is_business_profile: false,
		//   user_id: "",
		//   business_profile_id: "",
		//   followed_at: "",
		// },
	],
	following: [
		// {
		//   _id: "",
		//   is_business_profile: false,
		//   user_id: "",
		//   business_profile_id: "",
		//   followed_at: "",
		// },
	],
	recommended_by: [
		{
			is_business_profile: false,
			user_id: "",
			business_profile_id: "",
			message: "",
			recommended_at: "",
		},
	],
	is_subscribed: false,
	saved: [
		{
			entity_type: "",
			entity_id: "",
			saved_at: "",
			saved_by: {
				_id: "",
				is_business_profile: false,
				user_id: "",
				business_profile_id: "",
			},
		},
	],
	saved_is_public: false,
	analytics: {
		views: [
			{
				is_business_profile: false,
				user_id: "",
				business_profile_id: "",
				viewed_at: "",
				is_logged_in: false,
				source: "",
			},
		],
	},
	flags: [],
	saved_searches: [],
	industry_type: "",
	published: true,
	assigned_to: [],
	is_public: true,
	published_at: "",
	deleted: false,
	deleted_at: "",
	created_by: "",
	created_at: "",
	updated_by: "",
	updated_at: "",
	subscription_information: {
		subscription_id: "",
		plan_slug: "",
		end_date: new Date("2100-01-01"),
		active: false,
	},
	allow_in_featured: false,
	created_using_bd_tool: false,
	test: false,
	managed: false
};

export const business_profile = business_data;
export default business_profile;
