/* Common Imports */

import React from "react";

/* Component Imports */

import PropertyLoadingCardMini from "../skeletonLoadingCard/PropertyLoadingCardMini";
import ServiceCard from "./ServiceCardGridMini/ServiceCardGridMini";

/* Styled Components */

const ServiceCardGridView = ({
	ServiceId,
	dispatch,
	session,
	profile_context,
	isEditor,
	source,
	id,
}: {
	ServiceId: any;
	dispatch: any;
	session: any;
	profile_context: any;
	isEditor?: boolean | undefined;
	source?: string;
	id?: string;
}) => {
	const [serviceData, setServiceData] = React.useState<any>({});
	const [loading, setLoading] = React.useState(true);

	React.useEffect(() => {
		if (ServiceId) {
			fetch(process.env.PRODUCTION_API_URL + `services/cards/${ServiceId}`, {
				method: "POST",
				headers: {
					"Content-Type": "text/plain",
				},
			})
				.then((res) => res.json())
				.then((data) => {
					setServiceData(data.service_data);
					setLoading(false);
				});
		}
	}, [ServiceId]);

	if (loading) {
		return <PropertyLoadingCardMini />;
	}
	return (
		<React.Fragment>
			<ServiceCard
				id={id}
				profile_context={profile_context}
				session={session}
				dispatch={dispatch}
				loading={loading}
				_id={serviceData._id}
				slug={serviceData.slug}
				transaction_type={serviceData.transaction_type}
				services_type={serviceData.service_subtype}
				title={serviceData.title}
				images={serviceData.images}
				services_status={serviceData.availability}
				possession_date={serviceData.possession_date}
				location={serviceData.location ? serviceData.location.title : ""}
				developer={serviceData.service_provider ? serviceData.service_provider.name : ""}
				price={serviceData.price}
				price_per_unit={serviceData.price_per_unit}
				area={serviceData.area}
				bhk={serviceData.bhk}
				floors={serviceData.floors}
				no_of_seats={serviceData.no_of_seats}
				saved_content={serviceData.saved_content}
				boosting={serviceData.boost_information}
				created_by={serviceData.created_by}
				show_contact_details={serviceData.show_contact_details}
				source={source}
				published_status={serviceData.status}
				isEditor={isEditor}
			/>
		</React.Fragment>
	);
};

export default ServiceCardGridView;
