/*

? First, let's import the interface that will describe the shape of our search_query object.

*/

import StateInterface from "../state-interface";

/*

& Now let's describe an initial state for the search_filter_metrices object. This is the state that will be used when the app first fires up. Think of it like a placeholder.

*/

export const search_filter_metrices: StateInterface["search_filter_metrices"] = {
	boosted: [0],
	non_boosted: [0],
	filter_hash: "",
	no_of_boosted_results: 0,
	no_of_non_boosted_results: 0,
	total_no_of_results: 0,
	boosted_from_other_locations: [0],
	no_of_boosted_from_other_locations: 0
};
