/* Common Imports */

import { styled } from "@mui/system";

/* Component Imports */

import { Skeleton, Typography } from "@mui/material";

/* Icon Imports */

import { CorporateFareOutlined } from "@mui/icons-material";

/* Styled Components */

const Container = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "row",
	justifyContent: "flex-start",
	alignItems: "center",
	width: "100%",
	height: "100%",
	gap: "0.125rem",
	margin: "0rem 0rem 0rem 0rem",
	/*ipad Mini - 768*/
	[theme.breakpoints.up(theme.breakpoints.values.sm + 167)]: {
		gap: "0.25rem",
	},
}));

const Text = styled(Typography)(({ theme }) => ({
	fontSize: "0.625rem",
	lineHeight: "0.725rem",
	color: theme.palette.text.secondary,
	/*iphone XR - 414*/
	[theme.breakpoints.up(theme.breakpoints.values.xs + 130)]: {
		fontSize: "0.7rem",
		lineHeight: "0.75rem",
	},
	[theme.breakpoints.up("xsPlus")]: {
		fontSize: "0.625rem",
		lineHeight: "0.725rem",
	},
	/*684*/
	[theme.breakpoints.up(theme.breakpoints.values.sm + 84)]: {
		fontSize: "0.75rem",
		lineHeight: "0.75rem",
	},
	/*ipad Mini */
	[theme.breakpoints.up(theme.breakpoints.values.sm + 167)]: {
		fontSize: "0.75rem",
		lineHeight: "0.85rem",
	},
	/* ipad Air*/
	[theme.breakpoints.up(theme.breakpoints.values.sm + 219)]: {
		fontSize: "0.825rem",
		lineHeight: "0.825rem",
	},
	/*860*/
	[theme.breakpoints.up("sm860")]: {
		fontSize: "0.875rem",
		lineHeight: "0.875rem",
	},
	/*910*/
	[theme.breakpoints.up("sm910")]: {
		fontSize: "0.95rem",
		lineHeight: "0.95rem",
	},
}));

const CustomIcon = styled(CorporateFareOutlined)(({ theme }) => ({
	color: theme.palette.text.secondary,
	margin: "0rem 0rem 0rem 0rem",
	fontSize: "0.625rem",
	/*iphone XR - 414*/
	[theme.breakpoints.up(theme.breakpoints.values.xs + 130)]: {
		fontSize: "0.7rem",
	},
	[theme.breakpoints.up("xsPlus")]: {
		fontSize: "0.625rem",
	},
	/*684*/
	[theme.breakpoints.up(theme.breakpoints.values.sm + 84)]: {
		fontSize: "0.75rem",
	},
	/*ipad Mini */
	[theme.breakpoints.up(theme.breakpoints.values.sm + 167)]: {
		fontSize: "0.75rem",
	},
	/* ipad Air*/
	[theme.breakpoints.up(theme.breakpoints.values.sm + 219)]: {
		fontSize: "0.825rem",
	},
	/*860*/
	[theme.breakpoints.up("sm860")]: {
		fontSize: "0.875rem",
	},
	/*910*/
	[theme.breakpoints.up("sm910")]: {
		fontSize: "0.95rem",
	},
}));

const PropertyCardConfig = ({
	loading,
	config,
}: {
	loading: boolean;

	config: string | null | undefined;
}) => {
	if (loading) {
		return (
			<Skeleton
				animation="wave"
				variant="text"
				width="75%"
				height="auto"
				style={{ marginBottom: "0.5rem" }}
			/>
		);
	}

	return (
		<Container>
			<CustomIcon />
			<Text variant="body1">{config}</Text>
		</Container>
	);
};

export default PropertyCardConfig;
