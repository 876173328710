import StateInterface from "../state-interface";

export const bottom_subscribe_buy_beegru_points: StateInterface["bottom_subscribe_buy_beegru_points"] = {
	index: 0,

	packof10: {
		quantity: 0,
		total: 0,
	},
	packof100: {
		quantity: 0,
		total: 0,
	},
	packof500: {
		quantity: 0,
		total: 0,
	},
	packof1000: {
		quantity: 0,
		total: 0,
	},
	subtotal: 0,
	gst: 0,
	grandtotal: 0,
};
