/* Common Imports */

import React from "react";

/* Redux Imports */

import StateInterface from "../../../redux-magic/state-interface";

/* Component Imports */

import { Divider, SvgIcon, Typography, styled, useTheme } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import BeegruButton from "../../common-components/buttons/BeegruButton";

/* Icons / Illustrations */

import PostProperty from "../../../public/images/icons/home/banners/post_property.svg";
import PostService from "../../../public/images/icons/home/banners/post_service.svg";
import { setCreateNewPropertyCurrentPageThunk, setCreateNewServiceCurrentPageThunk } from "../../../redux-magic/store";
import CreatePropertyWithUploadTool from "../../modals/PropertyModal/CreatePropertyWithUploadTool";
import CreateServiceWithUploadTool from "../../modals/ServiceModals/CreateServiceWithUploadTool";

/* Styled Components */

const MainContainer = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "row",
	justifyContent: "space-between",
	alignItems: "space-between",
	width: "100%",
	gap: "2rem",
	padding: "1rem 2rem 1rem 2rem",
	margin: "2.5rem 0rem 1rem 0rem",
	borderRadius: "16px",
	background: theme.palette.mode === "dark" ? "#624936" : "#F5EEE8",
	[theme.breakpoints.down("md")]: {
		padding: "1rem 1.5rem 1rem 1.5rem",
	},
}));

const SectionContainer = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "column",
	justifyContent: "center",
	alignItems: "flex-start",
	width: "100%",
	gap: "1rem",
	[theme.breakpoints.down("sm")]: {
		gap: "0rem",
	},
}));

const MobileContainer = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "row",
	justifyContent: "space-between",
	alignItems: "center",
	width: "100%",
	gap: "1rem",
	[theme.breakpoints.up("md")]: {
		display: "none",
	},
}));

const Title = styled(Typography)(({ theme }) => ({
	fontSize: "1.5rem",
	lineHeight: "1.75rem",
	[theme.breakpoints.down("md")]: {
		fontSize: "1.25rem",
		lineHeight: "1.75rem",
	},
	color: theme.palette.mode === "dark" ? "#FFFFFF" : "#212121",
}));

const SubTtext = styled("li")(({ theme }) => ({
	color: theme.palette.mode === "dark" ? "#FFFFFF" : "#212121",
}));

const ThirdBannerSection = ({
	session,
	profile_context,
	dispatch,
	new_listing_url,
	newPropertyData,
	isStudio,
	newServiceData,
}: {
	session: any;
	profile_context: StateInterface["profile_context"];
	dispatch: Function;
	new_listing_url: StateInterface["new_listing_url"];
	newPropertyData: StateInterface["new_property_data"];
	isStudio: boolean;
	newServiceData: StateInterface["new_service_data"];
}) => {
	const matchesMd = useMediaQuery((theme: { breakpoints: { down: (arg0: string) => any } }) =>
		theme.breakpoints.down("md"),
	);

	const matchesSm = useMediaQuery((theme: { breakpoints: { down: (arg0: string) => any } }) =>
		theme.breakpoints.down("sm"),
	);

	const marginstyle = matchesSm ? "0.5rem 0rem 0rem 0rem" : "0rem 0rem 0rem 0rem";

	const theme = useTheme();

	/* Property modal handlelick */

	const [create, setCreate] = React.useState(false);

	const handleCreateOpen = () => {
		dispatch(setCreateNewPropertyCurrentPageThunk("business_profile"));
		setCreate(true);
	};

	const handleCreateClose = () => {
		setCreate(false);
	};

	/* Service modal handleOpen */

	const [openservice, setOpenService] = React.useState(false);

	const handleOpenNewService = () => {
		dispatch(setCreateNewServiceCurrentPageThunk("business_profile"));
		setOpenService(true);
	};

	const handleCloseNewService = () => {
		setOpenService(false);
	};

	if (session !== null && session.user.dbUser.pages.length > 0) {
		return (
			<MainContainer>
				{/* <CreateServiceModalWithBP
					open={openservice}
					handleClose={handleCloseNewService}
					creator_user_id={profile_context ? profile_context.user_id : ""}
					business_profile_id={profile_context ? profile_context.business_profile_id : ""}
					dispatch={dispatch}
					new_listing_url={new_listing_url ? new_listing_url : ""}
					profile_context={profile_context}
					session={session}
				/> */}
				<CreateServiceWithUploadTool
					open={openservice}
					handleClose={handleCloseNewService}
					dispatch={dispatch}
					new_listing_url={new_listing_url ? new_listing_url : ""}
					session={session}
					newServiceData={newServiceData}
				/>
				<SectionContainer>
					<Title variant="h6">Are you a service provider?</Title>

					{/* Mobile Illustration */}

					<MobileContainer>
						<ul
							style={{
								display: "flex",
								flexDirection: "column",
								justifyContent: "center",
								alignItems: "flex-start",
								gap: "0.5rem",
								fontSize: "0.875rem",
								lineHeight: "1.45rem",
								margin: marginstyle,
								padding: "0rem 0rem 0rem 1.5rem",
							}}
						>
							<SubTtext>List your service for free</SubTtext>
							<SubTtext>Connect directly with clients</SubTtext>
							<SubTtext>Better reach for your business</SubTtext>
						</ul>

						<SvgIcon
							component={PostService}
							inheritViewBox={false}
							viewBox="0 0 130 118"
							sx={{
								width: "125px",
								height: "150px",
								[theme.breakpoints.up("sm")]: {
									display: "none",
								},
							}}
						/>
					</MobileContainer>

					<ul
						style={{
							display: matchesMd ? "none" : "flex",
							flexDirection: "row",
							justifyContent: "center",
							alignItems: "flex-start",
							gap: "1.5rem",
							margin: "0rem",
							padding: "0rem 0rem 0rem 0rem",
							listStyleType: "none",
						}}
					>
						<SubTtext>List your service for free</SubTtext>
						<Divider
							orientation="vertical"
							flexItem
						/>
						<SubTtext>Connect directly with clients</SubTtext>
						<Divider
							orientation="vertical"
							flexItem
						/>
						<SubTtext>Better reach for your business</SubTtext>
					</ul>

					<BeegruButton
						flavor="primary"
						variant="outlined"
						size="large"
						sx={{
							marginTop: "1rem",
							[theme.breakpoints.down("md")]: {
								marginTop: "0rem",
							},
							[theme.breakpoints.down("sm")]: {
								width: "100%",
								marginTop: "1rem",
							},
						}}
						onClick={handleOpenNewService}
					>
						Post service
					</BeegruButton>
				</SectionContainer>

				{/* Desktop Illustration */}

				<SvgIcon
					component={PostService}
					inheritViewBox={false}
					viewBox="0 0 130 118"
					sx={{
						width: "18rem",
						height: "10rem",
						[theme.breakpoints.down("md")]: {
							marginTop: "1rem",
							width: "14rem",
							height: "12rem",
						},
						[theme.breakpoints.down("sm")]: {
							display: "none",
						},
					}}
				/>
			</MainContainer>
		);
	} else {
		return (
			<MainContainer>
				<CreatePropertyWithUploadTool
					open={create}
					handleClose={handleCreateClose}
					dispatch={dispatch}
					new_listing_url={new_listing_url ? new_listing_url : ""}
					session={session}
					newPropertyData={newPropertyData}
					studio={isStudio}
				/>
				<SectionContainer>
					<Title variant="h6">Trouble finding clients for your property?</Title>

					{/* Mobile Illustration */}

					<MobileContainer>
						<ul
							style={{
								display: "flex",
								flexDirection: "column",
								justifyContent: "center",
								alignItems: "flex-start",
								gap: "0.5rem",
								fontSize: "0.875rem",
								lineHeight: "1.45rem",
								margin: marginstyle,
								padding: "0rem 0rem 0rem 1.5rem",
							}}
						>
							<SubTtext>Post your property for free</SubTtext>
							<SubTtext>Get in touch with clients directly</SubTtext>
							<SubTtext>Get better reach</SubTtext>
						</ul>

						<SvgIcon
							component={PostProperty}
							inheritViewBox={false}
							viewBox="0 0 220 130"
							sx={{
								width: "125px",
								height: "120px",
								[theme.breakpoints.up("sm")]: {
									display: "none",
								},
							}}
						/>
					</MobileContainer>

					<ul
						style={{
							display: matchesMd ? "none" : "flex",
							flexDirection: "row",
							justifyContent: "center",
							alignItems: "flex-start",
							gap: "1.5rem",
							margin: "0rem",
							padding: "0rem 0rem 0rem 0rem",
							listStyleType: "none",
						}}
					>
						<SubTtext>Post your property for free</SubTtext>
						<Divider
							orientation="vertical"
							flexItem
						/>
						<SubTtext>Get in touch with clients directly</SubTtext>
						<Divider
							orientation="vertical"
							flexItem
						/>
						<SubTtext>Get better reach</SubTtext>
					</ul>

					<BeegruButton
						flavor="primary"
						variant="outlined"
						size="large"
						sx={{
							marginTop: "1rem",
							[theme.breakpoints.down("md")]: {
								marginTop: "0rem",
							},
							[theme.breakpoints.down("sm")]: {
								width: "100%",
								marginTop: "1rem",
							},
						}}
						onClick={handleCreateOpen}
					>
						Post property
					</BeegruButton>
				</SectionContainer>

				{/* Desktop Illustration */}

				<SvgIcon
					component={PostProperty}
					inheritViewBox={false}
					viewBox="0 0 220 130"
					sx={{
						width: "20rem",
						height: "10rem",
						[theme.breakpoints.down("md")]: {
							width: "15rem",
							height: "12rem",
						},
						[theme.breakpoints.down("sm")]: {
							display: "none",
						},
					}}
				/>
			</MainContainer>
		);
	}
};

export default ThirdBannerSection;
