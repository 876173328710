/* Common Imports */

import React from "react";

/* Redux Imports */

import StateInterface from "../../../redux-magic/state-interface";

/* Component Imports */

import { SvgIcon, Typography, styled, useTheme } from "@mui/material";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";

/* Icons / Illustrations */

import { signIn } from "next-auth/react";
import { useRouter } from "next/router";
import ExplorePossibilities from "../../../public/images/icons/home/banners/get_into_real_estate.svg";
import PostProperty from "../../../public/images/icons/home/banners/post_property_mini_banner.svg";
import PostService from "../../../public/images/icons/home/banners/post_service_new.svg";
import { setCreateNewPropertyCurrentPageThunk, setCreateNewServiceCurrentPageThunk } from "../../../redux-magic/store";
import CreatePropertyWithUploadTool from "../../modals/PropertyModal/CreatePropertyWithUploadTool";
import CreateServiceWithUploadTool from "../../modals/ServiceModals/CreateServiceWithUploadTool";

/* Styled Components */

const MainContainer = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "column",
	justifyContent: "center",
	alignItems: "center",
	width: "100%",
	gap: "0.5rem",
	margin: "0rem 0rem 0rem 0rem",
	[theme.breakpoints.up("sm")]: {
		display: "none",
	},
}));

const CarouselCard = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "column",
	justifyContent: "center",
	alignItems: "center",
	width: "100%",
	height: "100%",
}));

const BannerContainer = styled("div", {
	shouldForwardProp: (prop) => prop !== "locale",
})<{ locale: string }>(({ theme, locale }) => ({
	display: "flex",
	flexDirection: "row",
	direction: locale === "ar-AE" ? "rtl" : "ltr",
	justifyContent: "space-between",
	alignItems: "flex-end",
	width: "100%",
	minHeight: "5.125rem",
	height: "auto",
	gap: "0.5rem",
	margin: "0rem 0rem 2rem 0rem",
	borderRadius: "16px",
	background:
		locale === "ar-AE"
			? "linear-gradient(90deg, rgba(252, 128, 25, 0.00) 0%, rgba(252, 128, 25, 0.19) 100%)"
			: "linear-gradient(90deg, rgba(252, 128, 25, 0.19) 0%, rgba(252, 128, 25, 0.00) 100%)",
}));

const CarouselComponent = styled(Carousel)(({ theme }) => ({
	width: "100%",
	height: "100%",
	position: "relative",
	gap: "0rem",
	"& .carousel .control-dots": {
		position: "absolute",
		bottom: "0.25rem", // Move dots below the banner
		left: "50%",
		transform: "translateX(-50%)",
		margin: "0rem 0rem 0rem 0rem",
	},
	"& .carousel .control-dots .dot": {
		backgroundColor: "#d9d9d9",
		opacity: 1,
		margin: "0rem 0.25rem 0rem 0.25rem",
		boxShadow: "none",
		height: "0.5rem",
		width: "0.5rem",
	},
	"& .carousel .control-dots .dot.selected": {
		backgroundColor: "#a7a7a7",
		height: "0.65rem",
		width: "0.65rem",
	},
	"& .carousel .slide": {
		textAlign: "left",
	},
}));

const TextContainer = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "column",
	justifyContent: "flex-start",
	alignItems: "flex-start",
	width: "100%",
	height: "100%",
	gap: "0.25rem",
	padding: "1rem 1rem 1rem 1rem",
}));

const Title = styled(Typography)(({ theme }) => ({
	fontSize: "0.875rem",
	lineHeight: "1rem",
	color: theme.palette.mode === "dark" ? "#FFFFFF" : "#212121",
	fontWeight: 500,
}));

const SubTtext = styled(Typography)(({ theme }) => ({
	fontSize: "0.675rem",
	lineHeight: "0.95rem",
	color: theme.palette.mode === "dark" ? "#FFFFFF" : "#212121",
}));

const MiniBanner = ({
	session,
	dispatch,
	search_filter_state,
	searchanimationsettings,
	new_listing_url,
	newPropertyData,
	isStudio,
	newServiceData,
}: {
	session: any;
	dispatch: Function;
	search_filter_state: StateInterface["search_filters_state"];
	searchanimationsettings: StateInterface["search_animation_settings"];
	new_listing_url: StateInterface["new_listing_url"];
	newPropertyData: StateInterface["new_property_data"];
	isStudio: boolean;
	newServiceData: StateInterface["new_service_data"];
}) => {
	const theme = useTheme();

	const router = useRouter();

	const [autoplay, setAutoplay] = React.useState(true);

	const [create, setCreate] = React.useState(false);

	const handleCreateOpen = () => {
		dispatch(setCreateNewPropertyCurrentPageThunk("business_profile"));
		setCreate(true);
	};

	const handleCreateClose = () => {
		setCreate(false);
	};

	const [openservice, setOpenService] = React.useState(false);

	const handleOpenNewService = () => {
		dispatch(setCreateNewServiceCurrentPageThunk("business_profile"));
		setOpenService(true);
	};

	const handleCloseNewService = () => {
		setOpenService(false);
	};

	const pathname = React.useRef(router).current;

	/*

		* pathname create property trigger state to open

	*/

	React.useEffect(() => {
		if (pathname.query.create_property === "true" && session === true) {
			session && session.authorized ? setCreate(true) : signIn();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [session]);

	/*

		* pathname create service trigger service state to open

	*/

	React.useEffect(() => {
		if (pathname.query.create_service === "true" && session === true) {
			session && session.authorized ? setOpenService(true) : signIn();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [session]);

	/*

	* Close state and remove query from url

	*/

	React.useEffect(() => {
		if (pathname.query.create_property === "true" && session === true && create === false) {
			window.history.pushState({ urlPath: "/?create_property=true" }, "", "/");
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [create]);

	React.useEffect(() => {
		if (pathname.query.create_service === "true" && session === true && openservice === false) {
			window.history.pushState({ urlPath: "/?create_service=true" }, "", "/");
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [openservice]);

	const banners = [
		{
			title:
				router.locale === "ar-AE"
					? "سوق العقارات"
					: router.locale === "kn-IN"
						? "ರಿಯಲ್ ಎಸ್ಟೇಟ್ ಮಾರುಕಟ್ಟೆ"
						: "Real estate marketplace",
			subtitle:
				router.locale === "ar-AE"
					? "اكتشف الطريقة الأذكى للتنقل في عالم العقارات معنا!"
					: router.locale === "kn-IN"
						? "ಆಧುನಿಕ ರೀತಿಯಲ್ಲಿ ರಿಯಲ್ ಎಸ್ಟೇಟ್ ಅನ್ನು ಉತ್ತಮವಾಗಿ ಅನ್ವೇಷಿಸಲು ನಮ್ಮೊಂದಿಗೆ ಕೈಜೋಡಿಸಿ"
						: "Discover the smarter way to navigate real estate with us!",
			icon: ExplorePossibilities,
		},
		{
			title:
				router.locale === "ar-AE"
					? "نشر عقارك"
					: router.locale === "kn-IN"
						? "ನಿಮ್ಮ ಆಸ್ತಿ ಪೋಸ್ಟ್ ಮಾಡಿ"
						: "Post your property",
			subtitle:
				router.locale === "ar-AE"
					? "بع أو أجر عقارك على منصتنا!"
					: router.locale === "kn-IN"
						? "ನಮ್ಮ ವೇದಿಕೆಯಲ್ಲಿ ನಿಮ್ಮ ಆಸ್ತಿಯನ್ನು ಮಾರಾಟ ಮಾಡಿ ಅಥವಾ ಬಾಡಿಗೆಗೆ ನೀಡಿ!"
						: "Sell or rent out your property on our platform!",
			icon: PostProperty,
		},
		{
			title:
				router.locale === "ar-AE"
					? "انشر خدمتك"
					: router.locale === "kn-IN"
						? "ನಿಮ್ಮ ಸೇವೆಯನ್ನು ಪೋಸ್ಟ್ ಮಾಡಿ"
						: "Post your service",
			subtitle:
				router.locale === "ar-AE"
					? "هل أنت مقدم خدمة؟ انشر خدمتك على منصتنا!"
					: router.locale === "kn-IN"
						? "ನೀವು ಸೇವಾ ಪೂರೈಕೆದಾರರಾ? ನಮ್ಮ ವೇದಿಕೆಯಲ್ಲಿ ನಿಮ್ಮ ಸೇವೆಯನ್ನು ಪೋಸ್ಟ್ ಮಾಡಿ!"
						: "Are you a service provider? Post your service on our platform!",
			icon: PostService,
		},
	];

	const handleBannerClick = (index: number) => {
		if (index === 0) {
			window.location.href = "loading.html?callbackUrl=" + encodeURIComponent("/" + router.locale + "/intro");
		} else if (index === 1) {
			// router.push("/?create_property=true");
			session !== null
				? handleCreateOpen()
				: router
						.push(
							{
								pathname: encodeURI("/"),
								query: { create_property: "true" },
							},
							{
								pathname: encodeURI("/"),
								query: { create_property: "true" },
							},
							{ locale: router.locale },
						)
						.then(() => {
							signIn();
						});
		} else if (index === 2) {
			// router.push("/?create_service=true");
			session !== null
				? handleOpenNewService()
				: router
						.push(
							{
								pathname: encodeURI("/"),
								query: { create_service: "true" },
							},
							{
								pathname: encodeURI("/"),
								query: { create_service: "true" },
							},
							{ locale: router.locale },
						)
						.then(() => {
							signIn();
						});
		}
	};

	return (
		<MainContainer>
			<CreatePropertyWithUploadTool
				open={create}
				handleClose={handleCreateClose}
				dispatch={dispatch}
				new_listing_url={new_listing_url ? new_listing_url : ""}
				session={session}
				newPropertyData={newPropertyData}
				studio={isStudio}
			/>

			<CreateServiceWithUploadTool
				open={openservice}
				handleClose={handleCloseNewService}
				dispatch={dispatch}
				new_listing_url={new_listing_url ? new_listing_url : ""}
				session={session}
				newServiceData={newServiceData}
			/>

			<CarouselComponent
				autoPlay={autoplay}
				showArrows={false}
				showThumbs={false}
				swipeable={true}
				showStatus={false}
				infiniteLoop={true}
				interval={3000}
				showIndicators={true}
				onSwipeStart={() => setAutoplay(false)}
				onSwipeEnd={() => setAutoplay(true)}
			>
				{banners.map((banner, index) => (
					<CarouselCard
						key={index}
						onClick={() => {
							handleBannerClick(index);
						}}
					>
						<BannerContainer locale={router.locale ? router.locale : "en-IN"}>
							<TextContainer>
								<Title
									variant="h6"
									sx={{
										fontWeight: router.locale === "ar-AE" ? 600 : 500,
									}}
								>
									{banner.title}
								</Title>
								<SubTtext
									sx={{
										textAlign: router.locale === "ar-AE" ? "right" : "left",
									}}
								>
									{banner.subtitle}
								</SubTtext>
							</TextContainer>

							<SvgIcon
								component={banner.icon}
								inheritViewBox={false}
								viewBox={index === 0 ? "0 0 141 71" : index === 1 ? "0 0 136 93" : "0 0 128 109"}
								sx={{
									width: index === 0 ? "8rem" : index === 1 ? "7rem" : "6rem",
									height: index === 0 ? "4rem" : index === 1 ? "4rem" : "4.5rem",
								}}
							/>
						</BannerContainer>
					</CarouselCard>
				))}
			</CarouselComponent>
		</MainContainer>
	);
};

export default MiniBanner;
