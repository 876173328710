/* Common Imports */

import { styled } from "@mui/system";
import React, { useRef } from "react";

/* Redux Imports */

import LanguageIcon from "@mui/icons-material/Language";
import StateInterface from "../../redux-magic/state-interface";
/* Component Imports */

import {
	AppBar,
	FormControlLabel,
	Link,
	Menu,
	MenuItem,
	PopperPlacementType,
	Stack,
	SvgIcon,
	Switch,
	Toolbar,
	Typography,
	useTheme,
} from "@mui/material";
import Popover from "@mui/material/Popover";
import BeegruButton from "../common-components/buttons/BeegruButton";
import ColorModeContext from "../layout-components/contexts/colorModeContext";
import CreateNewOFBWithUploadTool from "../modals/PropertyModal/CreateNewOFBWithUploadTool";
import CreatePropertyWithUploadTool from "../modals/PropertyModal/CreatePropertyWithUploadTool";
import CreateServiceWithUploadTool from "../modals/ServiceModals/CreateServiceWithUploadTool";

import Notifications from "./components/Notifications";
import UserMenu from "./components/UserMenu";

/* Icon Imports */

import dynamic from "next/dynamic";
import PostOFB from "../../public/images/icons/home/post_ofb.svg";
import PostProperty from "../../public/images/icons/home/post_property.svg";
import PostService from "../../public/images/icons/home/post_service.svg";

/* Authentication Imports */

import { signOut } from "next-auth/react";
const RecoveryEmailSnackbar = dynamic(() =>
	import("../page-sections/Index/RecoveryEmailSnackbar").then((mod) => mod.default),
);

/* Routing Imports */

import { AddOutlined } from "@mui/icons-material";
import { useRouter } from "next/router";
import BeegruLogoDark from "../../public/images/logo/logo-white.svg";
import BeegruLogoLight from "../../public/images/logo/logo.svg";
import BeegruLogoArabicLight from "../../public/images/logo/logo_arabic.svg";
import BeegruLogoKannadaLight from "../../public/images/logo/logo_kannada.svg";
import {
	setCreateNewPropertyCurrentPageThunk,
	setCreateNewPropertyTransactionTypeThunk,
	setCreateNewServiceCurrentPageThunk,
} from "../../redux-magic/thunks";

/* Styled Component */

const MaterialUISwitch = styled(Switch)(({ theme }) => ({
	width: 62,
	height: 34,
	padding: 7,
	"& .MuiSwitch-switchBase": {
		margin: 1,
		padding: 0,
		transform: "translateX(6px)",
		"&.Mui-checked": {
			color: "#FFFFFF",
			transform: "translateX(22px)",
			"& .MuiSwitch-thumb:before": {
				backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 20 20"><path fill="${encodeURIComponent(
					theme.palette.primary.main,
				)}" d="M4.2 2.5l-.7 1.8-1.8.7 1.8.7.7 1.8.6-1.8L6.7 5l-1.9-.7-.6-1.8zm15 8.3a6.7 6.7 0 11-6.6-6.6 5.8 5.8 0 006.6 6.6z"/></svg>')`,
			},
			"& + .MuiSwitch-track": {
				opacity: 1,
				backgroundColor: theme.palette.secondary.dark,
			},
		},
	},
	"& .MuiSwitch-thumb": {
		backgroundColor: theme.palette.background.paper,
		width: 32,
		height: 32,
		border: `1px solid ${theme.palette.primary.main}`,
		borderRadius: "12px",
		boxShadow: "none",
		"&:before": {
			content: "''",
			position: "absolute",
			width: "100%",
			height: "100%",
			left: 0,
			top: 0,
			backgroundRepeat: "no-repeat",
			backgroundPosition: "center",
			backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 20 20"><path fill="${encodeURIComponent(
				theme.palette.primary.main,
			)}" d="M9.305 1.667V3.75h1.389V1.667h-1.39zm-4.707 1.95l-.982.982L5.09 6.072l.982-.982-1.473-1.473zm10.802 0L13.927 5.09l.982.982 1.473-1.473-.982-.982zM10 5.139a4.872 4.872 0 00-4.862 4.86A4.872 4.872 0 0010 14.862 4.872 4.872 0 0014.86 10 4.872 4.872 0 0010 5.139zm0 1.389A3.462 3.462 0 0113.471 10a3.462 3.462 0 01-3.473 3.472A3.462 3.462 0 016.527 10 3.462 3.462 0 0110 6.528zM1.665 9.305v1.39h2.083v-1.39H1.666zm14.583 0v1.39h2.084v-1.39h-2.084zM5.09 13.928L3.616 15.4l.982.982 1.473-1.473-.982-.982zm9.82 0l-.982.982 1.473 1.473.982-.982-1.473-1.473zM9.305 16.25v2.083h1.389V16.25h-1.39z"/></svg>')`,
		},
	},
	"& .MuiSwitch-track": {
		opacity: 1,
		backgroundColor: theme.palette.mode === "dark" ? "#333" : "#AAB4BE",
		borderRadius: "16px",
	},
}));

const SectionContainer = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "row",
	justifyContent: "space-between",
	// padding: "0.5rem 0rem 0.5rem 0rem",
	padding: "0.5rem 4rem 0.5rem 4rem",
	// height: "4rem",
	[theme.breakpoints.down("sm")]: {
		display: "flex",
		flexDirection: "row",
		justifyContent: "space-between",
		alignItems: "space-between",
		// margin: "0rem 0.4rem 0rem 0.4rem",
		padding: "0.5rem 1.25rem 0.5rem 1.25rem",
	},
}));

// const WhiteContainer = styled("div")(({ theme }) => ({
// 	display: "flex",
// 	justifyContent: "center",
// 	alignContent: "center",
// 	padding: "0.75rem 0.875rem 0.75rem 0.875rem",
// 	background: theme.palette.mode === "dark" ? "#424242" : "#FFFFFF",
// 	borderRadius: "16px",
// 	[theme.breakpoints.down("sm")]: {
// 		padding: "0.35rem 0.475rem 0.35rem 0.475rem",
// 	},
// }));

const RowContainer = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "row",
}));

const SocialRow = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "row",
	justifyContent: "space-between",
	alignItems: "center",
	// margin: "0rem 1rem 0rem 0rem",
	gap: "1rem",
	[theme.breakpoints.down("xs")]: {
		display: "flex",
		margin: "0.5rem 0rem 0.5rem 0rem",
	},
}));

const ButtonContain = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "row",
	justifyContent: "center",
	alignItems: "center",
}));

const ContentContainer = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "column",
	justifyContent: "center",
	alignItems: "flex-start",
	width: "100%",
	overflow: "hidden",
	padding: "0.75rem 0.25rem 0rem 0.25rem",
	gap: "0.75rem",
}));

const ContentRow = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "row",
	justifyContent: "flex-start",
	alignItems: "center",
	width: "100%",
	padding: "0rem 0rem 0rem 0rem",
	gap: "0.5rem",
	cursor: "pointer",
	"&:hover": {
		color: theme.palette.primary.main,
	},
}));

const CustomLink = styled(Link)(({ theme }) => ({
	textDecoration: "none",
	fontSize: "1rem",
	fontWeight: 500,
	padding: "0rem 0rem 0rem 0rem",
	color: theme.palette.mode === "dark" ? "#FFFFFF" : "#000",
	"&:hover": {
		color: theme.palette.primary.main,
		cursor: "pointer",
	},
}));

const NameTypeContainer = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "column",
	justifyContent: "space-between",
	alignItems: "flex-start",
	width: "100%",
}));

const Heading = styled(Typography)(({ theme }) => ({
	fontSize: "0.875rem",
	fontWeight: 400,
}));

const TypeTypo = styled(Typography)(({ theme }) => ({
	fontWeight: "400",
	fontSize: "0.75rem",
	color: theme.palette.mode == "dark" ? "#C6C6C6" : "#666666",
}));

const BeegruLogo = ({ mode, locale }: { mode: string; locale: string }) => (
	<Link
		href={`${process.env.PRODUCTION_URL || ""}loading.html?callbackUrl=${encodeURIComponent(
			`${process.env.PRODUCTION_URL || ""}`,
		)}&locale=${locale || "en"}`}
		rel="noopener"
		target="_self"
		referrerPolicy="no-referrer"
		aria-label="Marketplace"
		title="Home"
		sx={{
			padding: "0.5rem 0rem 0rem 0rem",
		}}
	>
		{mode === "dark" ? (
			<BeegruLogoDark
				width={102}
				height={30}
				viewBox="0 0 220 60"
			/>
		) : locale === "ar-AE" ? (
			<BeegruLogoArabicLight
				width={102}
				height={25}
				viewBox="0 0 65 23"
			/>
		) : locale === "kn-IN" ? (
			<BeegruLogoKannadaLight
				width={102}
				height={30}
				// viewBox="0 0 93 31"
			/>
		) : (
			<BeegruLogoLight
				width={102}
				height={30}
				viewBox="0 0 220 60"
			/>
		)}
	</Link>
);

/* Default Export */

const LayoutHeader = ({
	session,
	redux_session_updated,
	dispatch,
	profile_context,
	new_business_profile_url,
	new_listing_url,
	notification_data,
	new_notification,
	newPropertyData,
	studio,
	newServiceData,
}: {
	session?: any;
	redux_session_updated?: boolean;
	dispatch: Function;
	profile_context: StateInterface["profile_context"];
	new_business_profile_url: StateInterface["new_business_profile_url"];
	new_listing_url: StateInterface["new_listing_url"];
	notification_data: StateInterface["notifications_page"];
	new_notification: StateInterface["notification"] | null;
	newPropertyData: StateInterface["new_property_data"];
	studio: StateInterface["is_studio"];
	newServiceData: StateInterface["new_service_data"];
}) => {
	const theme = useTheme();
	const router = useRouter();
	const signIn = () => {
		window.location.href =
			(process.env.PRODUCTION_URL || "") +
			"loading.html?callbackUrl=" +
			encodeURIComponent((process.env.PRODUCTION_URL || "") + "auth/login?callbackUrl=" + window.location.href);
	};
	const [scrollY, setScrollY] = React.useState<number>(0);
	const [negativeScroll, setNegativeScroll] = React.useState<boolean>(true);
	const [anchorEl, setAnchorEl] = React.useState(null);
	const [isiOS, setIsiOS] = React.useState(false);

	const handleClick = (event: any) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	const open = Boolean(anchorEl);
	const id = open ? "simple-popover" : undefined;
	/* Effect Definitions */
	const [scroll, setScroll] = React.useState(0);
	React.useEffect(() => {
		/* First let's define an event listener */

		const handleScroll = () => {
			setScroll(window.scrollY);
			if (window.scrollY < scrollY) {
				setNegativeScroll(true);
			} else {
				setNegativeScroll(false);
			}
			setScrollY(window.scrollY);
			setScroll(window.scrollY);
		};
		/* Now we can add the event listener */

		window.addEventListener("scroll", handleScroll, { passive: true });
		return () => {
			window.removeEventListener("scroll", handleScroll);
		};
	}, [scrollY]);

	React.useEffect(() => {
		function iOS() {
			return (
				["iPad Simulator", "iPhone Simulator", "iPod Simulator", "iPad", "iPhone", "iPod"].includes(
					navigator.platform,
				) ||
				// iPad on iOS 13 detection
				(navigator.userAgent.includes("Mac") && "ontouchend" in document)
			);
		}
		if (iOS()) {
			setIsiOS(true);
		} else {
			setIsiOS(false);
		}
	}, []);

	/*

    * Property modal handlelick

  */

	const [create, setCreate] = React.useState(false);
	const [CreateNewOfbPropertyModals, setCreateNewOfbPropertyModals] = React.useState(false);

	const handleCreateOpen = () => {
		dispatch(
			setCreateNewPropertyCurrentPageThunk(
				router.asPath.split("/")[2] !== undefined ? router.asPath.split("/")[2] : "navbar",
			),
		);
		setCreate(true);
		setOpenPopper(false);
	};

	const handleCreateClose = () => {
		setCreate(false);
	};

	const handleCreateNewOfbPropertyOpen = async () => {
		dispatch(setCreateNewPropertyTransactionTypeThunk("ofb"));
		dispatch(
			setCreateNewPropertyCurrentPageThunk(
				router.asPath.split("/")[2] !== undefined ? router.asPath.split("/")[2] : "navbar",
			),
		);
		setCreateNewOfbPropertyModals(true);
		setOpenPopper(false);
	};

	const handleCreateNewOfbPropertyClose = async () => {
		setCreateNewOfbPropertyModals(false);
	};

	/*

    * Service modal handlelick

  */

	const [openservice, setOpenService] = React.useState(false);

	const handleOpenNewService = () => {
		dispatch(
			setCreateNewServiceCurrentPageThunk(
				router.asPath.split("/")[2] !== undefined ? router.asPath.split("/")[2] : "navbar",
			),
		);
		session === null ? window.open("/auth/login", "_self") : setOpenService(true);
		setOpenPopper(false);
	};

	const handleCloseNewService = () => {
		setOpenService(false);
	};

	/* popper for c*/

	const [anchorElPopper, setAnchorElPopper] = React.useState<HTMLButtonElement | null>(null);
	const [openPopper, setOpenPopper] = React.useState(false);
	const [placementPopper, setPlacementPopper] = React.useState<PopperPlacementType>();

	const handleClickPopper = (newPlacement: PopperPlacementType) => (event: React.MouseEvent<HTMLButtonElement>) => {
		setAnchorElPopper(event.currentTarget);
		setOpenPopper((prev) => placementPopper !== newPlacement || !prev);
		setPlacementPopper(newPlacement);
	};

	/*
	  * language popper states and function

	 */

	const [anchorElPopperLanguage, setAnchorElPopperLanguage] = React.useState<null | HTMLElement>(null);
	const [openPopperLanguage, setOpenPopperLanguage] = React.useState(false);
	const [placementPopperLanguage, setPlacementPopperLanguage] = React.useState<PopperPlacementType>();

	const handleClickPopperLanguage =
		(newPlacement: PopperPlacementType) => (event: React.MouseEvent<HTMLButtonElement>) => {
			setAnchorElPopperLanguage(event.currentTarget);
			setOpenPopperLanguage((prev) => placementPopperLanguage !== newPlacement || !prev);
			setPlacementPopperLanguage(newPlacement);
		};

	const handleCloseLanguage = (locale: string) => {
		setOpenPopperLanguage(false);
		setAnchorElPopperLanguage(null);

		const baseUrl = process.env.PRODUCTION_URL || window.location.origin;
		const callbackUrl = `${baseUrl}${encodeURIComponent(locale || "en-IN")}`;
		const loadingUrl = `${baseUrl}/loading.html?callbackUrl=${encodeURIComponent(callbackUrl)}`;

		window.location.href = loadingUrl;
	};
	/*

    * fix for popper. Close, if clicked anywhere else other than the popper

  */

	const useOutsideAlerter = (ref: any) => {
		React.useEffect(() => {
			/*

        * if clicked on outside the popper element

      */
			function onScroll(event: any) {
				if (ref.current && !ref.current.contains(event.target)) {
					setOpenPopper(false);
				}
			}
			function handleClickOutside(event: any) {
				if (ref.current && !ref.current.contains(event.target)) {
					setOpenPopper(false);
				}
			}
			// Binding event listener for clickoutside
			document.addEventListener("mousedown", handleClickOutside);
			// Binding event listener for scroll
			document.addEventListener("scroll", onScroll);
			return () => {
				// Unbind the event listener on clean up
				document.removeEventListener("mousedown", handleClickOutside);
				document.addEventListener("scroll", onScroll);
			};
		}, [ref]);
	};

	const wrapperRef = useRef(null);

	useOutsideAlerter(wrapperRef);
	const pathname = React.useRef(router).current;
	/*

		* pathname create trigger state to open

	*/
	React.useEffect(() => {
		if (pathname.query.create_property === "true" && redux_session_updated === true && session && session.authorized) {
			handleClickPopper("bottom");
			redux_session_updated === true && session && session.authorized ? setCreate(true) : signIn();
		} else if (
			pathname.query.create_property === "true" &&
			redux_session_updated === true &&
			(!session || !session.authorized)
		) {
			window.history.pushState({ urlPath: "/?create_property=true" }, "", "/");
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [redux_session_updated]);

	/*

		* pathname create service trigger service state to open

	*/
	React.useEffect(() => {
		if (pathname.query.create_service === "true" && redux_session_updated === true && session && session.authorized) {
			handleClickPopper("bottom");
			redux_session_updated === true && session && session.authorized ? setOpenService(true) : signIn();
		} else if (
			pathname.query.create_service === "true" &&
			redux_session_updated === true &&
			(!session || !session.authorized)
		) {
			window.history.pushState({ urlPath: "/?create_service=true" }, "", "/");
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [redux_session_updated]);

	/*

		* pathname create ofb trigger ofb state to open

	*/
	React.useEffect(() => {
		if (pathname.query.create_ofb === "true" && redux_session_updated === true && session && session.authorized) {
			handleClickPopper("bottom");
			redux_session_updated === true && session && session.authorized ? setCreateNewOfbPropertyModals(true) : signIn();
		} else if (
			pathname.query.create_ofb === "true" &&
			redux_session_updated === true &&
			(!session || !session.authorized)
		) {
			window.history.pushState({ urlPath: "/?create_ofb=true" }, "", "/");
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [redux_session_updated]);

	/*

    * Slug state to redirect to particular page

  */

	// const Slug: any = session?.user?.dbUser?.pages
	// 	? session.user.dbUser.pages.find((item: any) => item.page_id === profile_context?.business_profile_id)?.page_slug
	// 	: null;

	// const [userUrl, setUserUrl] = React.useState<string>(
	//   session === null
	//     ? "/auth/login"
	//     : profile_context?.page_type === "user"
	//       ? `/users/${
	//           session?.user?.dbUser?._id ? session?.user?.dbUser?._id : ""
	//         }`
	//       : `/${profile_context?.page_type}s/${Slug}`
	// );

	const [loginDisabled, setLoginDisabled]: [boolean, Function] = React.useState<boolean>(false);

	// React.useEffect(() => {
	// 	const csrfWait = setTimeout(() => {
	// 		setLoginDisabled(false);
	// 	}, 3000);
	// 	return () => clearTimeout(csrfWait);
	// }, []);

	const [isReady, setIsReady] = React.useState(false);
	React.useEffect(() => {
		// Check if the conditions are met
		if (redux_session_updated !== undefined && redux_session_updated === true) {
			setIsReady(true);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [redux_session_updated]);

	const CustomNavChanges = () => {
		/*

		! if else condition or switch or ternary operators, will have to write the same code in the else condition. in this, kinda like destructuring and saving it in variables and returning the variables with or without the whitecontainer. this way redundant code will be excluded.

		*/

		const RenderSocialRow = () => (
			<SocialRow>
				{/* Language */}

				{redux_session_updated && redux_session_updated === true && (
					<BeegruButton
						flavor="primary"
						variant="text"
						size={"medium"}
						onClick={handleClickPopperLanguage("bottom")}
						sx={{
							"& .MuiButton-endIcon": {
								margin: "0rem 0rem 0rem 0rem",
							},

							"& .MuiSvgIcon-root": {
								fontSize: "1.5rem",
							},
							minWidth: "0rem",
						}}
						// startIcon={router.locale === "ar-AE" ? null : <LanguageIcon />}
						endIcon={<LanguageIcon />}
					/>
				)}

				{/* Post */}

				{redux_session_updated && redux_session_updated === true && (
					<BeegruButton
						flavor="success"
						variant={session ? "outlined" : "outlined"}
						size={"medium"}
						sx={{
							"& .MuiButton-endIcon": {
								margin: "0rem 0rem 0rem 0.25rem",
							},
							[theme.breakpoints.down("sm")]: {
								display: "none",
							},
						}}
						// startIcon={<AddOutlined />}
						onClick={handleClickPopper("bottom")}
					>
						{router.locale === "ar-AE" ? "نشر" : router.locale === "kn-IN" ? "ಪೋಸ್ಟ್ " : "Post"}
						<AddOutlined />
					</BeegruButton>
				)}

				{/* Notifications */}

				{redux_session_updated && redux_session_updated === true && session && session.authorized && (
					<Notifications
						notification_data={notification_data}
						profile_context={profile_context}
						session={session}
						dispatch={dispatch}
						new_notification={new_notification}
					/>
				)}

				{/* Dark Mode Switch */}

				{/* {redux_session_updated && redux_session_updated === true && !session && (
					<ColorModeContext.Consumer>
						{({ toggleColorMode }) => (
							<FormControlLabel
								onChange={toggleColorMode}
								control={<MaterialUISwitch checked={theme.palette.mode === "dark"} />}
								label=""
								labelPlacement="start"
								sx={{ width: 39 }}
							/>
						)}
					</ColorModeContext.Consumer>
				)} */}

				{/* Login popover only for Galaxy Fold */}

				<ButtonContain>
					<Stack
						direction="row"
						sx={{
							[theme.breakpoints.up("xs")]: {
								display: "none",
							},
						}}
					>
						<Popover
							id={id}
							open={open}
							anchorEl={anchorEl}
							onClose={handleClose}
							anchorOrigin={{
								vertical: "bottom",
								horizontal: "right",
							}}
							transformOrigin={{
								vertical: "top",
								horizontal: "right",
							}}
						>
							<RowContainer>
								<Typography
									sx={{
										[theme.breakpoints.down("xs")]: {
											display: "flex",
											alignItems: "center",
										},
									}}
								>
									Theme :
								</Typography>
								<ColorModeContext.Consumer>
									{({ toggleColorMode }) => (
										<FormControlLabel
											onChange={toggleColorMode}
											control={<MaterialUISwitch checked={theme.palette.mode === "dark"} />}
											label=""
											labelPlacement="start"
											sx={{ width: 34 }}
										/>
									)}
								</ColorModeContext.Consumer>
							</RowContainer>

							<BeegruButton
								flavor="primary"
								variant="outlined"
								size="small"
								disabled={loginDisabled}
								onClick={() => {
									session && session.authorized
										? signOut({
												callbackUrl:
													process.env.PRODUCTION_API_URL +
													"auth/end-session?sessionId=" +
													encodeURIComponent(session.session_id) +
													"&callbackUrl=" +
													encodeURIComponent(router.asPath),
											})
										: signIn();
								}}
								name="login"
								type="button"
								sx={{
									[theme.breakpoints.down("xs")]: {
										display: "flex",
										alignSelf: "center",
										margin: "0.5rem",
									},
								}}
							>
								{session && session.authorized ? "Logout" : "Login"}
							</BeegruButton>
						</Popover>
					</Stack>

					{redux_session_updated &&
						redux_session_updated === true &&
						(session ? (
							<UserMenu
								session={session}
								dispatch={dispatch}
								profile_context={profile_context}
								new_business_profile_url={new_business_profile_url}
								redux_session_updated={redux_session_updated}
							/>
						) : (
							<BeegruButton
								disabled={loginDisabled}
								flavor="primary"
								variant="outlined"
								size="medium"
								onClick={() => {
									session && session.authorized
										? signOut({
												callbackUrl:
													process.env.PRODUCTION_API_URL +
													"auth/end-session?sessionId=" +
													encodeURIComponent(session.session_id) +
													"&callbackUrl=" +
													encodeURIComponent(router.asPath),
											})
										: signIn();
								}}
								name="login"
								type="button"
								sx={{
									[theme.breakpoints.down("xs")]: {
										display: "none",
									},
								}}
							>
								{session && session.authorized
									? router.locale === "ar-AE"
										? "تسجيل الخروج"
										: router.locale === "kn-IN"
											? "ಲಾಗೌಟ್"
											: "Logout"
									: router.locale === "ar-AE"
										? "تسجيل الدخول"
										: router.locale === "kn-IN"
											? "ಲಾಗಿನ್"
											: "Login"}
							</BeegruButton>
						))}
				</ButtonContain>
			</SocialRow>
		);

		return (
			<React.Fragment>
				<BeegruLogo
					mode={theme.palette.mode}
					locale={router.locale ? router.locale : "en-IN"}
				/>
				{RenderSocialRow()}
			</React.Fragment>
		);
	};

	React.useEffect(() => {
		if (create || openservice || CreateNewOfbPropertyModals) {
			document.body.style.overflow = "hidden";
		} else if (!create || !openservice || !CreateNewOfbPropertyModals) {
			document.body.style.overflow = "auto";
		}
	}, [create, openservice, CreateNewOfbPropertyModals]);

	return (
		<React.Fragment>
			{/* Property Modal */}

			<CreatePropertyWithUploadTool
				open={create}
				handleClose={handleCreateClose}
				dispatch={dispatch}
				new_listing_url={new_listing_url ? new_listing_url : ""}
				session={session}
				newPropertyData={newPropertyData}
				studio={studio}
				pathname={pathname}
			/>

			{/* Service Modal */}

			<CreateServiceWithUploadTool
				open={openservice}
				handleClose={handleCloseNewService}
				dispatch={dispatch}
				new_listing_url={new_listing_url ? new_listing_url : ""}
				session={session}
				newServiceData={newServiceData}
			/>

			{/* OFB Modal */}

			<CreateNewOFBWithUploadTool
				open={CreateNewOfbPropertyModals}
				handleClose={handleCreateNewOfbPropertyClose}
				creator_user_id={profile_context ? profile_context.user_id : ""}
				business_profile_id={profile_context ? profile_context.business_profile_id : ""}
				dispatch={dispatch}
				new_listing_url={new_listing_url ? new_listing_url : ""}
				profile_context={profile_context}
				session={session}
				newPropertyData={newPropertyData}
			/>
			{/* Language */}
			<Menu
				open={openPopperLanguage}
				anchorEl={anchorElPopperLanguage}
				disableScrollLock={true}
				onClose={() => setOpenPopperLanguage(false)}
				slotProps={{
					paper: {
						sx: {
							borderRadius: "1rem",
							background: theme.palette.background.paper,
							padding: "0rem",
							direction: router.locale === "ar-AE" ? "rtl" : "ltr",
						},
					},
				}}
				anchorOrigin={{
					vertical: "bottom",
					horizontal: "right",
				}}
				transformOrigin={{
					vertical: "top",
					horizontal: "right",
				}}
			>
				<MenuItem onClick={() => handleCloseLanguage("en-IN")}>English</MenuItem>
				<MenuItem onClick={() => handleCloseLanguage("kn-IN")}>ಕನ್ನಡ</MenuItem>
				{/*

				! Disabled for kannada release on Tue Jan 14 2025 Uncomment when needed

				*/}
				{/* <MenuItem onClick={() => handleCloseLanguage("ar-AE")}>العربية (ar-AE)</MenuItem> */}
			</Menu>

			{/* Post Menu */}

			<Menu
				open={openPopper}
				anchorEl={anchorElPopper}
				disableScrollLock={true}
				onClose={() => setOpenPopper(false)}
				slotProps={{
					paper: {
						sx: {
							borderRadius: "16px",
							background: theme.palette.background.paper,
							padding: "0.25rem 0.75rem 0.25rem 0.75rem",
							direction: router.locale === "ar-AE" ? "rtl" : "ltr",
						},
					},
				}}
				anchorOrigin={{
					vertical: "bottom",
					horizontal: "right",
				}}
				transformOrigin={{
					vertical: "top",
					horizontal: "right",
				}}
			>
				<CustomLink
					href={"/" + router.locale + "/intro"}
					rel="noopener"
					target="_self"
					referrerPolicy="no-referrer"
				>
					{router.locale === "ar-AE"
						? "ندخل في العقارات"
						: router.locale === "kn-IN"
							? "ರಿಯಲ್ ಎಸ್ಟೇಟ್‌ಗೆ ಪ್ರವೇಶಿಸಿ"
							: "Get into real estate"}
				</CustomLink>

				<ContentContainer
					sx={{
						padding: "0.5rem 0rem 0rem 0rem",
					}}
				>
					<ContentRow
						onClick={() => {
							session !== null
								? handleCreateOpen()
								: router
										.push(
											{
												pathname: encodeURI("/"),
												query: { create_property: "true" },
											},
											"",
											{ locale: router.locale },
										)
										.then(() => {
											signIn();
										});
						}}
					>
						{/* <AddHomeWorkOutlined
							color="primary"
							sx={{
								margin: "0rem 0.25rem 0rem 0rem",
								fontSize: "1.75rem",
							}}
						/> */}
						<SvgIcon
							component={PostProperty}
							inheritViewBox={false}
							viewBox="0 0 37 40"
							sx={{
								width: "2.5rem",
								height: "2.5rem",
							}}
						/>
						<NameTypeContainer>
							<Heading
								sx={{
									fontWeight: router.locale === "ar-AE" ? 600 : 500,
								}}
							>
								{router.locale === "ar-AE"
									? "انشر عقارًا"
									: router.locale === "kn-IN"
										? "ಆಸ್ತಿಯನ್ನು ಪೋಸ್ಟ್ ಮಾಡಿ"
										: "Post property"}
							</Heading>
							<TypeTypo>
								{router.locale === "ar-AE"
									? "بع أو أجر عقارك"
									: router.locale === "kn-IN"
										? "ನಿಮ್ಮ ಆಸ್ತಿಯನ್ನು ಮಾರಾಟ ಅಥವಾ ಬಾಡಿಗೆಗೆ ನೀಡಿ"
										: "Sell or rent out your property"}
							</TypeTypo>
						</NameTypeContainer>
					</ContentRow>

					<ContentRow
						onClick={() => {
							session !== null
								? handleOpenNewService()
								: router
										.push(
											{
												pathname: encodeURI("/"),
												query: { create_service: "true" },
											},
											"",
											{ locale: router.locale },
										)
										.then(() => {
											signIn();
										});
						}}
					>
						{/* <HomeRepairServiceOutlined
							color="primary"
							sx={{
								margin: router.locale === "ar-AE" ? "0rem 0rem 0rem 0.5rem" : "0rem 0.5rem 0rem 0rem",
								fontSize: "2.5rem",
							}}
						/> */}
						<SvgIcon
							component={PostService}
							inheritViewBox={false}
							viewBox="0 0 36 42"
							sx={{
								width: "2.5rem",
								height: "2.5rem",
							}}
						/>
						<NameTypeContainer>
							<Heading
								sx={{
									fontWeight: router.locale === "ar-AE" ? 600 : 500,
								}}
							>
								{router.locale === "ar-AE"
									? "انشر خدمة"
									: router.locale === "kn-IN"
										? "ಸೇವೆಯನ್ನು ಪೋಸ್ಟ್ ಮಾಡಿ"
										: "Post service"}
							</Heading>
							<TypeTypo>
								{router.locale === "ar-AE"
									? "ابحث عن عملاء لخدمتك"
									: router.locale === "kn-IN"
										? "ನಿಮ್ಮ ಸೇವೆಗಾಗಿ ಗ್ರಾಹಕರನ್ನು ಹುಡುಕಿ"
										: "Find clients for your service"}
							</TypeTypo>
						</NameTypeContainer>
					</ContentRow>

					<ContentRow
						onClick={() => {
							session !== null
								? handleCreateNewOfbPropertyOpen()
								: router
										.push(
											{
												pathname: encodeURI("/"),
												query: { create_ofb: "true" },
											},
											"",
											{ locale: router.locale },
										)
										.then(() => {
											signIn();
										});
						}}
					>
						{/* <AddBusinessOutlined
							color="primary"
							sx={{
								margin: router.locale === "ar-AE" ? "0rem 0rem 0rem 0.5rem" : "0rem 0.5rem 0rem 0rem",
								fontSize: "2.5rem",
							}}
						/> */}
						<SvgIcon
							component={PostOFB}
							inheritViewBox={false}
							viewBox="0 0 42 41"
							sx={{
								width: "2.5rem",
								height: "2.5rem",
							}}
						/>
						<NameTypeContainer>
							<Heading
								sx={{
									fontWeight: router.locale === "ar-AE" ? 600 : 500,
								}}
							>
								{router.locale === "ar-AE" ? "انشر أو إف بي" : router.locale === "kn-IN" ? "ಪೋಸ್ಟ್ OFB" : "Post OFB"}
							</Heading>
							<TypeTypo>
								{router.locale === "ar-AE"
									? "استفد من المواقع لصالحك"
									: router.locale === "kn-IN"
										? "ನಿಮ್ಮ ಅನುಕೂಲಕ್ಕಾಗಿ ಸ್ಥಳಗಳನ್ನು ಬಳಸಿಕೊಳ್ಳಿ"
										: "Utilize locations to your benefit"}
							</TypeTypo>
						</NameTypeContainer>
					</ContentRow>

					{/* <ContentRow
						onClick={() => {
							session !== null ? handleOpenBusinessProfile() : signIn();
						}}
					>
						<PersonAddAltOutlined
							color="primary"
							sx={{
								margin: "0rem 0.25rem 0rem 0rem",
								fontSize: "1.75rem",
							}}
						/>
						<NameTypeContainer>
							<Heading>Create business profile</Heading>
							<TypeTypo>Post your lisitngs as a business</TypeTypo>
						</NameTypeContainer>
					</ContentRow> */}
				</ContentContainer>
			</Menu>

			{/* <Slide
				appear={false}
				direction="down"
				in={isiOS || negativeScroll}
			> */}
			<AppBar
				position="fixed"
				color="transparent"
				elevation={0}
				sx={{
					direction: router.locale === "ar-AE" ? "rtl" : "ltr",
				}}
			>
				<SectionContainer
					sx={{
						alignItems:
							(scroll === 0 &&
								(pathname.asPath === "/" || pathname.asPath.startsWith("/?utm") || pathname.asPath.includes("fb"))) ||
							pathname.asPath.includes("create_property=true")
								? "flex-start"
								: "center",
						backgroundColor:
							scroll === 0 &&
							(pathname.asPath === "/" ||
								pathname.asPath.startsWith("/?utm") ||
								pathname.asPath.includes("fb") ||
								pathname.asPath.includes("create_property=true"))
								? "transparent"
								: theme.palette.mode === "dark"
									? "#424242"
									: "#ffffff",
						// backgroundColor: scroll === 0 ? "transparent" : theme.palette.mode === "dark" ? "#424242" : "#ffffff",
						// height: scroll === 0 && pathname.asPath === "/" ? "100%" : "4rem",
						height: "4rem",
					}}
				>
					{CustomNavChanges()}
				</SectionContainer>
			</AppBar>
			{/* </Slide> */}
			<Toolbar sx={{ "&.MuiToolbar-root": { minHeight: "0px" } }} />

			{/* {session &&
			session.user &&
			session.user.dbUser &&
			(session.user.dbUser.recovery_email.email === "" || session.user.dbUser.recovery_email.is_verified === false) ? (
				<RecoveryEmailSnackbar session={session} />
			) : null} */}
		</React.Fragment>
	);
};

export default LayoutHeader;
{
	/* {session !== null ? (
                  <UserMenu
                    session={session}
                    dispatch={dispatch}
                    profile_context={profile_context}
                    new_business_profile_url={new_business_profile_url}
                  />
                ) : (
                  <BeegruButton
                    disabled={loginDisabled}
                    flavor="primary"
                    variant="contained"
                    size="large"
                    onClick={() => {
                      session && session.authorized
                        ? signOut({
                            callbackUrl:
                              process.env.PRODUCTION_API_URL +
                              "auth/end-session?sessionId=" +
                              encodeURIComponent(session.session_id) +
                              "&callbackUrl=" +
                              encodeURIComponent(router.asPath),
                          })
                        : signIn();
                    }}
                    name="login"
                    type="button"
                    sx={{
                      [theme.breakpoints.down("xs")]: {
                        display: "none",
                      },
                    }}
                  >
                    {session && session.authorized
                      ? "Logout"
                      : "Login"}
                  </BeegruButton>
                )} */
}
