import { Divider, SvgIcon, Typography, styled, useTheme } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import React from "react";
import PostProperty from "../../../public/images/icons/home/banners/post_property.svg";
import StateInterface from "../../../redux-magic/state-interface";
import { setCreateNewPropertyCurrentPageThunk } from "../../../redux-magic/store";
import BeegruButton from "../../common-components/buttons/BeegruButton";
import CreatePropertyWithUploadTool from "../../modals/PropertyModal/CreatePropertyWithUploadTool";

const MainContainer = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "row",
	justifyContent: "space-between",
	alignItems: "space-between",
	width: "100%",
	gap: "2rem",
	padding: "1rem 2rem 1rem 2rem",
	margin: "2.5rem 0rem 1rem 0rem",
	borderRadius: "16px",
	background: theme.palette.mode === "dark" ? "#624936" : "#F5EEE8",
	[theme.breakpoints.down("md")]: {
		padding: "1rem 1.5rem 1rem 1.5rem",
	},
}));

const SectionContainer = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "column",
	justifyContent: "center",
	alignItems: "flex-start",
	width: "100%",
	gap: "1rem",
	[theme.breakpoints.down("sm")]: {
		gap: "0rem",
	},
}));

const MobileContainer = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "row",
	justifyContent: "space-between",
	alignItems: "center",
	width: "100%",
	gap: "1rem",
	[theme.breakpoints.up("md")]: {
		display: "none",
	},
}));

const Title = styled(Typography)(({ theme }) => ({
	fontSize: "1.5rem",
	lineHeight: "1.75rem",
	[theme.breakpoints.down("md")]: {
		fontSize: "1.25rem",
		lineHeight: "1.75rem",
	},
	color: theme.palette.mode === "dark" ? "#FFFFFF" : "#212121",
}));

const SubTtext = styled("li")(({ theme }) => ({
	color: theme.palette.mode === "dark" ? "#FFFFFF" : "#212121",
}));

const FirstBannerSection = ({
	session,
	dispatch,
	new_listing_url,
	profile_context,
	newPropertyData,
	isStudio,
	newServiceData,
}: {
	session: any;
	dispatch: Function;
	new_listing_url: StateInterface["new_listing_url"];
	profile_context: StateInterface["profile_context"];
	newPropertyData: StateInterface["new_property_data"];
	isStudio: boolean;
	newServiceData: StateInterface["new_service_data"];
}) => {
	const [isKannada, setIsKannada] = React.useState(false);

	const toggleLanguage = () => {
		setIsKannada(!isKannada);
	};

	const matchesMd = useMediaQuery((theme: { breakpoints: { down: (arg0: string) => any } }) =>
		theme.breakpoints.down("md"),
	);

	const matchesSm = useMediaQuery((theme: { breakpoints: { down: (arg0: string) => any } }) =>
		theme.breakpoints.down("sm"),
	);

	const marginstyle = matchesSm ? "0.5rem 0rem 0rem 0rem" : "0rem 0rem 0rem 0rem";

	const theme = useTheme();

	const [create, setCreate] = React.useState(false);

	const handleCreateOpen = () => {
		dispatch(setCreateNewPropertyCurrentPageThunk("business_profile"));
		setCreate(true);
	};

	const handleCreateClose = () => {
		setCreate(false);
	};

	return (
		<MainContainer>
			<CreatePropertyWithUploadTool
				open={create}
				handleClose={handleCreateClose}
				dispatch={dispatch}
				new_listing_url={new_listing_url ? new_listing_url : ""}
				session={session}
				newPropertyData={newPropertyData}
				studio={isStudio}
			/>
			<SectionContainer>
				<Title variant="h6">
					{isKannada ? "ನಿಮ್ಮ ಆಸ್ತಿಗೆ ಗ್ರಾಹಕರನ್ನು ಹುಡುಕುವಲ್ಲಿ ತೊಂದರೆ?" : "Trouble finding clients for your property?"}
				</Title>
				{/* <button onClick={toggleLanguage}>{isKannada ? "Switch to English" : "ಕನ್ನಡಕ್ಕೆ ಬದಲಾಯಿಸಿ"}</button> */}

				{/* Mobile Illustration */}
				<MobileContainer>
					<ul
						style={{
							display: "flex",
							flexDirection: "column",
							justifyContent: "center",
							alignItems: "flex-start",
							gap: "0.5rem",
							fontSize: "0.875rem",
							lineHeight: "1.45rem",
							margin: marginstyle,
							padding: "0rem 0rem 0rem 1.5rem",
						}}
					>
						<SubTtext style={{ color: theme.palette.mode === "dark" ? "#FFFFFF" : "#212121" }}>
							{isKannada ? "ನಿಮ್ಮ ಆಸ್ತಿಯನ್ನು ಉಚಿತವಾಗಿ ಪೋಸ್ಟ್ ಮಾಡಿ" : "Post your property for free"}
						</SubTtext>
						<SubTtext>{isKannada ? "ಗ್ರಾಹಕರೊಂದಿಗೆ ನೇರವಾಗಿ ಸಂಪರ್ಕಿಸಿ" : "Get in touch with clients directly"}</SubTtext>
						<SubTtext>{isKannada ? "ಉತ್ತಮ ತಲುಪುವಿಕೆ ಪಡೆಯಿರಿ" : "Get better reach"}</SubTtext>
					</ul>

					<SvgIcon
						component={PostProperty}
						inheritViewBox={false}
						viewBox="0 0 220 130"
						sx={{
							width: "125px",
							height: "120px",
							[theme.breakpoints.up("sm")]: {
								display: "none",
							},
						}}
					/>
				</MobileContainer>

				<ul
					style={{
						display: matchesMd ? "none" : "flex",
						flexDirection: "row",
						justifyContent: "center",
						alignItems: "flex-start",
						gap: "1.5rem",
						margin: "0rem",
						padding: "0rem 0rem 0rem 0rem",
						listStyleType: "none",
					}}
				>
					<SubTtext>{isKannada ? "ನಿಮ್ಮ ಆಸ್ತಿಯನ್ನು ಉಚಿತವಾಗಿ ಪೋಸ್ಟ್ ಮಾಡಿ" : "Post your property for free"}</SubTtext>
					<Divider
						orientation="vertical"
						flexItem
					/>
					<SubTtext>{isKannada ? "ಗ್ರಾಹಕರೊಂದಿಗೆ ನೇರವಾಗಿ ಸಂಪರ್ಕಿಸಿ" : "Get in touch with clients directly"}</SubTtext>
					<Divider
						orientation="vertical"
						flexItem
					/>
					<SubTtext>{isKannada ? "ಉತ್ತಮ ತಲುಪುವಿಕೆ ಪಡೆಯಿರಿ" : "Get better reach"}</SubTtext>
				</ul>

				<BeegruButton
					flavor="primary"
					variant="outlined"
					size="large"
					sx={{
						marginTop: "1rem",
						[theme.breakpoints.down("md")]: {
							marginTop: "0rem",
						},
						[theme.breakpoints.down("sm")]: {
							width: "100%",
							marginTop: "1rem",
						},
					}}
					onClick={handleCreateOpen}
				>
					{isKannada ? "ಆಸ್ತಿ ಪೋಸ್ಟ್ ಮಾಡಿ" : "Post property"}
				</BeegruButton>
			</SectionContainer>

			{/* Desktop Illustration */}
			<SvgIcon
				component={PostProperty}
				inheritViewBox={false}
				viewBox="0 0 220 130"
				sx={{
					width: "20rem",
					height: "10rem",
					[theme.breakpoints.down("md")]: {
						width: "15rem",
						height: "12rem",
					},
					[theme.breakpoints.down("sm")]: {
						display: "none",
					},
				}}
			/>
		</MainContainer>
	);
};

export default FirstBannerSection;
