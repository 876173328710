/*

? First, let's import the essentials - React, Layout, styled and Theme.

*/

import { Button, Skeleton, useTheme } from "@mui/material";
import { styled } from "@mui/system";
import { useRouter } from "next/router";
import React from "react";

/*

* Next, let's dynamically import all the UI sub-components we intend to use on this page. we dont need it on inital load. Api needs to trigger till then we show loading component which we will import it normally.

*/

import BusinessProfileCard from "../../cards/BusinessProfileCard";
import PropertyCardThree from "../../cards/PropertyCardGridMini/PropertyCardFetcher";
import PropertyCardTwo from "../../cards/PropertyCardGridMobileTwo/PropertyCardFetcher";
import ServiceCard from "../../cards/ServiceCardGridMini/ServiceCardFetcher";

/*

* let's check if mobile or desktop to import the correct card component. this is the function

*/
// const isMobile = () => {
// 	if (typeof window !== "undefined") {
// 		return window.innerWidth <= 768;
// 	}
// 	return false;
// };

/*

* lets call the function to check if mobile or desktop and import the correct card component

*/
// const FeaturedPropertiesCard = dynamic(
// 	() => {
// 		/*

// 		* mobile calls the isobile function

// 		*/
// 		const mobile = isMobile();
// 		if (mobile) {
// 			return import("../../cards/FeaturedCard/FeaturedMobileListCard");
// 		} else {
// 			return import("../../cards/FeaturedCard/FeaturedListCard");
// 		}
// 	},
// 	{ ssr: false },
// );

/*

? Next, let's import all the icons we intend to use on this page.

*/

import ArrowBackOutlinedIcon from "@mui/icons-material/ArrowBackOutlined";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";

/*

? Next, let's import all redux related components.

*/

import StateInterface from "../../../redux-magic/state-interface";
// import BusinessProfileCard from "../../cards/BusinessProfileCard";
import BusinessProfileCardGridView from "../../cards/BusinessProfileCards/BusinessProfileCardMini";
// import ServiceCard from "../../cards/ServiceCard/ServiceCard";
import { ArrowBackOutlined, ArrowForwardOutlined } from "@mui/icons-material";
import BeegruButton from "../../common-components/buttons/BeegruButton";

/*

& Next, let's style all the components we intend to use on this page.

*/

type CustomAttributesForDivElement = {
	index?: number;
};

const SectionContainer = styled("div")<CustomAttributesForDivElement>(({ theme }) => ({
	display: "flex",
	flexDirection: "row",
	justifyContent: "space-between",
	alignItems: "center",
	width: "100%",
	height: "100%",
	margin: "0rem 0rem 0rem 0rem",
}));

const Container = styled("div", {
	shouldForwardProp: (prop) => prop !== "locale",
})<{ locale: string }>(({ theme, locale }) => ({
	display: "flex",
	flexDirection: locale === "ar-AE" ? "row-reverse" : "row",
	alignItems: "center",
	gap: "1rem",
	flexWrap: "nowrap",
	scrollBehavior: "smooth",
	overflowX: "scroll",
	scrollSnapType: "x mandatory",
	"&::-webkit-scrollbar": {
		display: "none",
	},
}));

const InnerContainer = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "row",
	justifyContent: "flex-start",
	alignItems: "center",
	// gap: "1rem",
	// [theme.breakpoints.down("sm")]: {
	// 	gap: "0.5rem",
	// },
}));

const GradientHeading = styled("span")(({ theme }) => ({
	fontSize: "0.875rem",
	lineHeight: "1.25rem",
	fontWeight: 500,
	color: theme.palette.text.primary,
	padding: "0rem 0rem 0rem 0.25rem",
	[theme.breakpoints.up("sm")]: {
		fontSize: "1.25rem",
		lineHeight: "1.5rem",
		padding: "0rem 0rem 0.5rem 0.25rem",
	},
}));

const Columncontain = styled("div")(({ theme }) => ({
	display: "flex",
	position: "relative",
	// minHeight: "13.5rem",
	"&:hover": {
		"${LeftArrowContainer}": {
			display: "flex",
		},
	},
}));

const LeftArrowContainer = styled(Button)(({ theme }) => ({
	display: "flex",
	position: "absolute",
	justifyContent: "center",
	alignItems: "center",
	minWidth: "0px",
	background: theme.palette.mode === "dark" ? "black" : "white",
	padding: "1.5rem 1.5rem 1.5rem 1.5rem",
	left: -25,
	zIndex: 5,
	"&:hover": {
		background: theme.palette.mode === "dark" ? "black" : "white",
	},
	scrollBehavior: "smooth",
	borderRadius: "50%",
}));

const ArrowLeft = styled(ArrowBackOutlinedIcon)(({ theme }) => ({
	display: "flex",
	position: "absolute",
	cursor: "pointer",
	justifyContent: "center",
	alignItems: "center",
	// stroke: "black",
	fontSize: "2.5rem",
	fill: theme.palette.mode === "dark" ? "rgba(255, 255, 255, 0.56)" : "rgba(0, 0, 0, 0.54)",
	width: "1.5rem",
}));

const ArrowRight = styled(ArrowForwardIcon)(({ theme }) => ({
	display: "flex",
	cursor: "pointer",
	position: "absolute",
	// right: 12,
	flexDirection: "column",
	// stroke: "white",
	fill: theme.palette.mode === "dark" ? "rgba(255, 255, 255, 0.56)" : "rgba(0, 0, 0, 0.54)",
	width: "1.5rem",
}));

const RighArrowContainer = styled(Button)(({ theme }) => ({
	display: "flex",
	position: "absolute",
	justifyContent: "center",
	alignItems: "center",
	zIndex: 5,
	background: theme.palette.mode === "dark" ? "black" : "white",
	padding: "1.5rem 1.5rem 1.5rem 1.5rem",
	minWidth: "0px",
	right: -23,
	"&:hover": {
		background: theme.palette.mode === "dark" ? "black" : "white",
	},
	scrollBehavior: "smooth",
	borderRadius: "50%",
}));

const CardCarousel = ({
	index,
	data,
	loading,
	type,
	profile_context,
	dispatch,
	session,
	innerRef,
	searchanimationsettings,
}: {
	index: number;
	data: Array<any>;
	loading: boolean;
	type: string;
	profile_context: StateInterface["profile_context"];
	dispatch: Function;
	session: any;
	innerRef: any;
	searchanimationsettings: StateInterface["search_animation_settings"];
}) => {
	const theme = useTheme();
	/*

	& Next, Let's create a function to scroll the container.

	*/

	const ref = React.useRef<any>();

	const scroll = (scrollOffset: number) => {
		ref.current.scrollLeft += scrollOffset;
	};

	/*

	& This function is used to check if the device is touch enabled or not

	& @returns boolean

	*/

	const isTouchSupported = () => {
		return "ontouchstart" in window || navigator.maxTouchPoints;
	};

	/*

    & Next, let's declare a  local state variable to store the value of the display property of the scroll arrows.

  	*/

	const [display, setDisplay] = React.useState<string>("none");

	/*

    & Next, let's declare local state variables to store whether user can scroll left or right.

  	*/

	// const [canScrollLeft, setCanScrollLeft] = React.useState(false);
	// const [canScrollRight, setCanScrollRight] = React.useState(false);

	/*

    & Next, let's define a function to show the hover effect.

  */

	const ShowHover = () => {
		if (!isTouchSupported()) {
			setDisplay(display === "none" ? "flex" : "none");
		} else {
			return false;
		}
	};

	/*

    & Next, let's define a function to hide the hover effect.

  	*/

	const HideHover = () => {
		if (!isTouchSupported()) {
			setDisplay(display === "flex" ? "none" : "none");
		} else {
			return false;
		}
	};

	/*

    & Next, let's define a function to hide arrow id there is nothing to scroll on that side.

  	*/

	// React.useEffect(() => {
	// 	const handleScroll = () => {
	// 		if (ref.current) {
	// 			const { scrollLeft, scrollWidth, clientWidth } = ref.current;
	// 			setCanScrollLeft(scrollLeft > 3);
	// 			setCanScrollRight(scrollLeft + clientWidth < scrollWidth);
	// 		}
	// 	};

	// 	const carousel = ref.current;
	// 	if (carousel) {
	// 		carousel.addEventListener("scroll", handleScroll);
	// 		handleScroll(); // Initial check
	// 	}

	// 	return () => {
	// 		if (carousel) {
	// 			carousel.removeEventListener("scroll", handleScroll);
	// 		}
	// 	};
	// }, []);

	// React.useEffect(() => {
	// 	// Initial check after the component mounts and renders
	// 	if (ref.current) {
	// 		const { scrollLeft, scrollWidth, clientWidth } = ref.current;
	// 		setCanScrollLeft(scrollLeft > 3);
	// 		setCanScrollRight(scrollLeft + clientWidth < scrollWidth);
	// 	}
	// }, [data]);
	const router = useRouter();

	const viewAll = (type: string) => {
		// return async (dispatch: Function) => {
		let url = "";
		switch (type) {
			case "hot_properties":
				url =
					(process.env.PRODUCTION_URL || "") +
					"loading.html?callbackUrl=" +
					encodeURIComponent(
						(process.env.PRODUCTION_URL || "") +
							"search?search_type=properties&location=" +
							"HSR Layout" +
							"," +
							[12.9121, 77.6446] +
							"&transaction_type=rent" +
							"&property_category=residential" +
							"&property_type=Apartment" +
							`&locale=${router.locale || "en-IN"}`,
					);
				window.location.href = url;
				break;
			case "top_services":
				url =
					(process.env.PRODUCTION_URL || "") +
					"loading.html?callbackUrl=" +
					encodeURIComponent(
						(process.env.PRODUCTION_URL || "") +
							"search?search_type=services&location=" +
							encodeURIComponent("HSR Layout") +
							"," +
							encodeURIComponent("12.9121,77.6446") +
							"&service_category=regular_service" +
							"&service_type=interiors_and_decor" +
							"&service_subtype=interior_design" +
							`&locale=${router.locale || "en-IN"}`,
					);
				window.location.href = url;
				break;
			case "trending_services":
				url =
					(process.env.PRODUCTION_URL || "") +
					"loading.html?callbackUrl=" +
					encodeURIComponent(
						(process.env.PRODUCTION_URL || "") +
							"search?search_type=services&location=" +
							encodeURIComponent("HSR Layout") +
							"," +
							encodeURIComponent("12.9121,77.6446") +
							"&service_category=regular_service" +
							"&service_type=interiors_and_decor" +
							"&service_subtype=interior_design" +
							`&locale=${router.locale || "en-IN"}`,
					);
				window.location.href = url;
				break;
			case "top_realtors":
				url =
					(process.env.PRODUCTION_URL || "") +
					"loading.html?callbackUrl=" +
					encodeURIComponent(
						(process.env.PRODUCTION_URL || "") +
							"search?search_type=business_profiles&location=" +
							encodeURIComponent("HSR Layout") +
							"," +
							encodeURIComponent("12.9121,77.6446") +
							"&business_profile_type=agents" +
							`&locale=${router.locale}`,
					);
				window.location.href = url;
				break;
		}
	};

	const dataToRender: Function = (type: string, data: any): any => {
		const heading = (type: string) => {
			return (
				<SectionContainer
					sx={{
						direction: router.locale === "ar-AE" ? "rtl" : "ltr",
					}}
				>
					{/* Heading & Subheading */}

					{!loading && data && data[0] !== 0 ? (
						<div
							style={{
								display: "flex",
								flexDirection: "column",
								justifyContent: "center",
								alignItems: "center",
								gap: "0.5rem",
								// marginBottom: "0.5rem",
							}}
						>
							<GradientHeading
								sx={{
									fontWeight: router.locale === "ar-AE" ? 600 : 500,
								}}
							>
								{type === "hot_properties" || type === "trending_properties"
									? router.locale === "ar-AE"
										? "العقارات المميزة"
										: router.locale === "kn-IN"
											? "ಪ್ರಮುಖ ಆಸ್ತಿಗಳು"
											: "Featured properties"
									: type === "top_realtors"
										? router.locale === "ar-AE"
											? "أفضل وكلاء العقارات"
											: router.locale === "kn-IN"
												? "ಟಾಪ್ ರಿಯಾಲ್ಟರ್ಗಳು"
												: "Top realtors"
										: type === "top_services"
											? router.locale === "ar-AE"
												? "أفضل خدمات"
												: router.locale === "kn-IN"
													? "ಅತ್ಯುತ್ತಮ ಸೇವೆಗಳು"
													: "Top services"
											: type.charAt(0).toUpperCase() + type.slice(1).split("_").join(" ")}
							</GradientHeading>
						</div>
					) : (
						<div
							style={{
								display: "flex",
								flexDirection: "column",
								justifyContent: "center",
								alignItems: "center",
								gap: "0.5rem",
								// marginBottom: "0.5rem",
							}}
						>
							<Skeleton
								animation="wave"
								variant="text"
								width="12rem"
								height="2.25rem"
							/>
						</div>
					)}

					{/* View All button */}

					{!loading && data && data[0] !== 0 ? (
						<div
							style={{
								display: "flex",
								alignItems: "flex-start",
							}}
						>
							<BeegruButton
								flavour="primary"
								variant="text"
								onClick={() => {
									viewAll(type);
								}}
								sx={{
									fontSize: "0.75rem",
									fontWeight: router.locale === "ar-AE" ? 600 : 500,
									[theme.breakpoints.up("sm")]: {
										fontSize: "0.95rem",
									},
								}}
							>
								{router.locale === "ar-AE" ? "رؤية المزيد" : router.locale === "kn-IN" ? "ಮತ್ತಷ್ಟು ನೋಡಿ" : "see more"}

								{router.locale === "ar-AE" ? (
									<ArrowBackOutlined
										fontSize="medium"
										sx={{
											fontSize: "0.875rem",
											margin: "0rem 0.125rem 0rem 0rem",
											[theme.breakpoints.up("sm")]: {
												fontSize: "0.95rem",
											},
										}}
									/>
								) : (
									<ArrowForwardOutlined
										fontSize="medium"
										sx={{
											fontSize: "0.875rem",
											margin: "0rem 0rem 0rem 0.125rem",
											[theme.breakpoints.up("sm")]: {
												fontSize: "0.95rem",
											},
										}}
									/>
								)}
							</BeegruButton>
						</div>
					) : (
						<Skeleton
							animation="wave"
							variant="text"
							width="6rem"
							height="2rem"
						/>
					)}
				</SectionContainer>
			);
		};

		switch (type) {
			case "hot_properties":
				return (
					<React.Fragment>
						{heading(type)}

						<Columncontain
							onMouseEnter={ShowHover}
							onMouseLeave={HideHover}
						>
							<Container
								locale={router.locale ? router.locale : "en-IN"}
								ref={ref}
								sx={{ gap: "0.5rem" }}
							>
								{/* {canScrollLeft && ( */}
								<LeftArrowContainer
									variant="contained"
									onClick={() => {
										scroll(-400);
									}}
									style={{ display: display }}
								>
									<ArrowLeft />
								</LeftArrowContainer>
								{/* )} */}

								{data !== undefined ? (
									<React.Fragment>
										{data?.map((property: any, key: number) => {
											return (
												<InnerContainer key={key}>
													<PropertyCardThree
														PropertyId={property._id}
														dispatch={dispatch}
														session={session}
														profile_context={profile_context}
													/>
												</InnerContainer>
											);
										})}
									</React.Fragment>
								) : null}
								{/* {canScrollRight && ( */}
								<RighArrowContainer
									variant="contained"
									onClick={() => scroll(400)}
									style={{ display: display }}
								>
									<ArrowRight />
								</RighArrowContainer>
								{/* )} */}
							</Container>
						</Columncontain>
					</React.Fragment>
				);
			case "trending_properties":
				return (
					<React.Fragment>
						{heading(type)}

						<Columncontain
							onMouseEnter={ShowHover}
							onMouseLeave={HideHover}
						>
							<Container
								locale={router.locale ? router.locale : "en-IN"}
								ref={ref}
							>
								{/* {canScrollLeft && ( */}
								<LeftArrowContainer
									variant="contained"
									onClick={() => {
										scroll(-400);
									}}
									style={{ display: display }}
								>
									<ArrowLeft />
								</LeftArrowContainer>
								{/* )} */}

								{data !== undefined ? (
									<React.Fragment>
										{data?.map((property: any, key: number) => {
											return (
												<InnerContainer key={key}>
													<PropertyCardTwo
														PropertyId={property._id}
														dispatch={dispatch}
														session={session}
														profile_context={profile_context}
													/>
												</InnerContainer>
											);
										})}
									</React.Fragment>
								) : null}

								{/* {canScrollRight && ( */}
								<RighArrowContainer
									variant="contained"
									onClick={() => scroll(400)}
									style={{ display: display }}
								>
									<ArrowRight />
								</RighArrowContainer>
								{/* )} */}
							</Container>
						</Columncontain>
					</React.Fragment>
				);
			case "top_realtors":
				return (
					<React.Fragment>
						{heading(type)}

						<Columncontain
							onMouseEnter={ShowHover}
							onMouseLeave={HideHover}
						>
							<Container
								locale={router.locale ? router.locale : "en-IN"}
								ref={ref}
							>
								{/* {canScrollLeft && ( */}
								<LeftArrowContainer
									variant="contained"
									onClick={() => {
										scroll(-400);
									}}
									style={{ display: display }}
								>
									<ArrowLeft />
								</LeftArrowContainer>
								{/* )} */}

								{!loading && data && data[0] !== 0 ? (
									<React.Fragment>
										{data?.map((business_profile: any, key: number) => {
											return (
												<InnerContainer key={key}>
													<BusinessProfileCardGridView
														_id={business_profile._id}
														dispatch={dispatch}
													/>
												</InnerContainer>
											);
										})}
									</React.Fragment>
								) : null}

								{/* {canScrollRight && ( */}
								<RighArrowContainer
									variant="contained"
									onClick={() => scroll(400)}
									style={{ display: display }}
								>
									<ArrowRight />
								</RighArrowContainer>
								{/* )} */}
							</Container>
						</Columncontain>
					</React.Fragment>
				);
			case "top_users":
				return (
					<React.Fragment>
						{heading(type)}
						<Columncontain
							onMouseEnter={ShowHover}
							onMouseLeave={HideHover}
						>
							<Container
								locale={router.locale ? router.locale : "en-IN"}
								ref={ref}
							>
								{/* {canScrollLeft && ( */}
								<LeftArrowContainer
									variant="contained"
									onClick={() => {
										scroll(-400);
									}}
									style={{ display: display }}
								>
									<ArrowLeft />
								</LeftArrowContainer>
								{/* )} */}

								{!loading ? (
									<React.Fragment>
										{data?.map((user: any, key: number) => {
											return (
												<InnerContainer key={key}>
													<BusinessProfileCard
														loading={loading}
														profile_id={user._id}
														slug={user.slug}
														display_picture={user.profile_picture}
														title={(user.first_name + " " + user.last_name).toString()}
														profile_type={"user"}
														is_subscribed={user?.subscription_information?.active}
														is_kyc_verified={user.verified}
													/>
												</InnerContainer>
											);
										})}
									</React.Fragment>
								) : null}

								{/* {canScrollRight && ( */}
								<RighArrowContainer
									variant="contained"
									onClick={() => scroll(400)}
									style={{ display: display }}
								>
									<ArrowRight />
								</RighArrowContainer>
								{/* )} */}
							</Container>
						</Columncontain>
					</React.Fragment>
				);
			case "top_services":
				return (
					<React.Fragment>
						{heading(type)}

						<Columncontain
							onMouseEnter={ShowHover}
							onMouseLeave={HideHover}
						>
							<Container
								locale={router.locale ? router.locale : "en-IN"}
								ref={ref}
							>
								{/* {canScrollLeft && ( */}
								<LeftArrowContainer
									variant="contained"
									onClick={() => {
										scroll(-400);
									}}
									style={{ display: display }}
								>
									<ArrowLeft />
								</LeftArrowContainer>
								{/* )} */}

								{data !== undefined ? (
									<React.Fragment>
										{data?.map((service: any, key: number) => {
											return (
												<InnerContainer key={key}>
													<ServiceCard
														ServiceId={service._id}
														dispatch={dispatch}
														session={session}
														profile_context={profile_context}
													/>
												</InnerContainer>
											);
										})}
									</React.Fragment>
								) : null}

								{/* {canScrollRight && ( */}
								<RighArrowContainer
									variant="contained"
									onClick={() => scroll(400)}
									style={{ display: display }}
								>
									<ArrowRight />
								</RighArrowContainer>
								{/* )} */}
							</Container>
						</Columncontain>
					</React.Fragment>
				);
			case "trending_services":
				return (
					<React.Fragment>
						{heading(type)}

						<Columncontain
							onMouseEnter={ShowHover}
							onMouseLeave={HideHover}
						>
							<Container
								locale={router.locale ? router.locale : "en-IN"}
								ref={ref}
							>
								{/* {canScrollLeft && ( */}
								<LeftArrowContainer
									variant="contained"
									onClick={() => {
										scroll(-400);
									}}
									style={{ display: display }}
								>
									<ArrowLeft />
								</LeftArrowContainer>
								{/* )} */}

								{data !== undefined ? (
									<React.Fragment>
										{data?.map((service: any, key: number) => {
											return (
												<InnerContainer key={key}>
													<ServiceCard
														ServiceId={service._id}
														dispatch={dispatch}
														session={session}
														profile_context={profile_context}
													/>
												</InnerContainer>
											);
										})}
									</React.Fragment>
								) : null}

								{/* {canScrollRight && ( */}
								<RighArrowContainer
									variant="contained"
									onClick={() => scroll(400)}
									style={{ display: display }}
								>
									<ArrowRight />
								</RighArrowContainer>
								{/* )} */}
							</Container>
						</Columncontain>
					</React.Fragment>
				);
			default:
				return <React.Fragment></React.Fragment>;
		}
	};

	return (
		<React.Fragment>
			<div
				ref={innerRef}
				style={{ margin: "0.75rem 0rem 0rem 0rem" }}
			>
				{dataToRender(type, data)}
			</div>
		</React.Fragment>
	);
};

export default CardCarousel;
