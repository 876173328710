const CurrencyToNumberPipe: (value: string) => number = (value: string) =>
  Number(value.replace(/\D/g, ""));

export default CurrencyToNumberPipe;

// const CurrencyToNumberPipe: (value: string) => number = (value: string) => {
// 	// Remove all characters except digits and decimal point
// 	const cleanedValue = value.replace(/[^0-9.]+/g, "");
// 	return Number(cleanedValue);
// };

// export default CurrencyToNumberPipe;
