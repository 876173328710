/* Common Imports */

import React from "react";
import OFBCard from "./OFBCardGridMini/OFBCardGridMini";

/* Component Imports */

import StateInterface from "../../../redux-magic/state-interface";
import PropertyLoadingCardMini from "../skeletonLoadingCard/PropertyLoadingCardMini";

/* Styled Components */

const OFBCardGridView = ({
	OFBId,
	dispatch,
	session,
	profile_context,
	isEditor,
	source,
	id,
	bottom_beegru_points,
	boost,
}: {
	OFBId: any;
	dispatch: any;
	session: any;
	profile_context: any;
	isEditor?: boolean | undefined;
	source?: string;
	id?: string;
	bottom_beegru_points?: StateInterface["bottom_subscribe_buy_beegru_points"];
	boost?: StateInterface["boost"];
}) => {
	const [ofbData, setOFBData] = React.useState<any>({});

	const [loading, setLoading] = React.useState(true);

	React.useEffect(() => {
		if (OFBId) {
			fetch(`${process.env.PRODUCTION_API_URL}properties/cards/${OFBId}`, {
				method: "POST",
				headers: {
					"Content-Type": "text/plain",
				},
			})
				.then((res) => res.json())
				.then((data) => {
					setOFBData(data.property_data);
					setLoading(false);
				});
		}
	}, [OFBId]);

	if (loading) {
		return <PropertyLoadingCardMini />;
	}
	return (
		<React.Fragment>
			<OFBCard
				id={id}
				profile_context={profile_context}
				session={session}
				dispatch={dispatch}
				loading={loading}
				_id={ofbData._id}
				slug={ofbData.slug}
				transaction_type={ofbData.transaction_type}
				property_type={ofbData.property_type}
				ofb_category={ofbData.property_category}
				title={ofbData.title}
				images={ofbData.images}
				property_status={ofbData.availability}
				possession_date={ofbData.possession_date}
				location={ofbData.location ? ofbData.location.title : ""}
				developer={ofbData.builder ? ofbData.builder.name : ""}
				price={ofbData.price}
				price_per_unit={ofbData.price_per_unit}
				area={ofbData.area}
				bhk={ofbData.bhk}
				floors={ofbData.floors}
				no_of_seats={ofbData.no_of_seats}
				saved_content={ofbData.saved_content}
				boosting={ofbData.boost_information}
				created_by={ofbData.created_by}
				show_contact_details={ofbData.show_contact_details}
				source={source}
				published_status={ofbData.status}
				isEditor={isEditor}
				bottom_beegru_points={bottom_beegru_points}
				boost={boost}
			/>
		</React.Fragment>
	);
};

export default OFBCardGridView;
