/* Common Imports */

import { styled } from "@mui/system";
import React from "react";

/* Redux Imports */

import { ObjectId } from "mongodb";
import Initials from "./Initials";

/* Component Imports */

import { Link, Skeleton, SvgIcon, Tooltip, Typography } from "@mui/material";
import { useRouter } from "next/router";
import PremiumBadge from "../../public/images/badges/premium.svg";
import VerifiedPremiumBadge from "../../public/images/badges/verified_premium.svg";
/* Styled Components */

const BusinessProfileCardLink = styled(Link)(({ theme }) => ({
	textDecoration: "none",
	color: theme.palette.mode == "dark" ? "#fff" : "#000",
	"&:focus, &:hover, &:visited, &:link, &:active": {
		textDecoration: "none",
	},
}));

const PaperContainer = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "column",
	justifyContent: "center",
	alignItems: "center",
	background: theme.palette.background.paper,
	width: "9.375rem",
	height: "13rem",
	padding: "1rem 1.15rem",
	boxShadow: "0px 1px 3px rgba(0, 0, 0, 0.12)",
	borderRadius: "16px",
	// [theme.breakpoints.down("sm")]: {
	//   width: "12rem",
	//   height: "12rem",
	// },
}));

const TextContainer = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "column",
	justifyContent: "space-between",
	alignItems: "center",
	width: "100%",
	height: "100%",
	padding: "0rem 0rem 0rem 0rem",
}));

const CustomTooltip = styled(Tooltip)(({ theme }) => ({
	display: "flex",
	flexDirection: "column",
	justifyContent: "center",
	alignItems: "center",
	width: "100%",
	height: "100%",
}));

const Title = styled(Typography)(({ theme }) => ({
	color: theme.palette.mode === "dark" ? "#ffffff" : "#000000",
	fontWeight: 400,
	fontSize: "1rem",
	lineHeight: "1.25rem",
	textDecoration: "none",
	textAlign: "center",
	wordBreak: "break-word",

	[theme.breakpoints.down("sm")]: {
		fontSize: "0.875rem",
		lineHeight: "1rem",
	},
}));

const SubTitle = styled(Typography)(({ theme }) => ({
	color: theme.palette.primary.main,
	fontWeight: 400,
	fontSize: "0.85rem",
	lineHeight: "1.125rem",
	textDecoration: "none",
	[theme.breakpoints.down("xs")]: {
		fontSize: "0.8rem",
	},
}));

const ImgContainer = styled("div")(({ theme }) => ({
	display: "flex",
	justifyContent: "center",
	alignItems: "center",
	height: "6.25rem",
	width: "6.25rem",
	margin: "0rem 0rem 0.3rem 0rem",

	// [theme.breakpoints.down("sm")]: {
	//   height: "4.5rem",
	//   width: "4.5rem",
	// },
}));

const Image = styled("img")(({ theme }) => ({
	height: "6.25rem",
	width: "6.25rem",
	borderRadius: "8px",
	objectFit: "cover",
	// [theme.breakpoints.down("sm")]: {
	//   height: "4.5rem",
	//   width: "4.5rem",
	// },
}));

const Background = styled("div")(({ theme }) => ({
	display: "flex",
	justifyContent: "center",
	alignItems: "center",
	bacground: theme.palette.mode === "dark" ? "#000000" : "#ffffff",
	"&:hover": {
		dsiplay: "none",
		background: "none",
	},
}));

const BusinessProfileCard = ({
	profile_id,
	loading,
	slug,
	display_picture,
	title,
	profile_type,
	is_subscribed,
	is_kyc_verified,
}: {
	profile_id: string | ObjectId;
	slug: string;
	display_picture: any;
	title: string;
	profile_type: string;
	loading: Boolean;
	is_subscribed: Boolean;
	is_kyc_verified: Boolean;
}) => {
	const router = React.useRef(useRouter()).current;
	/*

  * Checks if display_picture.exists true

  */

	const Checker = display_picture ? (display_picture.exists ?? false) : false;

	const ProfileId = display_picture ? (display_picture?.file_id ? display_picture.file_id.toString() : "") : "";

	const profilePicture = Checker ? `/profile-picture/${ProfileId}` : "/images/icons/Avatar.svg";

	// const [profilePicture, setProfilePicture] = React.useState(
	//   display_picture
	//     ? (display_picture.url as BusinessProfileMediaImage) &&
	//       typeof (display_picture.url as BusinessProfileMediaImage) !== "string"
	//       ? (display_picture.url as BusinessProfileMediaImage)
	//         ? ((display_picture.url as BusinessProfileMediaImage)
	//             .lqip as string) !==
	//           "https://cdn.beegru.com/images/uploads/placeholder_1080p.jpg/1536/864"
	//           ? ((display_picture.url as BusinessProfileMediaImage)
	//               .lqip as string)
	//           : "/images/icons/Avatar.svg"
	//         : "/images/icons/Avatar.svg"
	//       : "/images/icons/Avatar.svg"
	//     : "/images/icons/Avatar.svg"
	// );

	// React.useEffect(() => {
	//   setProfilePicture(
	//     display_picture
	//       ? (display_picture.url as BusinessProfileMediaImage) &&
	//         typeof (display_picture.url as BusinessProfileMediaImage) !== "string"
	//         ? (display_picture.url as BusinessProfileMediaImage)
	//           ? ((display_picture.url as BusinessProfileMediaImage)
	//               .regular as string) !==
	//             "https://cdn.beegru.com/images/uploads/placeholder_1080p.jpg/1536/864"
	//             ? ((display_picture.url as BusinessProfileMediaImage)
	//                 .regular as string)
	//             : "/images/icons/Avatar.svg"
	//           : "/images/icons/Avatar.svg"
	//         : "/images/icons/Avatar.svg"
	//       : "/images/icons/Avatar.svg"
	//   );
	// }, [display_picture]);

	if (loading) {
		return (
			<React.Fragment>
				<PaperContainer>
					<ImgContainer>
						<Skeleton
							animation="wave"
							variant="text"
							width="10rem"
							height="10rem"
							style={{ marginBottom: "0.5rem" }}
						/>
					</ImgContainer>
					<TextContainer>
						<Title>
							<Skeleton
								animation="wave"
								variant="text"
								width="7rem"
								height="100%"
								style={{ marginBottom: "0.5rem" }}
							/>
						</Title>
						<SubTitle>
							<Skeleton
								animation="wave"
								variant="text"
								width="7rem"
								height="100%"
								style={{ marginBottom: "0.5rem" }}
							/>
						</SubTitle>
					</TextContainer>
				</PaperContainer>
			</React.Fragment>
		);
	} else {
		return (
			<React.Fragment>
				<BusinessProfileCardLink
					href={
						profile_type === "user"
							? `/${router.locale}/${profile_type}s/${profile_id}`
							: `/${router.locale}/${profile_type}s/${slug}`
					}
					underline="none"
					rel="noopener"
					target="_self"
					referrerPolicy="no-referrer"
				>
					<PaperContainer>
						<ImgContainer>
							{!profilePicture.includes("/images/icons/Avatar.svg") ? (
								<Image
									alt="Popular User"
									loading="lazy"
									referrerPolicy="no-referrer"
									// height={512}
									// width={512}
									src={profilePicture}
								/>
							) : (
								<Initials
									title={title}
									profiledropdown={false}
									header={false}
									businessprofile={false}
									postcard={false}
									comment={false}
								/>
							)}
						</ImgContainer>
						<TextContainer>
							<CustomTooltip
								title={title ? title : "Unknown Title"}
								placement="top"
							>
								<Title>{title ? (title.length > 18 ? title.substring(0, 18) + "..." : title) : "Unknown Title"}</Title>
							</CustomTooltip>
							<div
								style={{
									display: "flex",
									flexDirection: "row",
									justifyContent: "center",
									alignItems: "center",
									gap: "0.5rem",
								}}
							>
								<SubTitle>
									{profile_type ? profile_type.charAt(0).toUpperCase() + profile_type.slice(1) : "Agent"}
								</SubTitle>
								{is_subscribed === true && is_kyc_verified === true ? (
									<Tooltip title="Verified Premium">
										<Background>
											<SvgIcon
												component={VerifiedPremiumBadge}
												inheritViewBox={false}
												viewBox="0 0 24 24"
												width={24}
												height={24}
												sx={{
													width: "1rem",
													height: "1rem",
												}}
											/>
										</Background>
									</Tooltip>
								) : is_subscribed === true && is_kyc_verified === false ? (
									<Tooltip title="Premium">
										<Background>
											<SvgIcon
												component={PremiumBadge}
												inheritViewBox={false}
												viewBox="0 0 24 24"
												width={24}
												height={24}
												sx={{
													width: "1rem",
													height: "1rem",
												}}
											/>
										</Background>
									</Tooltip>
								) : null}
							</div>
						</TextContainer>
					</PaperContainer>
				</BusinessProfileCardLink>
			</React.Fragment>
		);
	}
};

export default BusinessProfileCard;
