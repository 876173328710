/* Common Imports */

/* Redux Imports */

import StateInterface from "../../../redux-magic/state-interface";

/* Component Imports */

import { SvgIcon, Typography, styled } from "@mui/material";

/* Icons / Illustrations */

import { useRouter } from "next/router";
import Electrician from "../../../public/images/icons/services/electrician.svg";
import InteriorDesign from "../../../public/images/icons/services/interior_design.svg";
import Painting from "../../../public/images/icons/services/painting.svg";
import PestControl from "../../../public/images/icons/services/pest_control.svg";
// import Plumbing from "../../../public/images/icons/services/plumbing.svg";
import Construction from "../../../public/images/icons/services/construction.svg";
import PackersMovers from "../../../public/images/icons/services/packers_and_movers.svg";
import { setSearchAnimationDetailsThunk } from "../../../redux-magic/thunks";

/* Styled Components */

const MainContainer = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "column",
	justifyContent: "space-between",
	alignItems: "space-between",
	width: "100%",
	gap: "0.5rem",
	padding: "0rem 0rem 0rem 0rem",
	margin: "0rem 0rem 0rem 0rem",
	[theme.breakpoints.up("sm")]: {
		display: "none",
	},
}));

const CardsContainer = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "row",
	justifyContent: "space-around",
	alignItems: "space-between",
	width: "100%",
	gap: "0.5rem",
	overflowX: "auto",
	overflowY: "hidden",
	whiteSpace: "nowrap",
	scrollbarWidth: "none", // Hide scrollbar for Firefox
	"&::-webkit-scrollbar": {
		display: "none", // Hide scrollbar for Chrome, Safari, and Opera
	},
}));

const CategoryCard = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "column",
	justifyContent: "flex-start",
	alignItems: "center",
	padding: "0.75rem 0.5rem 0rem 0.5rem",
	minWidth: "5.5rem",
	maxWidth: "5.25rem",
	height: "5.5rem",
	gap: "0rem",
	background: theme.palette.background.paper,
	borderRadius: "12px",
}));

const Heading = styled(Typography)(({ theme }) => ({
	fontSize: "0.875rem",
	lineHeight: "1.25rem",
	fontWeight: 500,
	color: theme.palette.text.primary,
	padding: "0rem 0rem 0rem 0.25rem",
}));

const SubText = styled(Typography)(({ theme }) => ({
	fontSize: "0.675rem",
	lineHeight: "0.75rem",
	fontWeight: 500,
	color: theme.palette.text.secondary,
	textAlign: "center",
	wordBreak: "break-word", // Break words if necessary
	whiteSpace: "normal", // Allow normal wrapping of text
}));

const ServicesSection = ({
	dispatch,
	search_filter_state,
	searchanimationsettings,
	searchLocation,
}: {
	dispatch: Function;
	search_filter_state: StateInterface["search_filters_state"];
	searchanimationsettings: StateInterface["search_animation_settings"];
	searchLocation: any;
}) => {
	const router = useRouter();

	const service_categories = [
		{
			id: "construction",
			title: router.locale === "ar-AE" ? "البناء" : router.locale === "kn-IN" ? "ಕನ್ಸ್ಟ್ರಕ್ಷನ್" : "Construction",
			icon: Construction,
		},
		{
			id: "interior_design",
			title: router.locale === "ar-AE" ? "الديكورات الداخلية" : router.locale === "kn-IN" ? "ಇಂಟೀರಿಯರ್ಸ್" : "Interiors",
			icon: InteriorDesign,
		},
		{
			id: "painting",
			title: router.locale === "ar-AE" ? "الطلاء" : router.locale === "kn-IN" ? "ಪೇಂಟಿಂಗ್ " : "Painting",
			icon: Painting,
		},
		{
			id: "packers_and_movers",
			title:
				router.locale === "ar-AE"
					? "شركات النقل والتغليف"
					: router.locale === "kn-IN"
						? "ಪ್ಯಾಕರ್ಸ್  ಮತ್ತು ಮೂವರ್ಸ್"
						: "Packers & Movers",
			icon: PackersMovers,
		},
		{
			id: "electrician",
			title: router.locale === "ar-AE" ? "كهربائي" : router.locale === "kn-IN" ? "ಎಲೆಕ್ಟ್ರಿಷಿಯನ್" : "Electrician",
			icon: Electrician,
		},
		{
			id: "pest_control",
			title: router.locale === "ar-AE" ? "مكافحة الحشرات" : router.locale === "kn-IN" ? "ಕೀಟ ನಿಯಂತ್ರಣ" : "Pest Control",
			icon: PestControl,
		},
	];

	const handleCardClick = (category: string, searchLocation: any) => {
		const { title, coordinates } = searchLocation.value;
		const serviceMapping: { [key: string]: { serviceType: string; serviceSubtype: string } } = {
			construction: { serviceType: "construction", serviceSubtype: "civil_contractor" },
			interior_design: { serviceType: "interiors_and_decor", serviceSubtype: "interior_design" },
			painting: { serviceType: "construction", serviceSubtype: "painting" },
			packers_and_movers: { serviceType: "transport", serviceSubtype: "packers_and_movers" },
			plumbing: { serviceType: "home_and_appliance_repair", serviceSubtype: "plumbing_repair" },
			electrician: { serviceType: "home_and_appliance_repair", serviceSubtype: "electrical_repair" },
			pest_control: { serviceType: "cleaning_and_maintenance", serviceSubtype: "pest_control" },
		};

		const service = serviceMapping[category];
		if (!service) return;

		const { serviceType, serviceSubtype } = service;
		const callbackUrl = encodeURIComponent(
			`${process.env.PRODUCTION_URL}search?search_type=services&location=${title},${coordinates}&service_type=${serviceType}&service_subtype=${serviceSubtype}&locale=${router.locale || "en-In"}`,
		);
		const url = `${process.env.PRODUCTION_URL}loading.html?callbackUrl=${callbackUrl}`;

		window.location.href = url;
	};
	return (
		<MainContainer
			sx={{
				direction: router.locale === "ar-AE" ? "rtl" : "ltr",
			}}
		>
			<Heading
				variant="h6"
				sx={{
					fontWeight: router.locale === "ar-AE" ? 600 : 500,
				}}
			>
				{router.locale === "ar-AE"
					? "استكشف الخدمات"
					: router.locale === "kn-IN"
						? "ಸೇವೆಗಳನ್ನು ಅನ್ವೇಷಿಸಿ"
						: "Explore services"}
			</Heading>

			<CardsContainer>
				{service_categories.map((category, index) => (
					<CategoryCard
						key={index}
						onClick={() => {
							if (!searchLocation) {
								dispatch(
									setSearchAnimationDetailsThunk({
										StartAnimate: searchanimationsettings?.StartAnimate as boolean,
										TriggerSearch: searchanimationsettings?.TriggerSearch as boolean,
										ResetSearch: searchanimationsettings?.ResetSearch as boolean,
										PropertySubCategory: ["Budget", "Status", "Listed by", "Facing"],
										HomeAccordion: false,
										OpenLocationDrawer: true,
									}),
								);
							} else {
								handleCardClick(category.id, searchLocation);
							}
						}}
						sx={{
							gap: index === 0 ? "0.75rem" : index === 3 ? "0.5rem" : "0rem",
						}}
					>
						<SubText variant="subtitle1">{category.title}</SubText>

						<SvgIcon
							component={category.icon}
							inheritViewBox={false}
							viewBox={index === 0 || index === 3 ? "0 0 40 40" : "0 0 85 80"}
							sx={{
								width: index === 0 || index === 3 ? "1.75rem" : "3.5rem",
								height: index === 0 || index === 3 ? "1.75rem" : "3.5rem",
							}}
						/>
					</CategoryCard>
				))}
			</CardsContainer>
		</MainContainer>
	);
};

export default ServicesSection;
