/*
  *Common Imports

*/

import { styled } from "@mui/system";
import React from "react";
/*

*Redux Imports

*/

import { setCreateNewPropertyDefaultLocationThunk } from "../../../../../redux-magic/store";

/*

* Material UI Imports

*/

import { Autocomplete, CircularProgress, InputAdornment, TextField, Typography } from "@mui/material";
/*

* Google Maps Imports

*/
import { useLoadScript } from "@react-google-maps/api";
import usePlacesAutocomplete, { getGeocode, getLatLng } from "use-places-autocomplete";

/*

* Icons Imports

*/

import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import { useRouter } from "next/router";

interface Suggestion {
	description: string;
	matched_substrings: Array<{ length: number; offset: number }>;
	place_id: string;
	reference: string;
	structured_formatting: {
		main_text: string;
		main_text_matched_substrings: Array<{ length: number; offset: number }>;
		secondary_text: string;
	};
	terms: Array<{ offset: number; value: string }>;
	types: Array<string>;
}

interface GeolocationCoordinates {
	latitude: number;
	longitude: number;
	altitude: number | null;
	accuracy: number;
	altitudeAccuracy: number | null;
	heading: number | null;
	speed: number | null;
}
interface GeolocationPosition {
	coords: GeolocationCoordinates;
}
interface GMRGCPlusCode {
	compound_code: string;
	global_code: string;
}
interface GMRGCResultsAddressComponent {
	long_name: string;
	short_name: string;
	types: Array<string>;
}
interface GMRGCResultsGeometryLocation {
	lat: number;
	lng: number;
}
interface GMRGCResultsGeometryBoundsAndGeometryViewport {
	northeast: GMRGCResultsGeometryLocation;
	southwest: GMRGCResultsGeometryLocation;
}
interface GMRGCResultsGeometry {
	bounds: GMRGCResultsGeometryBoundsAndGeometryViewport;
	location: GMRGCResultsGeometryLocation;
	location_type: string;
}
interface GMRGCResults {
	address_components: Array<GMRGCResultsAddressComponent>;
	formatted_address: string;
	geometry: GMRGCResultsGeometry;
	viewport: GMRGCResultsGeometryBoundsAndGeometryViewport;
	place_id: string;
	types: Array<string>;
}
interface GoogleMapsReverseGeocodingApiResponse {
	plus_code: GMRGCPlusCode;
	results: Array<GMRGCResults>;
	status: string;
}

const SearchContainer = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "row",
	justifyContent: "space-between",
	alignItems: "center",
	width: "100%",
	gap: "0.688rem",
	margin: "0rem 0rem 0rem 0rem",
}));

const OptionContainer = styled("li")(({ theme }) => ({
	display: "flex",
	flexDirection: "column",
	justifyContent: "center",
	alignItems: "flex-start",
	width: "100%",
	padding: "0.25rem 0.5rem",
	zIndex: 3,
}));

const OptionText = styled(Typography, {
	shouldForwardProp: (prop) => prop !== "isPrimary",
})<any>(({ theme, isPrimary }) => ({
	display: "flex",
	flexDirection: "row",
	justifyContent: "flex-start",
	alignItems: "flex-start",
	width: "100%",
	fontSize: "0.875rem",
	lineHeight: "1.3125rem",
	fontWeight: 400,
	color: isPrimary ? theme.palette.primary.main : theme.palette.text.primary,
})) as any;

const OptionSubtext = styled(Typography)(({ theme }) => ({
	display: "flex",
	flexDirection: "row",
	justifyContent: "flex-start",
	alignItems: "flex-start",
	width: "100%",
	fontSize: "0.875rem",
	lineHeight: "1.3125rem",
	fontWeight: 400,
	color: theme.palette.text.secondary,
})) as typeof Typography;

const librariesToLoad: ("places" | "drawing" | "geometry" | "localContext" | "visualization")[] = [
	"places",
	"drawing",
	"geometry",
];

const Search = ({
	change,
	fromList,
	setFromList,
	dispatch,
}: {
	change: any;
	fromList: any;
	setFromList: any;
	dispatch: Function;
}) => {
	const router = React.useRef(useRouter()).current;

	/*

  & let's declare the states for the usePlacesAutocomplete hook

  */
	const {
		value,
		suggestions: { data },
		setValue,
		init,
	} = usePlacesAutocomplete({
		initOnMount: false,
		debounce: 500,
	});

	/*

  & let's declare the states for the useLoadScript hook

  */
	const { isLoaded } = useLoadScript({
		googleMapsApiKey: process.env.GOOGLE_MAPS_API_KEY_NO_REFERRER_RESTRICTIONS || "",
		libraries: librariesToLoad as any,
	});

	const [options, setOptions] = React.useState<string[]>([]);

	const [onSelectLoading, setOnSelectLoading] = React.useState(false);

	const getCurrentLocation = () => {
		setOnSelectLoading(true);

		/*
    & Next, let's check if the user has allowed us to use their location and update the corresponding state accordingly.
    */
		if (!navigator.geolocation) {
			return;
		} else {
			/*
      & Next, since it looks like the user has allowed us to use their location, let's get their current location. Let's begin by disabling the location autocomplete input.
      */
			// setAllowUserInputInLocationAutocomplete(true);
			navigator.geolocation.getCurrentPosition(
				async (position: GeolocationPosition) => {
					/*
          & Next, let's extract the latitude and longitude from the position object.
          */
					const { latitude, longitude }: { latitude: number; longitude: number } = position.coords;
					/*
          & Next, let's use Google Maps' Reverse Geocoding API to get the user's current address.
          */
					const googleMapsReverseGeocodingApiResponse: GoogleMapsReverseGeocodingApiResponse = await (
						await fetch(
							"https://maps.googleapis.com/maps/api/geocode/json?latlng=" +
								latitude +
								"," +
								longitude +
								"&key=" +
								(process.env.GOOGLE_MAPS_API_KEY_NO_REFERRER_RESTRICTIONS || ""),
							{ cache: "no-store" },
						)
					).json();
					/*
          & Next, let's extract the user's current address from the API response object.
          */
					const userCurrentAddress: string | undefined =
						googleMapsReverseGeocodingApiResponse?.results[0]?.address_components?.find(
							(address_component: GMRGCResultsAddressComponent) =>
								address_component.types.includes("sublocality_level_1"),
						)?.long_name;
					const userCurrentAreas: Array<string> | undefined = googleMapsReverseGeocodingApiResponse?.results.map(
						(result) => {
							return result.address_components?.find(
								(address_component: GMRGCResultsAddressComponent) =>
									address_component.types.includes("sublocality_level_1") ||
									address_component.types.includes("postal_town") ||
									address_component.types.includes("locality"),
							)?.long_name as string;
						},
					);

					let userCurrentArea: string | undefined;

					let resultPos: number = 0;

					for (let i = 0; i < userCurrentAreas.length; i++) {
						if (userCurrentAreas[i] !== "Bengaluru") {
							userCurrentArea = userCurrentAreas[i];
							resultPos = i;
							break;
						}
					}

					/*
          & Next, let's update the corresponding state with the user's current address. Let's also set the userLocationAllowed state to true and the allowUserInputInLocationAutocomplete state to false.
          */
					const label = userCurrentAddress ? userCurrentAddress : userCurrentArea ? userCurrentArea : "";
					setValue(label);
					dispatch(
						setCreateNewPropertyDefaultLocationThunk({
							location_coordinates: [latitude, longitude],
							address: userCurrentArea as string,
						}),
					);
				},
				async (error) => {},
				{ timeout: 10000, enableHighAccuracy: true },
			);
		}
		setOnSelectLoading(false);
	};

	/*

  & let's define the function to handle the change in the location autocomplete

  */
	const onChangeLocationAutocomplete = async (event: React.SyntheticEvent<HTMLSelectElement>, tvalue: any) => {
		setValue("");

		if (tvalue === "Use current location") {
			getCurrentLocation();
		} else {
			const item = data.find((res) => res.place_id === tvalue);
			if (item) {
				const temp = item.structured_formatting.main_text;

				if (Number.isNaN(+temp)) {
					setFromList(temp);
					setValue(temp);
				} else setFromList(null);
			}
			const { lat, lng } = getLatLng((await getGeocode({ placeId: tvalue }))[0]);
			setOnSelectLoading(true);
			dispatch(
				setCreateNewPropertyDefaultLocationThunk({
					location_coordinates: [lat, lng],
					address: item?.structured_formatting.main_text as string,
				}),
			);
			setOnSelectLoading(false);
		}
	};

	/*

  & let's use the useEffect hook to set the value of the location autocomplete to the default location and initialize the autocomplete

  */

	// React.useEffect(() => {
	// 	if (!fromList) {
	// 		setValue(change);
	// 		init();
	// 	} else {
	// 		setFromList(null);
	// 	}
	// 	// eslint-disable-next-line react-hooks/exhaustive-deps
	// }, [change, isLoaded]);

	React.useEffect(() => {
		init();
	}, [init, setValue]);

	React.useEffect(() => {
		const mapped = ["Use current location", ...data.map((suggestion: Suggestion) => suggestion?.place_id)].filter(
			(option: string, position: number, self: Array<string>) =>
				option !== "" && self.findIndex((item: string) => item === option) === position,
		);
		setOptions(mapped);
	}, [data]);

	const localLang = {
		useCurrentLocation:
			router.locale === "ar-AE" ? "" : router.locale === "kn-IN" ? "ಪ್ರಸ್ತುತ ಸ್ಥಳವನ್ನು ಬಳಸಿ." : "Use current location",
		useGPSorIP:
			router.locale === "ar-AE"
				? ""
				: router.locale === "kn-IN"
					? "ಜಿಪಿಎಸ್ ಅಥವಾ ಐಪಿ ವಿಳಾಸವನ್ನು ಬಳಸಿ"
					: "Use GPS or IP address to locate me",
		inTheMiddleOfNowhere:
			router.locale === "ar-AE" ? "" : router.locale === "kn-IN" ? "ಯಾವುದೇ ಸ್ಥಳದಲ್ಲಿ ಇಲ್ಲ" : "in the middle of nowhere",
		enterLocation: router.locale === "ar-AE" ? "" : router.locale === "kn-IN" ? "ಸ್ಥಳವನ್ನು ನಮೂದಿಸಿ" : "Enter Location",
	};

	return (
		<SearchContainer>
			<Autocomplete
				disablePortal
				freeSolo={false}
				id="combo-box-demo"
				filterOptions={() => options}
				options={options}
				isOptionEqualToValue={(option: string, value: string) => {
					const item = data.find((res: any) => res.structured_formatting.main_text === value)?.place_id;
					return !item ? false : option === item;
				}}
				value={change}
				disabled={onSelectLoading}
				renderOption={(props, option, state, ownerState) => {
					const las: any = data.find((suggestion: any) => suggestion.place_id === option);
					const optionText: string | undefined =
						option === localLang.useCurrentLocation
							? localLang.useCurrentLocation
							: las?.structured_formatting?.main_text;
					const optionSubtext: string | undefined =
						option === localLang.useCurrentLocation
							? localLang.useCurrentLocation
							: las
								? las?.structured_formatting?.secondary_text
								: undefined;

					// Destructure key from props
					const { key, ...restProps } = props;

					return (
						<OptionContainer
							key={key}
							{...restProps}
						>
							<OptionText
								variant="body1"
								component="span"
								isPrimary={option === localLang.useCurrentLocation}
							>
								{optionText}
							</OptionText>
							<OptionSubtext
								variant="body2"
								component="span"
							>
								{optionSubtext
									? optionSubtext === localLang.useCurrentLocation
										? localLang.useGPSorIP
										: optionSubtext
									: localLang.inTheMiddleOfNowhere}
							</OptionSubtext>
						</OptionContainer>
					);
				}}
				// renderOption={(props, option, state, ownerState) => {
				// 	const las: any = data.find((suggestion: any) => suggestion.place_id === option);
				// 	const optionText: string | undefined =
				// 		option === "Use current location" ? "Use current location" : las?.structured_formatting?.main_text;
				// 	const optionSubtext: string | undefined =
				// 		option === "Use current location"
				// 			? "Use current location"
				// 			: las
				// 				? las?.structured_formatting?.secondary_text
				// 				: undefined;
				// 	return (
				// 		<OptionContainer {...props}>
				// 			<OptionText
				// 				variant="body1"
				// 				component="span"
				// 				isPrimary={option === "Use current location"}
				// 			>
				// 				{optionText}
				// 			</OptionText>
				// 			<OptionSubtext
				// 				variant="body2"
				// 				component="span"
				// 			>
				// 				{optionSubtext
				// 					? optionSubtext === "Use current location"
				// 						? "Use GPS or IP address to locate me"
				// 						: optionSubtext
				// 					: "in the middle of nowhere"}
				// 			</OptionSubtext>
				// 		</OptionContainer>
				// 	);
				// }}
				onChange={onChangeLocationAutocomplete}
				sx={{ width: "100%", "& fieldset": { borderRadius: "0.75rem" } }}
				renderInput={(params) => (
					<TextField
						variant="outlined"
						{...params}
						onChange={(e) => {
							setValue(e.target.value);
						}}
						InputProps={{
							...params.InputProps,
							startAdornment: (
								<InputAdornment position="start">
									<LocationOnOutlinedIcon />
								</InputAdornment>
							),
						}}
						placeholder={localLang.enterLocation}
						slotProps={{
							input: {
								sx: {
									"&::placeholder": {
										fontSize: "0.875rem", // Reduce placeholder font size
									},
								},
								...params.InputProps,
								endAdornment: onSelectLoading ? <CircularProgress size="30px" /> : undefined,
							},
						}}
					/>
				)}
			/>
		</SearchContainer>
	);
};

export default Search;
