/* Common imports */
import React from "react";
/* mui imports */

import SingleBedOutlinedIcon from "@mui/icons-material/SingleBedOutlined";
import { Button, Divider, Typography, useTheme } from "@mui/material";
import { styled } from "@mui/system";
import { useRouter } from "next/router";

/* redux imports */
import StateInterface from "../../redux-magic/state-interface";
import { updateSearchFilterActiveOptionsThunk, updateSearchFilterSelectedOptionsThunk } from "../../redux-magic/store";
import { SearchFilterId, SearchFilterOptionRange } from "../../redux-magic/sub-interfaces/search-types";
import { updateSearchFilterAppliedThunk } from "../../redux-magic/thunks";

/* stylings */
const Container = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "column",
	gap: "0.5rem",
	padding: "1rem",
}));

const HeaderContainer = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "row",
	width: "100%",
	color: theme.palette.text.primary,
	gap: "0.5rem",
}));

const CustomCategoryIcon = styled(SingleBedOutlinedIcon)(({ theme }) => ({
	color: theme.palette.primary.main,
	fontSize: "1.25rem",
	[theme.breakpoints.up("md1190")]: {
		fontSize: "1.5rem",
	},
}));

const Heading = styled(Typography)(({ theme }) => ({
	fontSize: "0.875rem",
	[theme.breakpoints.up("sm")]: {
		fontSize: "0.875rem",
	},
	[theme.breakpoints.up("md1190")]: {
		fontSize: "1rem",
	},
}));

const CheckBoxContainer = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "row",
	justifyContent: "flex-start",
	alignItems: "center",
	flexWrap: "wrap",
	margin: "0rem",
	gap: "0.5rem",
	[theme.breakpoints.up("md1190")]: {
		gap: "1rem",
	},
}));

const CheckBoxDiv = styled(Button)(({ theme }) => ({
	display: "flex",
	flexDirection: "row",
	justifyContent: "space-between",
	alignItems: "center",
	borderRadius: "0.5rem",
	padding: "var(--Spacing-spacing-xs, 4px)",
	gap: "var(--Spacing-spacing-xs, 2px)",
	cursor: "pointer",
}));

const Text = styled(Typography)(({ theme }) => ({
	fontSize: "0.825rem",
	[theme.breakpoints.up("sm")]: {
		fontSize: "0.875rem",
	},
	[theme.breakpoints.up("md1190")]: {
		fontSize: "1rem",
	},
	fontWeight: "400",
	lineHeight: "1rem",
	padding: "3px 6px",
	textTransform: "capitalize",
	color: theme.palette.text.primary,
}));

export const _h_bedrooms = ({
	search_id,
	dispatch,
	bedrooms,
}: {
	search_id: SearchFilterId;
	bedrooms: StateInterface["search_filters_state"]["bedrooms"];
	dispatch: Function;
}) => {
	const theme = useTheme();
	const router = useRouter();

	// lets create a state to store the selected data
	const minBedrooms: number = bedrooms
		? (bedrooms.selected_options[0] as SearchFilterOptionRange)
			? (bedrooms.selected_options[0] as SearchFilterOptionRange).value
				? (bedrooms.selected_options[0] as SearchFilterOptionRange).value.unlock_max === true
					? 5
					: (bedrooms.selected_options[0] as SearchFilterOptionRange).value.min
				: 0
			: 0
		: 0;

	const maxBedrooms: number = bedrooms
		? (bedrooms.selected_options[0] as SearchFilterOptionRange)
			? (bedrooms.selected_options[0] as SearchFilterOptionRange).value
				? (bedrooms.selected_options[0] as SearchFilterOptionRange).value.unlock_max === true
					? 5
					: (bedrooms.selected_options[0] as SearchFilterOptionRange).value.max
				: 0
			: 0
		: 0;

	const [state, setState] = React.useState<Array<number>>(
		minBedrooms === 0 && maxBedrooms === 0 ? [] : [minBedrooms, maxBedrooms],
	);

	// const [slide, setSlide] = React.useState<boolean>(false);
	const bedroom = [
		{
			id: "studio",
			title: "Studio",
			value: 0.5,
		},
		{
			id: "1bhk",
			title: "1 BHK",
			value: 1,
		},
		{
			id: "2bhk",
			title: "2 BHK",
			value: 2,
		},
		{
			id: "3bhk",
			title: "3 BHK",
			value: 3,
		},
		{
			id: "4bhk",
			title: "4 BHK",
			value: 4,
		},
		{
			id: "4+bhk",
			title: "4 + BHK",
			value: 5,
		},
	];

	// lets call the useEffect hook to update the state

	React.useEffect(() => {
		dispatch(
			updateSearchFilterSelectedOptionsThunk({
				searchFilterId: search_id,
				newOptions:
					state.length === 0
						? []
						: [
								{
									id: "bedrooms",
									title: "Bedrooms",
									value: {
										min:
											state.length === 0
												? 0
												: state.sort((a, b) => a - b)[0] === 5
													? 4
													: state.sort((a, b) => a - b)[0],
										max:
											state.length === 0
												? 0
												: state.sort((a, b) => a - b)[state.sort((a, b) => a - b).length - 1] === 5
													? 4
													: state.sort((a, b) => a - b)[state.sort((a, b) => a - b).length - 1],
										unlock_max: state.includes(5) ? true : false,
									},
								},
							],
			}),
		);

		dispatch(
			updateSearchFilterActiveOptionsThunk({
				searchFilterId: search_id,
				newOptions: [
					{
						id: "bedrooms",
						title: "Bedrooms",
						value: {
							min: state.length === 0 ? 0 : state.sort((a, b) => a - b)[0] === 5 ? 4 : state.sort((a, b) => a - b)[0],
							max:
								state.length === 0
									? 0
									: state.sort((a, b) => a - b)[state.sort((a, b) => a - b).length - 1] === 5
										? 4
										: state.sort((a, b) => a - b)[state.sort((a, b) => a - b).length - 1],
							unlock_max: state.includes(5) ? true : false,
						},
					},
				],
			}),
		);
	}, [state, dispatch, search_id]);

	React.useEffect(() => {
		const value = bedrooms.selected_options[0]?.value;
		if (typeof value === "object" && "min" in value) {
			// Now TypeScript knows that value is of type SearchFilterOptionValueRange
			const isApplied = value.min !== 0 || value.max !== 0;
			dispatch(
				updateSearchFilterAppliedThunk({
					searchFilterId: search_id,
					isApplied: isApplied,
				}),
			);
		}
		// setState(minBedrooms === 0 && maxBedrooms === 0 ? [] : [minBedrooms, maxBedrooms]);
	}, [bedrooms, search_id, dispatch]);

	/* lets create a function to remove the data from the array */

	const handleRemoveData = (value: number) => {
		const index = state.indexOf(value);

		// lets remove the value

		if (index > -1) {
			const newState = [...state];
			newState.splice(index, 1);
			setState(newState);
		}
	};

	return (
		<Container>
			<HeaderContainer>
				<CustomCategoryIcon />
				<Heading variant="body2">
					{router.locale === "ar-AE" ? "عدد الغرف" : router.locale === "kn-IN" ? "ಕೋಣೆಗಳು" : "Bedrooms"}
				</Heading>
				{/* {state.length !== 0 && <CustomSelectedIcon />} */}
			</HeaderContainer>
			<Divider
				sx={{
					borderBottom: "1px solid var(--Other-New-Divider, rgba(255, 255, 255, 0.12))",
				}}
			/>
			<CheckBoxContainer>
				{bedroom.map((item: any) => {
					const inRange = item.value >= minBedrooms && item.value <= maxBedrooms;
					return (
						<CheckBoxDiv
							key={item.id}
							onClick={() => {
								if (item.value === 5) {
									setState(state.includes(5) ? [] : [5]);
								} else {
									if (state.includes(5)) {
										setState([item.value]);
									} else {
										setState(
											state.includes(item.value)
												? state.filter((val) => val !== item.value)
												: [...state, item.value].sort((a, b) => a - b),
										);
									}
								}
							}}
							sx={{
								background: inRange ? "#FC801933" : "",
								border: inRange ? "1px solid #FC8019" : "1px solid #C0C0C0",
							}}
						>
							{/* {inRange ? <DoneIcon /> : <AddIcon />} */}
							<Text variant="body1">{item.title}</Text>
						</CheckBoxDiv>
					);
				})}
			</CheckBoxContainer>
		</Container>
	);
};
