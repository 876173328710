import {
	SearchFilter,
	SearchFilterOptionText,
	SearchFilterOptionTextActive,
} from "../../../redux-magic/sub-interfaces/search-types";

export const _c_transaction_type: SearchFilter = {
	id: "transaction_type",
	title: "Transaction Type",
	level: 1,
	index: 2,
	presentation_type: "tabs",
	multi: false,
	affects: ["security_deposit", "property_status"],
	affected_by: ["search_type"],
	priority: 1,
	is_advanced: false,
	included_in_search_bar: true,
	option_type: "text",
	possible_options: [
		/*

		^ The database query for the "buy" option also includes "resale".
		* For more information, refer to the "buildDatabaseQueries()" in "lib/data/search-filters/helpers/buildDatabaseQueries.ts".

		~ [UPDATE][2024-12-26][@siddhantvinchurkar]: The "buy" option is now a separate option from "resale".

		*/

		{
			id: "buy",
			title: "Buy",
			value: "buy",
		},
		{
			id: "resale",
			title: "Resale",
			value: "resale",
		},
		{
			id: "rent",
			title: "Rent",
			value: "rent",
		},
		{
			id: "ofb",
			title: "Open for business",
			value: "ofb",
		},
	] as Array<SearchFilterOptionText>,
	active_options: [
		{
			id: "buy",
			title: "Buy",
			value: "buy",
			is_selected: true,
		},
		{
			id: "resale",
			title: "Resale",
			value: "resale",
			is_selected: false,
		},
		{
			id: "rent",
			title: "Rent",
			value: "rent",
			is_selected: false,
		},
		{
			id: "ofb",
			title: "Open for business",
			value: "ofb",
			is_selected: false,
		},
	] as Array<SearchFilterOptionTextActive>,
	selected_options: [
		{
			id: "buy",
			title: "Buy",
			value: "buy",
		},
	] as Array<SearchFilterOptionText>,
	db_collection: "properties",
	db_field: "transaction_type",
	is_visible: true,
	is_applied: true,
	is_accordion_open: false,
};
