/* Common imports */

import React from "react";

/* mui imports */

import EventAvailableOutlinedIcon from "@mui/icons-material/EventAvailableOutlined";
import { Button, Divider, IconButton, InputAdornment, Typography } from "@mui/material";
import { styled } from "@mui/system";
import { useRouter } from "next/router";

/* redux imports */
import CalendarMonthOutlinedIcon from "@mui/icons-material/CalendarMonthOutlined";
import { LocalizationProvider, MobileDatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs, { Dayjs } from "dayjs";
import StateInterface from "../../redux-magic/state-interface";
import { updateSearchFilterActiveOptionsThunk, updateSearchFilterSelectedOptionsThunk } from "../../redux-magic/store";
import {
	SearchFilterId,
	SearchFilterOption,
	SearchFilterOptionText,
} from "../../redux-magic/sub-interfaces/search-types";
import { updateSearchFilterAppliedThunk, updateSearchFilterVisibilityThunk } from "../../redux-magic/thunks";
/* stylings */
const Container = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "column",
	gap: "0.5rem",
	padding: "1rem",
}));

const HeaderContainer = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "row",
	width: "100%",
	color: theme.palette.text.primary,
	gap: "0.5rem",
}));

const CustomEventAvailableOutlinedIcon = styled(EventAvailableOutlinedIcon)(({ theme }) => ({
	// margin: "0rem 0.5rem 0rem 0rem",
	color: theme.palette.primary.main,
	fontSize: "1.25rem",
	[theme.breakpoints.up("md1190")]: {
		fontSize: "1.5rem",
	},
}));

const Heading = styled(Typography)(({ theme }) => ({
	fontSize: "0.875rem",
	[theme.breakpoints.up("sm")]: {
		fontSize: "0.875rem",
	},
	[theme.breakpoints.up("md1190")]: {
		fontSize: "1rem",
	},
}));

const CheckBoxContainer = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "row",
	justifyContent: "flex-start",
	alignItems: "center",
	flexWrap: "wrap",
	margin: "0rem",
	gap: "0.5rem",
	[theme.breakpoints.up("md1190")]: {
		// padding: "0rem 1rem 1rem 1rem",
		gap: "1rem",
	},
}));

const CheckBoxDiv = styled(Button)(({ theme }) => ({
	display: "flex",
	flexDirection: "row",
	justifyContent: "space-between",
	alignItems: "center",
	borderRadius: "0.5rem",
	padding: "var(--Spacing-spacing-xs, 4px)",
	gap: "var(--Spacing-spacing-xs, 2px)",
	cursor: "pointer",
}));

const Text = styled(Typography)(({ theme }) => ({
	fontSize: "0.825rem",
	[theme.breakpoints.up("sm")]: {
		fontSize: "0.875rem",
	},
	[theme.breakpoints.up("md1190")]: {
		fontSize: "1rem",
	},
	fontWeight: "400",
	lineHeight: "1rem",
	padding: "3px 6px",
	textTransform: "capitalize",
	color: theme.palette.text.primary,
}));

export const _n_property_status = ({
	search_id,
	property_status,
	transaction_type,
	property_type,
	dispatch,
}: {
	search_id: SearchFilterId;
	property_status: StateInterface["search_filters_state"]["property_status"];
	transaction_type: StateInterface["search_filters_state"]["transaction_type"];
	property_type: StateInterface["search_filters_state"]["property_type"];
	dispatch: Function;
}) => {
	const router = useRouter();

	const [slide, setSlide] = React.useState<boolean>(false);

	let options: Array<SearchFilterOptionText> = [];
	switch (transaction_type.selected_options[0]?.id) {
		case "buy":
			switch (property_type.selected_options[0]?.id) {
				case "plot":
				case "farm_plot":
					options = [
						{
							id: "ready_for_registration",
							title: "Ready for Registration",
							value: "ready_for_registration",
						},
						{
							id: "under_construction",
							title: "Under Construction",
							value: "under_construction",
						},
					];
					break;

				default:
					options = [
						{
							id: "ready_to_move",
							title: "Ready to Move",
							value: "ready_to_move",
						},
						{
							id: "under_construction",
							title: "Under Construction",
							value: "under_construction",
						},
					];
					break;
			}
			break;
		case "rent":
			options = [
				{
					id: "immediately",
					title: "Immediately",
					value: "immediately",
				},
				{
					id: "select_date",
					title: "Select Date",
					value: "select_date",
				},
			];
			break;
		default:
			options = [
				{
					id: "ready_to_move_in",
					title: "Ready to Move In",
					value: "ready_to_move_in",
				},
				{
					id: "under_construction",
					title: "Under Construction",
					value: "under_construction",
				},
				{
					id: "ready_for_registration",
					title: "Ready for Registration",
					value: "ready_for_registration",
				},
			];
			break;
	}

	const [triggerDate, setTriggerDate] = React.useState(false);

	const handleUpdatePropertyStatus = (value: Array<SearchFilterOption>) => {
		if (value[0]?.id === "select_date" || value[0]?.id === "under_construction") {
			setTriggerDate(true);
		} else {
			setTriggerDate(false);
		}
		dispatch(
			updateSearchFilterSelectedOptionsThunk({
				searchFilterId: search_id,
				newOptions: value,
			}),
		);

		dispatch(
			updateSearchFilterActiveOptionsThunk({
				searchFilterId: search_id,
				newOptions: property_status.active_options.map((item) => ({
					...item,
					is_selected: item.id === value[0]?.id ? true : false,
				})),
			}),
		);
	};

	React.useEffect(() => {
		// lets check if the property status is selected
		let isPropertyStatusSelected = property_status
			? property_status.selected_options.length !== 0
				? true
				: false
			: false;

		// lets update the isApplied value if the property status is selected
		dispatch(updateSearchFilterAppliedThunk({ searchFilterId: search_id, isApplied: isPropertyStatusSelected }));

		// lets set visibility of the property status affects
		property_status.affects.map((item: SearchFilterId) => {
			switch (transaction_type.selected_options[0]?.id) {
				case "buy":
					switch (property_status.selected_options[0]?.id) {
						case "under_construction":
							switch (item) {
								case "possession_date":
									dispatch(
										updateSearchFilterVisibilityThunk({
											searchFilterId: item,
											isVisible: true,
										}),
									);
									break;
								case "available_from":
									dispatch(
										updateSearchFilterVisibilityThunk({
											searchFilterId: item,
											isVisible: false,
										}),
									);
									break;
							}
							break;
						default:
							dispatch(
								updateSearchFilterVisibilityThunk({
									searchFilterId: item,
									isVisible: false,
								}),
							);
							break;
					}
					break;
				case "rent":
					switch (property_status.selected_options[0]?.id) {
						case "select_date":
							switch (item) {
								case "possession_date":
									dispatch(
										updateSearchFilterVisibilityThunk({
											searchFilterId: item,
											isVisible: false,
										}),
									);
									break;
								case "available_from":
									dispatch(
										updateSearchFilterVisibilityThunk({
											searchFilterId: item,
											isVisible: true,
										}),
									);
									break;
							}
							break;
						default:
							dispatch(
								updateSearchFilterVisibilityThunk({
									searchFilterId: item,
									isVisible: false,
								}),
							);
							break;
					}
					break;
				default:
					dispatch(
						updateSearchFilterVisibilityThunk({
							searchFilterId: item,
							isVisible: true,
						}),
					);

					break;
			}

			if (!isPropertyStatusSelected) {
				dispatch(
					updateSearchFilterSelectedOptionsThunk({
						searchFilterId: item,
						newOptions: [],
					}),
				);
			}
		});
	}, [property_status, search_id, dispatch, transaction_type, property_type]);
	const handleUpdateAvaliableFromDate = (date: number) => {
		dispatch(
			updateSearchFilterSelectedOptionsThunk({
				searchFilterId: "available_from",
				newOptions: [
					{
						id: "available_from",
						title: "Available From",
						value: {
							min: date,
							max: date,
							unlock_max: true,
						},
					},
				],
			}),
		);

		dispatch(
			updateSearchFilterActiveOptionsThunk({
				searchFilterId: "available_from",
				newOptions: [
					{
						id: "available_from",
						title: "Available From",
						value: {
							min: date,
							max: date,
							unlock_max: true,
						},
					},
				],
			}),
		);
		dispatch(updateSearchFilterAppliedThunk({ searchFilterId: "available_from", isApplied: true }));
	};
	const handleUpdatePossesionDate = (date: number) => {
		dispatch(
			updateSearchFilterSelectedOptionsThunk({
				searchFilterId: "possession_date",
				newOptions: [
					{
						id: "possession_date",
						title: "Available From",
						value: {
							min: date,
							max: date,
							unlock_max: true,
						},
					},
				],
			}),
		);

		dispatch(
			updateSearchFilterActiveOptionsThunk({
				searchFilterId: "possession_date",
				newOptions: [
					{
						id: "possession_date",
						title: "Available From",
						value: {
							min: date,
							max: date,
							unlock_max: true,
						},
					},
				],
			}),
		);
		dispatch(updateSearchFilterAppliedThunk({ searchFilterId: "possession_date", isApplied: true }));
	};

	const [open, setOpen] = React.useState(true);

	return (
		<Container>
			<HeaderContainer>
				<CustomEventAvailableOutlinedIcon
				// sx={{
				// 	margin: router.locale === "ar-AE" ? "0rem 0rem 0rem 0.5rem" : "0rem 0.5rem 0rem 0rem",
				// }}
				/>
				<Heading variant="body2">
					{transaction_type.selected_options[0]?.id === "rent"
						? router.locale === "ar-AE"
							? ""
							: router.locale === "kn-IN"
								? "ಲಭ್ಯವಿರುವ ದಿನಾಂಕ"
								: "Available from"
						: router.locale === "ar-AE"
							? "التوافر"
							: router.locale === "kn-IN"
								? "ಥಿತಿ"
								: "Status"}
				</Heading>
				{/* {property_status.selected_options.length != 0 && <CustomSelectedIcon />} */}
			</HeaderContainer>
			<Divider
				sx={{
					borderBottom: "1px solid var(--Other-New-Divider, rgba(255, 255, 255, 0.12))",
				}}
			/>

			<CheckBoxContainer>
				{options.map((item: SearchFilterOptionText) => (
					<CheckBoxDiv
						key={item.id}
						onClick={() => {
							const isSelected = item.id === property_status?.selected_options[0]?.id;
							isSelected
								? handleUpdatePropertyStatus([] as Array<SearchFilterOption>)
								: handleUpdatePropertyStatus([item] as Array<SearchFilterOption>);
						}}
						sx={{
							background: property_status?.selected_options[0]?.id === item.id ? "#FC801933" : "",
							border: property_status?.selected_options[0]?.id === item.id ? "1px solid #FC8019" : "1px solid #C0C0C0",
						}}
					>
						{/* {property_status?.selected_options[0]?.id === item.id ? <DoneIcon /> : <AddIcon />} */}
						<Text variant="body1">{item.title}</Text>
					</CheckBoxDiv>
				))}
				{(transaction_type.selected_options[0]?.id === "rent" || transaction_type.selected_options[0]?.id === "buy") &&
				triggerDate ? (
					<div style={{ width: "100%" }}>
						<LocalizationProvider dateAdapter={AdapterDayjs}>
							<MobileDatePicker
								defaultValue={dayjs()}
								open={open}
								onClose={() => {
									setOpen(false);
								}}
								disablePast={true}
								onChange={(date: Dayjs | null) => {
									setOpen(true);
									if (date) {
										/*

											* valueof returns UNIX timestamp passing the same to the function.

											*/
										let date_to_store = date?.valueOf();
										transaction_type.selected_options[0]?.id === "rent"
											? handleUpdateAvaliableFromDate(date_to_store)
											: handleUpdatePossesionDate(date_to_store);
									}
								}}
								slotProps={{
									textField: {
										InputProps: {
											endAdornment: (
												<InputAdornment position="end">
													<IconButton onClick={() => setOpen(true)}>
														<CalendarMonthOutlinedIcon />
													</IconButton>
												</InputAdornment>
											),
										},
									},
								}}
							/>
						</LocalizationProvider>
					</div>
				) : null}
			</CheckBoxContainer>
		</Container>
	);
};
