/*

? First, let's import the interface that will describe the shape of our boost object.

*/

import StateInterface from "../state-interface";

/*

& Now let's describe an initial state for the boost object. This is the state that will be used when the app first fires up. Think of it like a placeholder.

*/

const loading_data: StateInterface["loading"] = {
	source: "home",
	loading: false,
};

export const loading = loading_data;
