/* Common Imports */

/* Redux Imports */

import StateInterface from "../../../redux-magic/state-interface";

/* Component Imports */

import { SvgIcon, Typography, styled } from "@mui/material";

/* Icons / Illustrations */

import { useRouter } from "next/router";
import DeveloperProjects from "../../../public/images/icons/home/developer_projects.svg";
import Rent from "../../../public/images/icons/home/rent.svg";
import RentArabic from "../../../public/images/icons/home/rent_arabic.svg";
import RentKannada from "../../../public/images/icons/home/rent_kannada.svg";
import Resale from "../../../public/images/icons/home/resale.svg";
import Sale from "../../../public/images/icons/home/sale.svg";
import SaleArabic from "../../../public/images/icons/home/sale_arabic.svg";
import SaleKannada from "../../../public/images/icons/home/sale_kannada.svg";
import { setSearchAnimationDetailsThunk } from "../../../redux-magic/thunks";

/* Styled Components */

const MainContainer = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "column",
	justifyContent: "space-between",
	alignItems: "space-between",
	width: "100%",
	gap: "0.5rem",
	padding: "0rem 0rem 0rem 0rem",
	margin: "1.25rem 0rem 1.25rem 0rem",
	// [theme.breakpoints.up("sm")]: {
	// 	display: "none",
	// },
}));

const CardsContainer = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "row",
	justifyContent: "space-around",
	alignItems: "space-between",
	flexWrap: "wrap",
	width: "100%",
	gap: "0.5rem",
}));

const CategoryCard = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "row",
	justifyContent: "center",
	alignItems: "center",
	padding: "0.75rem 0.75rem 0.75rem 0.75rem",
	width: "48%",
	gap: "0rem",
	background: theme.palette.background.paper,
	borderRadius: "16px",
	// boxShadow: "0px 1px 3px rgba(0, 0, 0, 0.12)",
}));

const TextColumn = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "column",
	justifyContent: "center",
	alignItems: "flex-start",
	width: "100%",
	gap: "0rem",
}));

const Heading = styled(Typography)(({ theme }) => ({
	fontSize: "0.875rem",
	lineHeight: "1rem",
	fontWeight: 500,
	color: theme.palette.text.primary,
	padding: "0rem 0rem 0rem 0.25rem",
}));

const SubText = styled(Typography)(({ theme }) => ({
	fontSize: "0.75rem",
	lineHeight: "1rem",
	color: theme.palette.text.secondary,
}));

const Title = styled(Typography)(({ theme }) => ({
	fontSize: "0.875rem",
	lineHeight: "1.25rem",
	fontWeight: 600,
	color: theme.palette.text.primary,
}));

const CategorySection = ({
	searchanimationsettings,
	dispatch,
	searchLocation,
}: {
	searchanimationsettings: StateInterface["search_animation_settings"];
	dispatch: Function;
	searchLocation: any;
}) => {
	const router = useRouter();

	const category_cards = [
		{
			id: "sale",
			subtext: router.locale === "ar-AE" ? "عقارات" : router.locale === "kn-IN" ? "ಆಸ್ತಿಗಳ" : "Properties for",
			title: router.locale === "ar-AE" ? "للبيع" : router.locale === "kn-IN" ? "ಮಾರಾಟ" : "Sale",
			illustration: router.locale === "ar-AE" ? SaleArabic : router.locale === "kn-IN" ? SaleKannada : Sale,
		},
		{
			id: "rent",
			subtext: router.locale === "ar-AE" ? "عقارات" : router.locale === "kn-IN" ? "ಆಸ್ತಿಗಳು" : "Properties for",
			title: router.locale === "ar-AE" ? "للايجار" : router.locale === "kn-IN" ? "ಬಾಡಿಗೆಗೆ" : "Rent",
			illustration: router.locale === "ar-AE" ? RentArabic : router.locale === "kn-IN" ? RentKannada : Rent,
		},
		{
			id: "resale",
			subtext: router.locale === "ar-AE" ? "عقارات" : router.locale === "kn-IN" ? "ಆಸ್ತಿಗಳ" : "Properties for",
			title: router.locale === "ar-AE" ? "إعادة البيع" : router.locale === "kn-IN" ? "ಮರುಮಾರಾಟ" : "Resale",
			illustration: Resale,
		},
		{
			id: "projects",
			subtext: router.locale === "ar-AE" ? "جديدة" : router.locale === "kn-IN" ? "ಹೊಸ" : "New",
			title: router.locale === "ar-AE" ? "مشاريع" : router.locale === "kn-IN" ? "ಯೋಜನೆಗಳು" : "Projects",
			illustration: DeveloperProjects,
		},
	];

	const handleCardClick = (category: string, searchLocation: any) => {
		const { title, coordinates } = searchLocation.value;

		const categoryMapping: { [key: string]: { transactionType: string; additionalParams?: string } } = {
			sale: {
				transactionType: "buy",
				additionalParams: "&property_category=residential&property_type=Apartment",
			},
			rent: {
				transactionType: "rent",
				additionalParams: "&property_category=residential&property_type=Apartment",
			},
			resale: {
				transactionType: "resale",
				additionalParams: "&property_category=residential&property_type=Apartment",
			},
			projects: {
				transactionType: "buy",
				additionalParams: "&property_category=residential&property_type=Apartment&listed_by=developer",
			},
		};

		const categoryInfo = categoryMapping[category];

		if (!categoryInfo) return;

		const { transactionType, additionalParams } = categoryInfo;

		const callbackUrl = encodeURIComponent(
			`${process.env.PRODUCTION_URL}search?search_type=properties&location=${title},${coordinates}&transaction_type=${transactionType}${additionalParams || ""}&locale=${router.locale || "en-In"}`,
		);

		const url = `${process.env.PRODUCTION_URL}loading.html?callbackUrl=${callbackUrl}`;

		window.location.href = url;
	};

	return (
		<MainContainer
			sx={{
				direction: router.locale === "ar-AE" ? "rtl" : "ltr",
			}}
		>
			<Heading
				variant="h6"
				sx={{
					fontWeight: router.locale === "ar-AE" ? 600 : 500,
				}}
			>
				{router.locale === "ar-AE"
					? "ما الذي تبحث عنه؟"
					: router.locale === "kn-IN"
						? "ನೀವು ಏನು ಹುಡುಕುತ್ತಿದ್ದೀರಿ?"
						: "What are you looking for?"}
			</Heading>

			<CardsContainer>
				{category_cards.map((category, index) => {
					return (
						<CategoryCard
							key={index}
							onClick={() => {
								if (!searchLocation) {
									dispatch(
										setSearchAnimationDetailsThunk({
											StartAnimate: searchanimationsettings?.StartAnimate as boolean,
											TriggerSearch: searchanimationsettings?.TriggerSearch as boolean,
											ResetSearch: searchanimationsettings?.ResetSearch as boolean,
											PropertySubCategory: ["Budget", "Status", "Listed by", "Facing"],
											HomeAccordion: false,
											OpenLocationDrawer: true,
										}),
									);
								} else {
									handleCardClick(category.id, searchLocation);
								}
							}}
						>
							<TextColumn>
								<SubText variant="subtitle1">{category.subtext}</SubText>
								<Title variant="subtitle1">{category.title}</Title>
							</TextColumn>

							<SvgIcon
								component={category.illustration}
								inheritViewBox={false}
								viewBox="0 0 40 40"
								sx={{
									width: "50px",
									height: "30px",
								}}
							/>
						</CategoryCard>
					);
				})}
			</CardsContainer>
		</MainContainer>
	);
};

export default CategorySection;
