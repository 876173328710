/* Common imports */

import React, { useRef } from "react";

/* mui imports */

import Brightness1Icon from "@mui/icons-material/Brightness1";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import KeyboardArrowDownOutlinedIcon from "@mui/icons-material/KeyboardArrowDownOutlined";
import PoolOutlinedIcon from "@mui/icons-material/PoolOutlined";
import RemoveCircleOutlineOutlinedIcon from "@mui/icons-material/RemoveCircleOutlineOutlined";
import {
	AccordionSummary,
	Autocomplete,
	Button,
	Checkbox,
	Divider,
	Popper,
	TextField,
	Typography,
	useTheme,
} from "@mui/material";
import { styled } from "@mui/system";
import { useRouter } from "next/router";

/* redux imports */
import StateInterface from "../../redux-magic/state-interface";
import { updateSearchFilterActiveOptionsThunk, updateSearchFilterSelectedOptionsThunk } from "../../redux-magic/store";
import {
	SearchFilterId,
	SearchFilterOption,
	SearchFilterOptionTextActive,
} from "../../redux-magic/sub-interfaces/search-types";
import { updateSearchFilterAppliedThunk } from "../../redux-magic/thunks";

/* stylings */
const Container = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "column",
	gap: "0.5rem",
	padding: "1rem",
}));

const HeaderContainer = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "row",
	width: "100%",
	color: theme.palette.text.primary,
	gap: "0.5rem",
}));

const CustomAccordionSummary = styled(AccordionSummary)(({ theme }) => ({
	padding: "0.5rem 0.75rem 0.5rem 0.75rem",
	margin: "0rem",
	fontSize: "1rem",
	[theme.breakpoints.up("sm")]: {
		fontSize: "1rem",
	},
	[theme.breakpoints.up("md1190")]: {
		fontSize: "1.125rem",
		padding: "1rem 1rem 1rem 1rem",
	},
	"& .MuiAccordionSummary-content": {
		display: "flex",
		flexDirection: "row",
		justifyContent: "flex-start",
		alignItems: "center",
		margin: "0rem",
		[theme.breakpoints.up("md1190")]: {
			margin: "0.75rem 0rem",
		},
	},
}));

const CustomPoolOutlinedIcon = styled(PoolOutlinedIcon)(({ theme }) => ({
	margin: "0rem 0.5rem 0rem 0rem",
	color: theme.palette.primary.main,
	fontSize: "1.25rem",
	[theme.breakpoints.up("md1190")]: {
		fontSize: "1.5rem",
	},
}));

const Heading = styled(Typography)(({ theme }) => ({
	fontSize: "0.875rem",
	[theme.breakpoints.up("sm")]: {
		fontSize: "0.875rem",
	},
	[theme.breakpoints.up("md1190")]: {
		fontSize: "1rem",
	},
}));

const CustomArrowIcon = styled(KeyboardArrowDownOutlinedIcon)(({ theme }) => ({
	width: "1.5rem",
	height: "1.5rem",
	borderRadius: "4px",
	color: "#6e3037",
	backgroundColor: "#ffe9d9",
}));

const CustomSelectedIcon = styled(Brightness1Icon)(({ theme }) => ({
	color: theme.palette.primary.main,
	width: "8px",
	marginTop: "-0.25rem",
	marginLeft: "0.25rem",
}));

const CheckBoxContainer = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "row",
	justifyContent: "flex-start",
	alignItems: "center",
	flexWrap: "wrap",
	margin: "0rem",
	gap: "0.5rem",
	[theme.breakpoints.up("md1190")]: {
		// padding: "0rem 1rem 1rem 1rem",
		gap: "1rem",
	},
}));

const CheckBoxDiv = styled(Button)(({ theme }) => ({
	display: "flex",
	flexDirection: "row",
	justifyContent: "space-between",
	alignItems: "center",
	borderRadius: "0.5rem",
	padding: "0.25rem",
	boxShadow: "none",
	gap: "0.25rem",
	cursor: "pointer",
	background: "#ffe9d9",
}));

const Text = styled(Typography)(({ theme }) => ({
	fontSize: "0.825rem",
	[theme.breakpoints.up("sm")]: {
		fontSize: "0.875rem",
	},
	[theme.breakpoints.up("md1190")]: {
		fontSize: "1rem",
	},
	fontWeight: "400",
	lineHeight: "1rem",
	padding: "3px 6px",
	textTransform: "capitalize",
	color: "#6e3037",
}));

const RemoveIcon = styled(RemoveCircleOutlineOutlinedIcon)(({ theme }) => ({
	color: "#6e3037",
	height: "1rem",
	width: "1rem",
}));

export const _u_amenities = ({
	search_id,
	amenities,
	dispatch,
}: {
	search_id: SearchFilterId;
	amenities: StateInterface["search_filters_state"]["amenities"];
	dispatch: Function;
}) => {
	const theme = useTheme();
	const router = useRouter();

	const AmenityOptions = [
		{
			id: "jogging-track",
			title: "Jogging Track",
			value: "jogging-track",
		},
		{
			id: "swimming-pool",
			title: "Swimming Pool",
			value: "swimming-pool",
		},
		{
			id: "heated-swimming-pool",
			title: "Heated Swimming Pool",
			value: "heated-swimming-pool",
		},
		{
			id: "infinity-pool",
			title: "Infinity Pool",
			value: "infinity-pool",
		},
		{
			id: "toddler-pool",
			title: "Toddler Pool",
			value: "toddler-pool",
		},
		{
			id: "clubhouse",
			title: "Clubhouse",
			value: "clubhouse",
		},
		{
			id: "bonfire-area",
			title: "Bonfire Area",
			value: "bonfire-area",
		},
		{
			id: "park",
			title: "Park",
			value: "park",
		},
		{
			id: "senior-citizen-park",
			title: "Senior Citizen Park",
			value: "senior-citizen-park",
		},
		{
			id: "rain-water-harvesting",
			title: "Rain Water Harvesting",
			value: "rain-water-harvesting",
		},
		{
			id: "security",
			title: "Security",
			value: "security",
		},
		{
			id: "gym",
			title: "Gym",
			value: "gym",
		},
		{
			id: "cctv-surveillance",
			title: "CCTV Surveillance",
			value: "cctv-surveillance",
		},
		{
			id: "amphitheatre",
			title: "Amphitheatre",
			value: "amphitheatre",
		},
		{
			id: "mini-theatre",
			title: "Mini Theatre",
			value: "mini-theatre",
		},
		{
			id: "yoga-deck",
			title: "Yoga Deck",
			value: "yoga-deck",
		},
		{
			id: "landscaped-garden",
			title: "Landscaped Garden",
			value: "landscaped-garden",
		},
		{
			id: "kids-play-area",
			title: "Kids Play Area",
			value: "kids-play-area",
		},
		{
			id: "supermarket",
			title: "Supermarket",
			value: "supermarket",
		},
		{
			id: "atm",
			title: "Atm",
			value: "atm",
		},
		{
			id: "pharmacy",
			title: "Pharmacy",
			value: "pharmacy",
		},
		{
			id: "tennis-court",
			title: "Tennis Court",
			value: "tennis-court",
		},
		{
			id: "basketball-court",
			title: "Basketball Court",
			value: "basketball-court",
		},
		{
			id: "badminton-court",
			title: "Badminton Court",
			value: "badminton-court",
		},
		{
			id: "table-tennis",
			title: "Table Tennis",
			value: "table-tennis",
		},
		{
			id: "indoor-games",
			title: "Indoor Games",
			value: "indoor-games",
		},
		{
			id: "billiards",
			title: "Billiards",
			value: "billiards",
		},
		{
			id: "skating-rink",
			title: "Skating Rink",
			value: "skating-rink",
		},
		{
			id: "volleyball-court",
			title: "Volleyball Court",
			value: "volleyball-court",
		},
		{
			id: "sand-pit",
			title: "Sand Pit",
			value: "sand-pit",
		},
		{
			id: "cricket-practice-net",
			title: "Cricket Practice Net",
			value: "cricket-practice-net",
		},
		{
			id: "football-turf",
			title: "Football Turf",
			value: "football-turf",
		},
		{
			id: "lift",
			title: "Lift",
			value: "lift",
		},
		{
			id: "cafeteria",
			title: "Cafeteria",
			value: "cafeteria",
		},
		{
			id: "car-parking",
			title: "Car Parking",
			value: "car-parking",
		},
		{
			id: "covered-car-parking",
			title: "Covered Car Parking",
			value: "covered-car-parking",
		},
		{
			id: "visitor-parking",
			title: "Visitor Parking",
			value: "visitor-parking",
		},
		{
			id: "electric-car-charging-station",
			title: "Electric Car Charging Station",
			value: "electric-car-charging-station",
		},
		{
			id: "library",
			title: "Library",
			value: "library",
		},
		{
			id: "reading-room",
			title: "Reading Room",
			value: "reading-room",
		},
		{
			id: "multipurpose-hall",
			title: "Multipurpose Hall",
			value: "multipurpose-hall",
		},
		{
			id: "jacuzzi",
			title: "Jacuzzi",
			value: "jacuzzi",
		},
		{
			id: "sauna",
			title: "Sauna",
			value: "sauna",
		},
		{
			id: "spa",
			title: "Spa",
			value: "spa",
		},
		{
			id: "barbecue-area",
			title: "Barbecue Area",
			value: "barbecue-area",
		},
		{
			id: "squash-court",
			title: "Squash Court",
			value: "squash-court",
		},
		{
			id: "power-backup",
			title: "Power Backup",
			value: "power-backup",
		},
		{
			id: "helipad",
			title: "Helipad",
			value: "helipad",
		},
		{
			id: "sewage-treatment-plant",
			title: "Sewage Treatment Plant",
			value: "sewage-treatment-plant",
		},
		{
			id: "intercom",
			title: "Intercom",
			value: "intercom",
		},
		{
			id: "water-softening-plant",
			title: "Water Softening Plant",
			value: "water-softening-plant",
		},
		{
			id: "fire-safety-system",
			title: "Fire Safety System",
			value: "fire-safety-system",
		},
		{
			id: "butterfly-garden",
			title: "Butterfly Garden",
			value: "butterfly-garden",
		},
		{
			id: "cycling-track",
			title: "Cycling Track",
			value: "cycling-track",
		},
		{
			id: "waiting-lounge",
			title: "Waiting Lounge",
			value: "waiting-lounge",
		},
		{
			id: "solar-lighting",
			title: "Solar Lighting",
			value: "solar-lighting",
		},
		{
			id: "salon",
			title: "Salon",
			value: "salon",
		},
		{
			id: "meditation-zone",
			title: "Meditation Zone",
			value: "meditation-zone",
		},
		{
			id: "maintenance-staff",
			title: "Maintenance Staff",
			value: "maintenance-staff",
		},
		{
			id: "outdoor-lounge",
			title: "Outdoor Lounge",
			value: "outdoor-lounge",
		},
		{
			id: "health-clinic",
			title: "Health Clinic",
			value: "health-clinic",
		},
		{
			id: "restaurant",
			title: "Restaurant",
			value: "restaurant",
		},
		{
			id: "golf-course",
			title: "Golf Course",
			value: "golf-course",
		},
		{
			id: "waste-management-plant",
			title: "Waste Management Plant",
			value: "waste-management-plant",
		},
		{
			id: "business-centre",
			title: "Business Centre",
			value: "business-centre",
		},
		{
			id: "party-counter",
			title: "Party Counter",
			value: "party-counter",
		},
		{
			id: "service-lift",
			title: "Service Lift",
			value: "service-lift",
		},
		{
			id: "gas-pipeline",
			title: "Gas Pipeline",
			value: "gas-pipeline",
		},
		{
			id: "play-school",
			title: "Play School",
			value: "play-school",
		},
		{
			id: "terrace-private-garden",
			title: "Terrace / Private Garden",
			value: "terrace-private-garden",
		},
	];

	const [state, setState] = React.useState<Array<SearchFilterOption>>([]);

	const [slide, setSlide] = React.useState<boolean>(false);

	const [autoCompleteOpen, setAutoCompleteOpen] = React.useState<boolean | undefined>(false);

	const popperRef = useRef<HTMLDivElement | null>(null);

	const handleUpdateAmenities = (newValue: Array<SearchFilterOption>) => {
		dispatch(
			updateSearchFilterSelectedOptionsThunk({
				searchFilterId: search_id,
				newOptions: newValue as Array<SearchFilterOption>,
			}),
		);

		dispatch(
			updateSearchFilterActiveOptionsThunk({
				searchFilterId: search_id,
				newOptions: amenities.active_options.map((item: SearchFilterOptionTextActive) => ({
					...item,
					is_selected: newValue.some((obj) => obj.id === item.id) ? true : false,
				})),
			}),
		);
	};

	React.useEffect(() => {
		// lets check if the amenities is selected or not
		let isAmenitiesSelected = amenities ? (amenities.selected_options.length !== 0 ? true : false) : false;

		// lets set the isApplied value to true if the amenities is selected
		dispatch(updateSearchFilterAppliedThunk({ searchFilterId: search_id, isApplied: isAmenitiesSelected }));
	}, [amenities, search_id, dispatch]);

	return (
		<Container>
			<HeaderContainer>
				<CustomPoolOutlinedIcon
				// sx={{
				// 	margin: router.locale === "ar-AE" ? "0rem 0rem 0rem 0.5rem" : "0rem 0.5rem 0rem 0rem",
				// }}
				/>
				<Heading variant="body2">
					{router.locale === "ar-AE"
						? "المرافق"
						: router.locale === "ar-AE"
							? "عدد الحمامات"
							: router.locale === "kn-IN"
								? "ಸೌಲಭ್ಯಗಳು"
								: "Amenities"}
				</Heading>
				{/* {state.length > 0 && <CustomSelectedIcon />} */}
			</HeaderContainer>
			<Divider
				sx={{
					borderBottom: "1px solid var(--Other-New-Divider, rgba(255, 255, 255, 0.12))",
				}}
			/>

			<CheckBoxContainer>
				<Autocomplete
					multiple
					freeSolo
					open={autoCompleteOpen}
					PopperComponent={(props, ref) => (
						<Popper
							{...props}
							ref={popperRef}
							placement="top"
						/>
					)}
					sx={{
						width: "100%",
						margin: "0rem 0rem 0rem 0rem",
					}}
					id="amenity-options"
					value={amenities.selected_options as SearchFilterOption[]}
					onChange={(event, newValue: SearchFilterOption[]) => {
						setState([...newValue]);
						handleUpdateAmenities(newValue);
					}}
					options={AmenityOptions}
					getOptionLabel={(option: SearchFilterOption) => option.title}
					renderTags={(value: SearchFilterOption[]) =>
						value.map((option: SearchFilterOption, index: number) => {
							return (
								<CheckBoxDiv
									key={index}
									onClick={(e) => {
										// Prevent the Autocomplete dropdown from being opened
										e.stopPropagation();
										// Update the state to remove the clicked option
										setState((prev) => prev.filter((item) => item.id !== option.id));
										// Optionally, call handleUpdateAmenities or similar function to update elsewhere
										handleUpdateAmenities(state.filter((item) => item.id !== option.id));
									}}
								>
									<Text>{option.title}</Text>
									<RemoveIcon />
								</CheckBoxDiv>
							);
						})
					}
					renderInput={(params) => (
						<TextField
							{...params}
							variant="outlined"
							label={
								router.locale === "ar-AE"
									? "ابدأ الكتابة هنا..."
									: router.locale === "kn-IN"
										? "ಇಲ್ಲಿ ಟೈಪ್ ಮಾಡಿ..."
										: "Start typing here..."
							}
							onBlur={() => {
								setAutoCompleteOpen(false);
							}}
							onChange={(e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
								if (e.target.value !== "") {
									setAutoCompleteOpen(true);
								} else if (e.target.value.length < 2) {
									setAutoCompleteOpen(false);
								}
							}}
							sx={{
								fontSize: "0.825rem",
								[theme.breakpoints.up("sm")]: {
									fontSize: "0.875rem",
								},
								[theme.breakpoints.up("md1190")]: {
									fontSize: "1rem",
								},
							}}
						/>
					)}
					renderOption={(props, option) => (
						<li {...props}>
							<Checkbox
								icon={<CheckBoxOutlineBlankIcon />}
								checkedIcon={<CheckBoxIcon />}
								checked={state.some((obj) => obj.id === (option as SearchFilterOption).id)}
							/>
							{(option as SearchFilterOption).title}
						</li>
					)}
				/>
			</CheckBoxContainer>
		</Container>
	);
};
