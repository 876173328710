/*

? First, let's import the interface that will describe the shape of our cash transaction object.

*/

import StateInterface from "../state-interface";

/*

& Now let's describe an initial state for the cash transaction object. This is the state that will be used when the app first fires up. Think of it like a placeholder.

*/

export const cash_transaction: StateInterface["cash_transaction"] = {
	_id: "",
	transaction: {
		_id_copy: "",
		user_id: "",
		pack_of_10: {
			pack_price: 0,
			quantity: 0,
			total_price: 0,
			total_beegru_points: 0,
		},
		pack_of_100: {
			pack_price: 0,
			quantity: 0,
			total_price: 0,
			total_beegru_points: 0,
		},
		pack_of_500: {
			pack_price: 0,
			quantity: 0,
			total_price: 0,
			total_beegru_points: 0,
		},
		pack_of_1000: {
			pack_price: 0,
			quantity: 0,
			total_price: 0,
			total_beegru_points: 0,
		},
		beegru_points_amount: 0,
		sub_total: 0,
		gst: 0,
		fiat_amount: 0,
		conversion_rate: 60,
		currency: "",
		order_id: "",
		order_status: "",
		order_amount: 0,
		order_amount_paid: 0,
		order_amount_due: 0,
		order_currency: "",
		order_payment_attempts: 0,
		order_receipt: "",
		razorpay_order_notes: {
			redirect_url: "",
			boost: {
				_id: "",
				boosted_entity_type: "",
				boosted_entity_id: "",
				// user detail
				boosted_by: {
					is_business_profile: false,
					user_id: "",
					business_profile_id: "",
				},
				// // detail
				boost_start_date: "",
				boost_end_date: "",
				trigger_start_date: "",
				trigger_end_date: "",
				// // location
				boost_location: [],
				// // point
				budget: 0,
				total_amount: 0,
				average_hourly_rate: 0,
				// state
				active: true,
				cancelled: false,
				created_at: "",
				updated_at: "",
				cancelled_at: "",
			},
		},
		payment: {
			razorpay_payment_id: "",
			razorpay_order_id: "",
			razorpay_signature: "",
			reason_for_decline: "",
			created_at: "",
		},
		cookie: "",
		transaction_timeout_at: "",
	},
	buy_pack: false,
	created_at: "",
};
