/* Common Imports */

import { styled } from "@mui/system";
import React from "react";

/* Redux Imports */

import { ObjectId } from "mongodb";

/* Component Imports */

import LocalPhoneOutlinedIcon from "@mui/icons-material/LocalPhoneOutlined";
import { Link, Skeleton, Typography } from "@mui/material";
import { useRouter } from "next/router";
import PremiumBadge from "../../../public/images/badges/premium.svg";
import BeegruButton from "../../common-components/buttons/BeegruButton";
import Initials from "../Initials";

/* Styled Components */

const BusinessProfileCardLink = styled(Link)(({ theme }) => ({
	textDecoration: "none",
	color: theme.palette.mode == "dark" ? "#fff" : "#000",
	"&:focus, &:hover, &:visited, &:link, &:active": {
		textDecoration: "none",
	},
}));

const CardContainer = styled("div", {
	shouldForwardProp: (prop) => prop !== "source",
})<{ source: string }>(({ theme, source }) => ({
	background: theme.palette.background.paper,
	display: "flex",
	flexDirection: "column",
	justifyContent: "space-between",
	alignItems: "space-between",
	borderRadius: "16px",
	objectFit: "contain",
	width: source === "search" ? "100%" : "22.05rem",
	height: "12.75rem",
	padding: "1rem",
	// boxShadow: "0px 1px 3px rgba(0, 0, 0, 0.12)",
	scrollSnapAlign: "start",
	/* XXS breakpoint */
	[theme.breakpoints.up("xxs")]: {
		width: source === "search" ? "16rem" : "18rem",
		height: "12.75rem",
	},
	/* iphone 5 */
	[theme.breakpoints.up(theme.breakpoints.values.xs + 39)]: {
		width: source === "search" ? "17.75rem" : "18.05rem",
		height: "12.75rem",
	},
	/*galaxy S8+ - 360*/
	[theme.breakpoints.up(theme.breakpoints.values.xs + 78)]: {
		width: source === "search" ? "20.25rem" : "20.5rem",
		height: "12.75rem",
	},
	/*iphone se - 375*/
	[theme.breakpoints.up(theme.breakpoints.values.xs + 94)]: {
		width: source === "search" ? "21.25rem" : "21.25rem",
		height: "12.75rem",
	},
	/* iphone 12 pro and pixel - 390 */
	[theme.breakpoints.up(theme.breakpoints.values.xs + 108.95)]: {
		width: source === "search" ? "22rem" : "22.05rem",
		height: "12.75rem",
	},
	/*iphone XR - 414*/
	[theme.breakpoints.up(theme.breakpoints.values.xs + 130)]: {
		width: source === "search" ? "23.5rem" : "22.05rem",
		height: "12.75rem",
	},
	/*iphone 14 pro max - 430*/
	[theme.breakpoints.up(theme.breakpoints.values.xs + 149)]: {
		width: source === "search" ? "24.5rem" : "22.05rem",
		height: "12.75rem",
	},
	[theme.breakpoints.up("xsPlus")]: {
		width: source === "search" ? "30.5rem" : "22.05rem",
		height: "12.75rem",
	},
	[theme.breakpoints.up("sm")]: {
		width: "22.05rem",
		height: "12.75rem",
	},
	/*ipad Mini */
	[theme.breakpoints.up(theme.breakpoints.values.sm + 167)]: {
		width: "22.05rem",
		height: "12.75rem",
	},
	/* ipad Air*/
	[theme.breakpoints.up(theme.breakpoints.values.sm + 219)]: {
		width: "22.05rem",
		height: "12.75rem",
	},
	[theme.breakpoints.up("md")]: {
		width: "22.05rem",
		height: "12.75rem",
	},
	/* MDLG Breakpoint iPadPro*/
	[theme.breakpoints.up(theme.breakpoints.values.md + 64)]: {
		width: "22.05rem",
		height: "12.75rem",
	},
	/*720p and 768p breakpoint */
	[theme.breakpoints.up("lg")]: {
		width: "22.05rem",
		height: "12.75rem",
	},
	/* 1080p 125% breakpoint*/
	[theme.breakpoints.up(theme.breakpoints.values.lg + 150)]: {
		width: "22.05rem",
		height: "12.75rem",
	},
	/* 1080p breakpoint*/
	[theme.breakpoints.up("xl")]: {
		width: "22.05rem",
		height: "12.75rem",
	},
	/* XXL breakpoint  2560p*/
	[theme.breakpoints.up(theme.breakpoints.values.xl + 640)]: {
		width: "22.05rem",
		height: "12.75rem",
	},
	/*4k breakpoint 3840p*/
	[theme.breakpoints.up(theme.breakpoints.values.xl + 1920)]: {
		width: "22.05rem",
		height: "12.75rem",
	},
}));

const Image = styled("img")(({ theme }) => ({
	height: "7.25rem",
	width: "7.25rem",
	borderRadius: "12px",
	objectFit: "cover",
	[theme.breakpoints.down("sm")]: {
		height: "7.25rem",
		width: "7.25rem",
	},
}));

const ImageDiv = styled("div")(({ theme }) => ({
	height: "7.25rem",
	width: "7.25rem",
}));

const InfoContainer = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "row",
	justifyContent: "space-between",
	alignItems: "flex-start",
	gap: "1rem",
	width: "100%",
	height: "100%",
}));

const ContentContainer = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "column",
	justifyContent: "space-between",
	alignItems: "flex-end",
	width: "100%",
	gap: "0.5rem",
}));

const TitleAndTypeContainer = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "column",
	justifyContent: "flex-start",
	alignItems: "flex-start",
	gap: "0rem",
	width: "100%",
}));

const TitleContainer = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "row",
	justifyContent: "flex-start",
	alignItems: "center",
	gap: "0.5rem",
	width: "100%",
}));

const ListingContainer = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "column",
	justifyContent: "space-between",
	alignItems: "flex-start",
	width: "100%",
	background: theme.palette.background.default,
	borderRadius: "12px",
	padding: "0.35rem 0.5rem 0.35rem 0.5rem",
	gap: "0.25rem",
}));

const BusinessProfileCardGridView = ({ _id, source }: { _id: ObjectId | string; source?: string }) => {
	const [businessData, setBusinessData] = React.useState<any>({});
	const [loading, setLoading] = React.useState(true);
	const router = React.useRef(useRouter()).current;

	React.useEffect(() => {
		if (_id) {
			fetch(`${process.env.PRODUCTION_API_URL}business-profiles/cards/${_id}`, {
				method: "POST",
				headers: {
					"Content-Type": "text/plain",
				},
			})
				.then((res) => res.json())
				.then((data) => {
					setBusinessData(data.BusinessProfileData);
					setLoading(false);
				});
		}
	}, [_id]);

	if (loading) {
		/* Skeleton loading state */

		return (
			<React.Fragment>
				<CardContainer source={source ? source : ""}>
					<InfoContainer
						sx={{
							height: "auto",
						}}
					>
						<ImageDiv>
							<Skeleton
								variant="rectangular"
								width="7.25rem"
								height="7.25rem"
								animation="wave"
								style={{
									borderRadius: "12px",
								}}
							/>
						</ImageDiv>

						<ContentContainer>
							<TitleAndTypeContainer>
								<Skeleton
									variant="text"
									width="70%"
									height="1.5rem"
									animation="wave"
								/>

								<Skeleton
									variant="text"
									width="50%"
									height="1.25rem"
									animation="wave"
								/>
							</TitleAndTypeContainer>

							<ListingContainer>
								<Skeleton
									variant="text"
									width="1rem"
									height="1.5rem"
									animation="wave"
								/>
								<Skeleton
									variant="text"
									width="7rem"
									height="1.5rem"
									animation="wave"
								/>
							</ListingContainer>
						</ContentContainer>
					</InfoContainer>
					<div
						style={{
							width: "100%",
							height: "100%",
							display: "flex",
							justifyContent: "center",
							alignItems: "center",
						}}
					>
						<Skeleton
							variant="text"
							width="100%"
							height="100%"
							animation="wave"
							style={{
								borderRadius: "12px",
								margin: "0.75rem 0 0 0",
							}}
						/>
					</div>
				</CardContainer>
			</React.Fragment>
		);
	} else {
		/* Card */

		return (
			<React.Fragment>
				<BusinessProfileCardLink
					href={`/${router.locale}/${businessData.business_profile_type}s/${businessData.slug}`}
					rel="noopener"
					target="_self"
					referrerPolicy="no-referrer"
					title={businessData.title ? businessData.title : "Business profile"}
				>
					<CardContainer source={source ? source : ""}>
						<InfoContainer>
							{/* Image */}

							<ImageDiv>
								{!businessData.display_picture_url?.includes("") ? (
									<Image
										alt="Display Picture"
										loading="lazy"
										referrerPolicy="no-referrer"
										height={512}
										width={512}
										src={businessData.display_picture_url}
									/>
								) : (
									<Initials
										title={businessData.title}
										profiledropdown={false}
										header={false}
										businessprofile={false}
										postcard={false}
										comment={false}
										search={false}
										businessProfileCard={true}
									/>
								)}
							</ImageDiv>

							<ContentContainer>
								<TitleAndTypeContainer>
									{/* Title */}

									<TitleContainer>
										<Typography
											variant="body1"
											fontWeight={500}
											sx={{
												lineHeight: "1.25rem",
											}}
										>
											{businessData.title
												? businessData.title.length > (source === "search" ? 12 : 18)
													? businessData.title.substring(0, source === "search" ? 12 : 18) + "..."
													: businessData.title
												: ""}
										</Typography>
										{businessData.subscription_information?.active ? <PremiumBadge /> : null}
									</TitleContainer>

									{/* Type */}

									<Typography
										variant="body2"
										sx={{ width: "100%" }}
									>
										{businessData.business_profile_type
											? businessData.business_profile_type === "professional"
												? "Service Professional"
												: businessData.business_profile_type
											: ""}
									</Typography>
								</TitleAndTypeContainer>

								<ListingContainer>
									<Typography
										variant="body1"
										fontWeight={500}
										sx={{ width: "100%" }}
									>
										{businessData.listingCount}
									</Typography>

									<Typography
										variant="body1"
										sx={{ width: "100%" }}
									>
										{businessData.business_profile_type === "professional"
											? businessData.listingCount === 1
												? "Service"
												: "Services"
											: businessData.listingCount === 1
												? "Property"
												: "Properties"}
									</Typography>
								</ListingContainer>
							</ContentContainer>
						</InfoContainer>

						<BeegruButton
							variant="outlined"
							color="primary"
							sx={{
								letterSpacing: "0.025rem",
								fontWeight: 400,
								width: "100%",
							}}
							startIcon={<LocalPhoneOutlinedIcon />}
							href={
								businessData &&
								businessData.phone &&
								`tel:${businessData.phone.country_code} ${businessData.phone.phone_number}`
							}
						>
							Call now
						</BeegruButton>
					</CardContainer>
				</BusinessProfileCardLink>
			</React.Fragment>
		);
	}
};

export default BusinessProfileCardGridView;
