/*

? First, let's import the interface that will describe the shape of our business profile object.

*/

import StateInterface from "../state-interface";

/*

& Now let's describe an initial state for the business profile object. This is the state that will be used when the app first fires up. Think of it like a placeholder.

*/

const business_data: StateInterface["business_profile"] = {
	employee_details: [
		{
			first_name: "John",
			last_name: "Doe",
			profile_picture_link: "https://via.placeholder.com/150",
			status: "pending",
		},
	],
	_id: "5f9f1b9b9c9d2b0b8c8b4567",
	title: "Heavie Developers",
	slug: "heavier-developers",
	search_metrics: {
		search_string: "interiors for vaishnavi terraces",
		search_points: 1,
		search_string_delimiter: " ",
		location_coordinates: [],
		search_filter_hashes: [],
	},
	business_profile_type: "developer",
	verified: false,
	cover_image: {
		file_id: "",
		url: "",
		video_metadata: {
			duration: 2,
			height: 720,
			width: 1280,
		},
		mime_type: "",
		original_file_server_path: "",
		exists: false,
	},
	display_picture_url: {
		file_id: "",
		url: "",
		video_metadata: {
			duration: 2,
			height: 720,
			width: 1280,
		},
		mime_type: "video/mp4",
		original_file_server_path: "",
		exists: false,
	},
	phone: {
		phone_number: "9980775599",
		country_code: "+91",
	},
	email: {
		email: "mail@heavie.co",
		verified: false,
	},
	is_profile_completed: false,
	precise_location_enabled: false,
	admins: [
		{
			user_id: "62a0a8e45503a8e31dcdab1a",
			access_level: "super-admin",
			added_at: "2022-06-30T18:30:00.000Z",
		},
	],
	admin_user_details: [
		{
			first_name: "Siddhant",
			last_name: "Vinchurkar",
			profile_picture_link: "https://avatars.githubusercontent.com/u/20932114?v=4",
		},
	],
	about: {
		year_established: 2020,
		short_description: "We are a team of developers who are passionate about building awesome products.",
		long_description: "We are a team of developers who are passionate about building awesome products.",
		office_address: [],
		website_url: "https://heavie.co",
		education: "",
		organization: "",
		designation: "",
	},
	locations_of_operation: ["Bangalore, India"],
	services_offered: ["Web Development"],
	expertise: ["Construction", "Architecture"],
	why_choose_us: ["IGBC Certified Buildings", "Luxury"],
	offers: [],
	hiring: [],
	awards_and_honors: [],
	licences_and_certifications: [],
	achievements: [],
	employees: [],
	no_of_employees: 0,
	is_subscribed: false,
	faqs: [
		{
			question: "When will Heavie Eden be completed?",
			answer: "Heavie Eden will be completed by 2024",
		},
	],
	languages: [
		{
			language: "English",
			proficiency: "Excellent",
		},
		{
			language: "Kannada",
			proficiency: "Excellent",
		},
		{
			language: "Hindi",
			proficiency: "Excellent",
		},
	],
	developer_projects: {
		total_projects: 1,
		completed_projects: 0,
		ongoing_projects: 1,
		total_area: "",
	},
	agent_brokerage: [],
	kyc_info: {
		kyc_status: "",
		kyc_comment: "",
		kyc_profile_type: "",
		kyc_type: "",
		assigned_to: [],
		pan: {
			pan_number: "",
			front_image_file_id: "",
			back_image_file_id: "",
		},
		selected_document: "",
		aadhaar: {
			aadhaar_number: "",
			front_image_file_id: "",
			back_image_file_id: "",
		},
		voter_id: {
			voter_id_number: "",
			front_image_file_id: "",
			back_image_file_id: "",
		},
		driving_license: {
			driving_license_number: "",
			front_image_file_id: "",
			back_image_file_id: "",
		},
		passport: {
			passport_number: "",
			front_image_file_id: "",
			back_image_file_id: "",
		},
		tin_certificate: {
			tin_number: "",
			front_image_file_id: "",
		},
		gst_certificate: {
			gst_number: "",
			front_image_file_id: "",
		},
		license_from_legal_authority: {
			license_number: "",
			front_image_file_id: "",
		},
		memorandum_of_agreement: {
			memorandum_of_agreement_number: "",
			front_image_file_id: "",
		},
		rera_certificate: {
			rera_number: "",
			front_image_file_id: "",
		},
		partnership_deed: {
			partnership_deed_number: "",
			front_image_file_id: "",
		},
		partnership_registration_certificate: {
			partnership_reg_certificate_number: "",
			front_image_file_id: "",
		},
		article_of_association: {
			article_of_association_number: "",
			front_image_file_id: "",
		},
		certificate_of_incorporation: {
			certificate_of_incorporation_number: "",
			front_image_file_id: "",
		},
		llp_agreement: {
			llp_agreement_number: "",
			front_image_file_id: "",
		},
		internal_use_documents: [],
	},
	portfolio: [],
	followers: [
		{
			_id: "5f9f1b9b9c9d2b0b8c8b4567",
			is_business_profile: false,
			user_id: "62a0a8e45503a8e31dcdab1a",
			business_profile_id: "62a0a8e45503a8e31dcdab1b",
			followed_at: "2020-06-30T18:30:00.000Z",
		},
	],
	following: [
		{
			_id: "5f9f1b9b9c9d2b0b8c8b4567",
			is_business_profile: false,
			user_id: "62a0a8e45503a8e31dcdab1a",
			business_profile_id: "62a0a8e45503a8e31dcdab1b",
			followed_at: "2020-06-30T18:30:00.000Z",
		},
	],
	recommended_by: [
		{
			is_business_profile: false,
			user_id: "62a0a8e45503a8e31dcdab1a",
			business_profile_id: "62a0a8e45503a8e31dcdab1b",
			message: "Best developer in the world",
			recommended_at: "2020-06-30T18:30:00.000Z",
		},
	],
	saved: [
		{
			entity_type: "property",
			entity_id: "62b2e32e99a2279a2dd33960",
			saved_at: "2020-06-30T18:30:00.000Z",
			saved_by: {
				_id: "5f9f1b9b9c9d2b0b8c8b4567",
				is_business_profile: false,
				user_id: "62a0a8e45503a8e31dcdab1a",
				business_profile_id: "62a0a8e45503a8e31dcdab1b",
			},
		},
	],
	saved_is_public: false,
	analytics: {
		views: [
			{
				is_business_profile: false,
				user_id: "62a0a8e45503a8e31dcdab1a",
				business_profile_id: "62a0a8e45503a8e31dcdab1b",
				viewed_at: "2020-06-30T18:30:00.000Z",
				is_logged_in: false,
				source: "",
			},
		],
	},
	flags: [],
	saved_searches: [],
	industry_type: "real-estate",
	published: true,
	assigned_to: [],
	is_public: true,
	published_at: "2100-01-31T18:00:00.000Z",
	deleted: false,
	deleted_at: "2100-01-31T18:30:00.000Z",
	created_by: "62a0a8e45503a8e31dcdab1a",
	created_at: "2022-06-30T18:30:00.000Z",
	updated_by: "62a0a8e45503a8e31dcdab1a",
	updated_at: "2022-06-30T18:30:00.000Z",
	subscription_information: {
		subscription_id: "",
		plan_slug: "",
		end_date: new Date("2100-01-01"),
		active: false,
	},
	allow_in_featured: false,
	created_using_bd_tool: false,
	test: false,
	managed: false
};

export const business_profile = business_data;
export default business_profile;
