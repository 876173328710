/* Common imports */

import React from "react";

/* mui imports */

import { Accordion, AccordionDetails, AccordionSummary, Button, Divider, Typography } from "@mui/material";
import { styled } from "@mui/system";
import { useRouter } from "next/router";

import Brightness1Icon from "@mui/icons-material/Brightness1";
import ChairOutlinedIcon from "@mui/icons-material/ChairOutlined";
import KeyboardArrowDownOutlinedIcon from "@mui/icons-material/KeyboardArrowDownOutlined";

/* redux imports */
import StateInterface from "../../redux-magic/state-interface";
import {
	SearchFilterId,
	SearchFilterOption,
	SearchFilterOptionTextActive,
} from "../../redux-magic/sub-interfaces/search-types";

import {
	updateSearchFilterActiveOptionsThunk,
	updateSearchFilterAppliedThunk,
	updateSearchFilterSelectedOptionsThunk,
} from "../../redux-magic/thunks";

/* stylings */
const Container = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "column",
	gap: "0.5rem",
	padding: "1rem",
}));

const HeaderContainer = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "row",
	width: "100%",
	color: theme.palette.text.primary,
	gap: "0.5rem",
}));
const CustomAccordion = styled(Accordion)(({ theme }) => ({
	borderRadius: "16px",
	boxShadow: "none",
	"&:: before": {
		display: "none",
	},
}));

const CustomAccordionSummary = styled(AccordionSummary)(({ theme }) => ({
	padding: "0.5rem 0.75rem 0.5rem 0.75rem",
	margin: "0rem",
	fontSize: "1rem",
	[theme.breakpoints.up("sm")]: {
		fontSize: "1rem",
	},
	[theme.breakpoints.up("md1190")]: {
		fontSize: "1.125rem",
		padding: "1rem 1rem 1rem 1rem",
	},
	"& .MuiAccordionSummary-content": {
		display: "flex",
		flexDirection: "row",
		justifyContent: "flex-start",
		alignItems: "center",
		margin: "0rem",
		[theme.breakpoints.up("md1190")]: {
			margin: "0.75rem 0rem",
		},
	},
}));

const CustomChairOutlinedIcon = styled(ChairOutlinedIcon)(({ theme }) => ({
	// margin: "0rem 0.5rem 0rem 0rem",
	color: theme.palette.primary.main,
	fontSize: "1.25rem",
	[theme.breakpoints.up("md1190")]: {
		fontSize: "1.5rem",
	},
}));

const Heading = styled(Typography)(({ theme }) => ({
	fontSize: "0.875rem",
	[theme.breakpoints.up("sm")]: {
		fontSize: "0.875rem",
	},
	[theme.breakpoints.up("md1190")]: {
		fontSize: "1rem",
	},
}));

const CustomArrowIcon = styled(KeyboardArrowDownOutlinedIcon)(({ theme }) => ({
	width: "1.5rem",
	height: "1.5rem",
	borderRadius: "4px",
	color: "#6e3037",
	backgroundColor: "#ffe9d9",
}));

const CustomSelectedIcon = styled(Brightness1Icon)(({ theme }) => ({
	color: theme.palette.primary.main,
	width: "8px",
	marginTop: "-0.25rem",
	marginLeft: "0.25rem",
}));

const CustomAccordionDetails = styled(AccordionDetails)(({ theme }) => ({
	display: "flex",
	flexDirection: "row",
	justifyContent: "flex-start",
	alignItems: "center",
	flexWrap: "wrap",
	margin: "0rem",
	gap: "0.5rem",
	padding: "0rem 0.75rem 1rem 0.75rem",
	[theme.breakpoints.up("md1190")]: {
		padding: "0rem 1rem 1rem 1rem",
		gap: "1rem",
	},
}));

const CheckBoxDiv = styled(Button)(({ theme }) => ({
	display: "flex",
	flexDirection: "row",
	justifyContent: "space-between",
	alignItems: "center",
	borderRadius: "0.5rem",
	padding: "var(--Spacing-spacing-xs, 4px)",
	gap: "var(--Spacing-spacing-xs, 2px)",
	cursor: "pointer",
}));

const Text = styled(Typography)(({ theme }) => ({
	fontSize: "0.825rem",
	[theme.breakpoints.up("sm")]: {
		fontSize: "0.875rem",
	},
	[theme.breakpoints.up("md1190")]: {
		fontSize: "1rem",
	},
	fontWeight: "400",
	lineHeight: "1rem",
	padding: "3px 6px",
	textTransform: "capitalize",
	color: theme.palette.text.primary,
}));

const CheckBoxContainer = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "row",
	justifyContent: "flex-start",
	alignItems: "center",
	flexWrap: "wrap",
	margin: "0rem",
	gap: "0.5rem",
	[theme.breakpoints.up("md1190")]: {
		// padding: "0rem 1rem 1rem 1rem",
		gap: "1rem",
	},
}));

export const _t_furnishing = ({
	search_id,
	furnishing,
	dispatch,
}: {
	search_id: SearchFilterId;
	furnishing: StateInterface["search_filters_state"]["furnishing"];
	dispatch: Function;
}) => {
	const router = useRouter();

	const [slide, setSlide] = React.useState<boolean>(false);

	/* lets create a function to remove the data from the array */

	const handleRemoveData = (item: SearchFilterOption) => {
		// now lets remove the whole object from the array

		dispatch(
			updateSearchFilterSelectedOptionsThunk({
				searchFilterId: search_id,
				newOptions: furnishing.selected_options.filter((i) => i.value !== item.value),
			}),
		);

		// now lets set the is_selected value in active_options to false if the user removes the item

		dispatch(
			updateSearchFilterActiveOptionsThunk({
				searchFilterId: search_id,
				newOptions: furnishing.active_options.map((data: SearchFilterOptionTextActive) => ({
					...data,
					is_selected: item === data ? false : data.is_selected,
				})),
			}),
		);
	};

	const handleUpdateFurnishing = (newValue: Array<SearchFilterOption>) => {
		dispatch(
			updateSearchFilterSelectedOptionsThunk({
				searchFilterId: search_id,
				newOptions: newValue as Array<SearchFilterOption>,
			}),
		);

		dispatch(
			updateSearchFilterActiveOptionsThunk({
				searchFilterId: search_id,
				newOptions: furnishing.active_options.map((item: SearchFilterOptionTextActive) => ({
					...item,
					is_selected: newValue.some((obj) => obj.id === item.id) ? true : false,
				})),
			}),
		);
	};

	React.useEffect(() => {
		// lets check if the furnishing is selected or not
		let isFurnishingSelected = furnishing ? (furnishing.selected_options.length !== 0 ? true : false) : false;

		// lets set the isApplied value to true if the furnishing is selected
		dispatch(updateSearchFilterAppliedThunk({ searchFilterId: search_id, isApplied: isFurnishingSelected }));
	}, [furnishing, search_id, dispatch]);

	return (
		<Container>
			<HeaderContainer>
				<CustomChairOutlinedIcon />
				<Heading variant="body2">
					{router.locale === "ar-AE" ? "التأثيث" : router.locale === "kn-IN" ? "ಸಜ್ಜುಗೊಳಿಸುವಿಕೆ" : "Furnishing"}
				</Heading>
				{/* {furnishing.selected_options.length !== 0 && <CustomSelectedIcon />} */}
			</HeaderContainer>
			<Divider
				sx={{
					borderBottom: "1px solid var(--Other-New-Divider, rgba(255, 255, 255, 0.12))",
				}}
			/>
			<CheckBoxContainer>
				{furnishing.possible_options.map((item: SearchFilterOption) => (
					<CheckBoxDiv
						key={item.id}
						onClick={() => {
							furnishing.selected_options.some((obj: SearchFilterOption) => obj.id === item.id)
								? handleRemoveData(item)
								: handleUpdateFurnishing([...furnishing.selected_options, item]);
						}}
						sx={{
							background: furnishing.selected_options.some((obj: SearchFilterOption) => obj.id === item.id)
								? "#FC801933"
								: "",
							border: furnishing.selected_options.some((obj: SearchFilterOption) => obj.id === item.id)
								? "1px solid #FC8019"
								: "1px solid #C0C0C0",
						}}
					>
						{/* {furnishing.selected_options.some((obj: SearchFilterOption) => obj.id === item.id) ? (
								<DoneIcon />
							) : (
								<AddIcon />
							)} */}
						<Text variant="body1">{item.title}</Text>
					</CheckBoxDiv>
				))}
			</CheckBoxContainer>
		</Container>
	);
};
