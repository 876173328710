/* Common Imports */

import React from "react";
import { styled } from "@mui/system";

/* Component Imports */

import { Typography, useTheme, Skeleton } from "@mui/material";
import { LocationOnOutlined } from "@mui/icons-material";

/* Styled Components */

const Container = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "row",
	justifyContent: "flex-start",
	alignItems: "center",
	width: "100%",
	height: "100%",
	gap: "0.125rem",
	margin: "0rem 0rem 0rem 0rem",
}));

const Text = styled(Typography)(({ theme }) => ({
	fontSize: "0.625rem",
	lineheight: "0.75rem",
	color: theme.palette.text.secondary,
}));

const GridCardLocation = ({ loading, location }: { loading: boolean; location: string }) => {
	const theme = useTheme();

	if (loading) {
		return (
			<React.Fragment>
				<Skeleton
					animation="wave"
					variant="text"
					width="75%"
					height="auto"
					style={{ marginBottom: "0.5rem" }}
				/>
			</React.Fragment>
		);
	}

	return (
		<React.Fragment>
			<Container>
				<LocationOnOutlined
					fontSize="small"
					sx={{
						fontSize: "0.75rem",
						margin: "0.125rem 0rem 0rem 0rem",
						color: theme.palette.text.secondary,
					}}
				/>
				<Text variant="body1">
					{location ? (location.length < 13 ? location : location?.substring(0, 13) + "...") : ""}
				</Text>
			</Container>
		</React.Fragment>
	);
};

export default GridCardLocation;
