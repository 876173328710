/* Common imports */

import React from "react";

/* mui imports */

import Brightness1Icon from "@mui/icons-material/Brightness1";
import KeyboardArrowDownOutlinedIcon from "@mui/icons-material/KeyboardArrowDownOutlined";
import Person2OutlinedIcon from "@mui/icons-material/Person2Outlined";
import { AccordionDetails, Button, Divider, Typography, useTheme } from "@mui/material";
import { styled } from "@mui/system";
import { useRouter } from "next/router";

/* redux imports */
import StateInterface from "../../redux-magic/state-interface";
import {
	SearchFilterId,
	SearchFilterOption,
	SearchFilterOptionTextActive,
} from "../../redux-magic/sub-interfaces/search-types";

import {
	updateSearchFilterActiveOptionsThunk,
	updateSearchFilterAppliedThunk,
	updateSearchFilterSelectedOptionsThunk,
} from "../../redux-magic/thunks";

/* stylings */

const Container = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "column",
	gap: "0.5rem",
	padding: "1rem",
}));

const HeaderContainer = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "row",
	width: "100%",
	color: theme.palette.text.primary,
	gap: "0.5rem",
}));

const CustomCategoryIcon = styled(Person2OutlinedIcon)(({ theme }) => ({
	// margin: "0rem 0.5rem 0rem 0rem",
	color: theme.palette.primary.main,
	fontSize: "1.25rem",
	[theme.breakpoints.up("md1190")]: {
		fontSize: "1.5rem",
	},
}));

const Heading = styled(Typography)(({ theme }) => ({
	fontSize: "0.875rem",
	[theme.breakpoints.up("sm")]: {
		fontSize: "0.875rem",
	},
	[theme.breakpoints.up("md1190")]: {
		fontSize: "1rem",
	},
}));

const CustomArrowIcon = styled(KeyboardArrowDownOutlinedIcon)(({ theme }) => ({
	width: "1.5rem",
	height: "1.5rem",
	borderRadius: "4px",
	color: "#6e3037",
	backgroundColor: "#ffe9d9",
}));

const CustomSelectedIcon = styled(Brightness1Icon)(({ theme }) => ({
	color: theme.palette.primary.main,
	width: "8px",
	marginTop: "-0.25rem",
	marginLeft: "0.25rem",
}));

const CustomAccordionDetails = styled(AccordionDetails)(({ theme }) => ({
	display: "flex",
	flexDirection: "row",
	justifyContent: "flex-start",
	alignItems: "center",
	flexWrap: "wrap",
	margin: "0rem",
	gap: "0.5rem",
	padding: "0rem 0.75rem 1rem 0.75rem",
	[theme.breakpoints.up("md1190")]: {
		padding: "0rem 1rem 1rem 1rem",
		gap: "1rem",
	},
}));

const CheckBoxDiv = styled(Button)(({ theme }) => ({
	display: "flex",
	flexDirection: "row",
	justifyContent: "space-between",
	alignItems: "center",
	borderRadius: "0.5rem",
	padding: "var(--Spacing-spacing-xs, 4px)",
	gap: "var(--Spacing-spacing-xs, 2px)",
	cursor: "pointer",
}));

const Text = styled(Typography)(({ theme }) => ({
	fontSize: "0.825rem",
	[theme.breakpoints.up("sm")]: {
		fontSize: "0.875rem",
	},
	[theme.breakpoints.up("md1190")]: {
		fontSize: "1rem",
	},
	fontWeight: "400",
	lineHeight: "1rem",
	padding: "3px 6px",
	textTransform: "capitalize",
	color: theme.palette.text.primary,
}));

const CheckBoxContainer = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "row",
	justifyContent: "flex-start",
	alignItems: "center",
	flexWrap: "wrap",
	margin: "0rem",
	gap: "0.5rem",
	[theme.breakpoints.up("md1190")]: {
		gap: "1rem",
	},
}));

export const _z_business_profile_type = ({
	search_id,
	business_profile_type,
	dispatch,
}: {
	search_id: SearchFilterId;
	business_profile_type: StateInterface["search_filters_state"]["business_profile_type"];
	dispatch: Function;
}) => {
	const theme = useTheme();
	const router = useRouter();

	const [slide, setSlide] = React.useState<boolean>(business_profile_type.is_accordion_open);

	const handleUpdateBusinessProfileType = (value: Array<SearchFilterOptionTextActive>) => {
		if (value.length > 0) {
			let selected_options: Array<SearchFilterOption> = value.map((option) => {
				let { is_selected, ...rest } = option;
				return rest;
			});

			dispatch(
				updateSearchFilterSelectedOptionsThunk({
					searchFilterId: search_id,
					newOptions: selected_options,
				}),
			);

			dispatch(
				updateSearchFilterActiveOptionsThunk({
					searchFilterId: search_id,
					newOptions: business_profile_type.active_options.map((item) => ({
						...item,
						is_selected: item.id === value[0]?.id ? true : false,
					})),
				}),
			);
		}
	};

	React.useEffect(() => {
		/*

		 * lets check if the business profile type option is selected

		*/

		let isBusinessProfileTypeCategorySelected = business_profile_type
			? business_profile_type.selected_options.length !== 0
				? true
				: false
			: false;

		/*

		* lets set the business profile type is applied to true if the business profile type is selected

		 */
		dispatch(
			updateSearchFilterAppliedThunk({ searchFilterId: search_id, isApplied: isBusinessProfileTypeCategorySelected }),
		);
	}, [business_profile_type, search_id, dispatch]);

	return (
		<Container>
			<HeaderContainer>
				<CustomCategoryIcon
				//
				/>
				<Heading variant="body2">
					{router.locale === "ar-AE"
						? "نوع الملف الشخصي للأعمال"
						: router.locale === "kn-IN"
							? "ವ್ಯಾಪಾರ ಪ್ರೊಫೈಲ್ ಪ್ರಕಾರ"
							: "Business profile type"}
				</Heading>
				{business_profile_type.is_applied && <CustomSelectedIcon />}
			</HeaderContainer>
			<Divider
				sx={{
					borderBottom: "1px solid var(--Other-New-Divider, rgba(255, 255, 255, 0.12))",
				}}
			/>

			<CheckBoxContainer>
				{business_profile_type.active_options.map((item: SearchFilterOptionTextActive) => (
					<CheckBoxDiv
						key={item.id}
						onClick={() => {
							const isSelected = item.id === business_profile_type?.selected_options[0]?.id;
							isSelected
								? handleUpdateBusinessProfileType([] as Array<SearchFilterOptionTextActive>)
								: handleUpdateBusinessProfileType([item] as Array<SearchFilterOptionTextActive>);
						}}
						sx={{
							background: item.is_selected ? "#FC801933" : "",
							border: item.is_selected
								? "1px solid var(--Other-New-Outline-Border, rgba(255, 255, 255, 0.23))"
								: "1px solid #C0C0C0",
						}}
					>
						{/* {item.is_selected ? (
								<DoneIcon sx={{ color: theme.palette.mode === "dark" ? "#ffffff" : "#623816" }} />
							) : (
								<AddIcon sx={{ color: theme.palette.mode === "dark" ? "#ffffff" : "#623816" }} />
							)} */}
						<Text variant="body1">
							{item.title === "Landowners"
								? "Owners"
								: item.title === "Professionals"
									? "Service Professionals"
									: item.title}
						</Text>
					</CheckBoxDiv>
				))}
			</CheckBoxContainer>
		</Container>
	);
};
