/* Common Imports */

import React from "react";
import { styled } from "@mui/system";

/* Component Imports */

import { Typography, Skeleton, useTheme } from "@mui/material";

/* Icon Imports */

import { CorporateFareOutlined } from "@mui/icons-material";

/* Styled Components */

const Container = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "row",
	justifyContent: "flex-start",
	alignItems: "centyer",
	width: "100%",
	height: "100%",
	gap: "0.25rem",
	margin: "0rem 0rem 0rem 0rem",
}));

const Text = styled(Typography)(({ theme }) => ({
	fontSize: "0.75rem",
	lineheight: "0.875rem",
	color: theme.palette.text.secondary,
}));

const PropertyCardConfigAndContact = ({
	loading,
	developer,
	config,
	card_title,
	dispatch,
	session,
	slug,
	title,
	created_by,
	show_contact_details,
}: {
	loading: boolean;
	developer: string;
	config: string | null | undefined;
	card_title: string | undefined;
	dispatch: Function;
	session: any;
	slug: string;
	title: string;
	created_by: any;
	show_contact_details: boolean;
}) => {
	const theme = useTheme();

	if (loading) {
		return (
			<Skeleton
				animation="wave"
				variant="text"
				width="75%"
				height="auto"
				style={{ marginBottom: "0.5rem" }}
			/>
		);
	}

	return (
		<React.Fragment>
			<Container>
				<CorporateFareOutlined
					fontSize="small"
					sx={{
						fontSize: "0.75rem",
						margin: "0.125rem 0rem 0rem 0rem",
						color: theme.palette.text.secondary,
					}}
				/>
				<Text variant="body1">{config}</Text>
			</Container>
		</React.Fragment>
	);
};

export default PropertyCardConfigAndContact;
