/* Common Imports */

import React from "react";
import { styled } from "@mui/system";

/* Component Imports */

import { Typography, useTheme, Skeleton } from "@mui/material";
import { CategoryOutlined } from "@mui/icons-material";

/* Styled Components */

const Container = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "row",
	justifyContent: "flex-start",
	alignItems: "centyer",
	width: "100%",
	height: "100%",
	gap: "0.125rem",
	margin: "0rem 0rem 0rem 0rem",
}));

const Text = styled(Typography)(({ theme }) => ({
	fontSize: "0.75rem",
	lineheight: "0.75rem",
	color: theme.palette.text.secondary,
}));

const SkeletonSection = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "row",
	justifyContent: "space-between",
	alignItems: "center",
	width: "100%",
}));

const GridCardAreaAndConfig = ({ loading, propertyType }: { loading: boolean; propertyType: Array<string> }) => {
	const theme = useTheme();

	if (loading) {
		return (
			<SkeletonSection>
				<Skeleton
					animation="wave"
					variant="text"
					width="30%"
					height="auto"
					style={{ marginBottom: "0.5rem" }}
				/>
				<Skeleton
					animation="wave"
					variant="text"
					width="30%"
					height="auto"
					style={{ marginBottom: "0.5rem" }}
				/>
			</SkeletonSection>
		);
	}

	return (
		<Container>
			<CategoryOutlined
				fontSize="small"
				sx={{
					fontSize: "0.75rem",
					margin: "0.125rem 0rem 0rem 0rem",
					color: theme.palette.text.secondary,
				}}
			/>
			<Text variant="body1">
				{propertyType[0].length > 13 ? propertyType[0].substring(0, 13) + "..." : propertyType[0]}
			</Text>
		</Container>
	);
};

export default GridCardAreaAndConfig;
