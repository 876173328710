/* Common imports */
import React from "react";
/* mui imports */

import Brightness1Icon from "@mui/icons-material/Brightness1";
import KeyboardArrowDownOutlinedIcon from "@mui/icons-material/KeyboardArrowDownOutlined";
import PaymentsOutlinedIcon from "@mui/icons-material/PaymentsOutlined";
import {
	Accordion,
	AccordionDetails,
	AccordionSummary,
	Divider,
	Slide,
	TextField,
	Typography,
	useTheme,
} from "@mui/material";
import { styled } from "@mui/system";
import { useRouter } from "next/router";

/* library impoerts */
import CurrencyToNumberPipe from "../../lib/CurrencyToNumberPipe";
import NumberToCurrencyPipe from "../../lib/NumberToCurrencyPipe";

/* redux imports */
import StateInterface from "../../redux-magic/state-interface";
import { SearchFilterId, SearchFilterOptionRange } from "../../redux-magic/sub-interfaces/search-types";
import { updateSearchFilterAppliedThunk, updateSearchFilterSelectedOptionsThunk } from "../../redux-magic/thunks";
/* stylings */

const CustomAccordion = styled(Accordion)(({ theme }) => ({
	borderRadius: "16px",
	boxShadow: "none",
	"&:: before": {
		display: "none",
	},
}));

const CustomAccordionSummary = styled(AccordionSummary)(({ theme }) => ({
	padding: "0.5rem 0.75rem 0.5rem 0.75rem",
	margin: "0rem",
	fontSize: "1rem",
	[theme.breakpoints.up("sm")]: {
		fontSize: "1rem",
	},
	[theme.breakpoints.up("md1190")]: {
		fontSize: "1.125rem",
		padding: "1rem 1rem 1rem 1rem",
	},
	"& .MuiAccordionSummary-content": {
		display: "flex",
		flexDirection: "row",
		justifyContent: "flex-start",
		alignItems: "center",
		margin: "0rem",
		[theme.breakpoints.up("md1190")]: {
			margin: "0.75rem 0rem",
		},
	},
}));

const CustomCategoryIcon = styled(PaymentsOutlinedIcon)(({ theme }) => ({
	margin: "0rem 0.5rem 0rem 0rem",
	color: theme.palette.primary.main,
	fontSize: "1.25rem",
	[theme.breakpoints.up("md1190")]: {
		fontSize: "1.5rem",
	},
}));

const Heading = styled(Typography)(({ theme }) => ({
	fontSize: "0.875rem",
	[theme.breakpoints.up("sm")]: {
		fontSize: "0.875rem",
	},
	[theme.breakpoints.up("md1190")]: {
		fontSize: "1rem",
	},
}));

const CustomArrowIcon = styled(KeyboardArrowDownOutlinedIcon)(({ theme }) => ({
	width: "1.5rem",
	height: "1.5rem",
	borderRadius: "4px",
	color: "#6e3037",
	backgroundColor: "#ffe9d9",
}));

const CustomSelectedIcon = styled(Brightness1Icon)(({ theme }) => ({
	color: theme.palette.primary.main,
	width: "8px",
	marginTop: "-0.25rem",
	marginLeft: "0.25rem",
}));

const CustomAccordionDetails = styled(AccordionDetails)(({ theme }) => ({
	display: "flex",
	flexDirection: "row",
	justifyContent: "center",
	alignItems: "center",
	flexWrap: "wrap",
	margin: "0rem",
	gap: "1.5rem",
	padding: "0rem 0.75rem 1rem 0.75rem",
	[theme.breakpoints.up("md1190")]: {
		padding: "0rem 1rem 1rem 1rem",
		gap: "1rem",
	},
}));

const Text = styled(TextField)(({ theme }) => ({
	"& .MuiOutlinedInput-root": {
		borderRadius: "0.5rem",
		fontSize: "0.825rem",
		[theme.breakpoints.up("sm")]: {
			fontSize: "0.875rem",
		},
		[theme.breakpoints.up("md1190")]: {
			fontSize: "1rem",
		},
		fontWeight: "400",
		lineHeight: "1rem",
		height: "3.35rem",
	},
}));

// const SubText = styled(Typography)(({ theme }) => ({
// 	fontSize: "0.875rem",
// 	fontWeight: "500",
// 	lineHeight: "1rem",
// 	padding: "3px 6px",
// }));

export const _f_budget = ({
	search_id,
	dispatch,
	budget,
}: {
	search_id: SearchFilterId;
	dispatch: Function;
	budget: StateInterface["search_filters_state"]["budget"];
}) => {
	const theme = useTheme();
	const router = useRouter();

	const [minBudget, setMinBudget] = React.useState<number>(0);
	const [maxBudget, setMaxBudget] = React.useState<number>(0);
	const [slide, setSlide] = React.useState<boolean>(false);
	const [errorMinPrice, setErrorMinPrice] = React.useState<boolean>(false);
	const [errorMaxPrice, setErrorMaxPrice] = React.useState<boolean>(false);

	const minBud = (budget.selected_options[0] as SearchFilterOptionRange)?.value?.min;

	const maxBud = (budget.selected_options[0] as SearchFilterOptionRange)?.value?.max;

	const handleUpdateMinBudget = (e: React.ChangeEvent<HTMLInputElement>) => {
		const value = e.target.value;
		const numberValue = CurrencyToNumberPipe(value);

		if (value === "") {
			setMinBudget(0);
		} else {
			const numberValue = CurrencyToNumberPipe(value);
			if (!isNaN(numberValue)) {
				setMinBudget(numberValue);
			}
		}

		if (maxBudget !== 0 && numberValue > maxBudget) {
			setErrorMinPrice(true);
			setErrorMaxPrice(true);
		} else {
			setErrorMinPrice(false);
			setErrorMaxPrice(false);
		}

		// Dispatch updated budget range
		dispatch(
			updateSearchFilterSelectedOptionsThunk({
				searchFilterId: search_id,
				newOptions: [
					{
						id: "budget",
						title: "Budget",
						value: {
							min: numberValue,
							max: maxBudget,
							unlock_max: maxBudget === 0 ? true : false,
						},
					},
				],
			}),
		);
	};

	const handleUpdateMaxBudget = (e: React.ChangeEvent<HTMLInputElement>) => {
		const value = e.target.value;
		const numberValue = CurrencyToNumberPipe(value);

		if (value === "") {
			setMaxBudget(0);
		} else {
			const numberValue = CurrencyToNumberPipe(value);
			if (!isNaN(numberValue)) {
				setMaxBudget(numberValue);
			}
		}
		if (minBudget !== 0 && numberValue < minBudget) {
			setErrorMinPrice(true);
			setErrorMaxPrice(true);
		} else {
			setErrorMinPrice(false);
			setErrorMaxPrice(false);
		}

		// Dispatch updated budget range
		dispatch(
			updateSearchFilterSelectedOptionsThunk({
				searchFilterId: search_id,
				newOptions: [
					{
						id: "budget",
						title: "Budget",
						value: {
							min: minBudget,
							max: numberValue,
							unlock_max: numberValue === 0 ? true : false,
						},
					},
				],
			}),
		);
	};

	React.useEffect(() => {
		if (budget.selected_options.length === 0) {
			setMinBudget(0);
			setMaxBudget(0);
		}
		const value = budget.selected_options[0]?.value;

		if (typeof value === "object" && "min" in value) {
			setMinBudget(value.min);
			setMaxBudget(value.max);
			const isApplied = value.min !== 0 || value.max !== 0;
			dispatch(
				updateSearchFilterAppliedThunk({
					searchFilterId: search_id,
					isApplied: isApplied,
				}),
			);
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [budget, search_id, dispatch]);
	let minbudget = minBudget;
	let maxbudget = maxBudget;
	return (
		<CustomAccordion
			square={true}
			disableGutters={true}
			onChange={() => {
				setSlide(!slide);
			}}
		>
			<CustomAccordionSummary
				expandIcon={<CustomArrowIcon />}
				aria-controls="panel1a-content"
				id="panel1a-header"
			>
				<CustomCategoryIcon
					sx={{
						margin: router.locale === "ar-AE" ? "0rem 0rem 0rem 0.5rem" : "0rem 0.5rem 0rem 0rem",
					}}
				/>
				<Heading variant="body2">
					{router.locale === "ar-AE" ? "السعر" : router.locale === "kn-IN" ? "ಬಜೆಟ್" : "Budget"}
				</Heading>
				{(minBud || maxBud) > 0 ? <CustomSelectedIcon /> : null}
			</CustomAccordionSummary>
			<Divider
				sx={{
					borderBottom: "1px solid var(--Other-New-Divider, rgba(255, 255, 255, 0.12))",
				}}
			/>
			<Slide
				direction="up"
				in={slide}
				mountOnEnter
				unmountOnExit
			>
				<CustomAccordionDetails>
					<Text
						value={minbudget === 0 ? "" : NumberToCurrencyPipe(minbudget)}
						label={
							router.locale === "ar-AE" ? "الحد الأدنى للسعر" : router.locale === "kn-IN" ? "ಕಡಿಮೆ ಬಜೆಟ್" : "Min budget"
						}
						variant="outlined"
						inputProps={{
							min: 0,
						}}
						sx={{ width: "100%" }}
						onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
							handleUpdateMinBudget(e);
						}}
						error={errorMinPrice}
						// helperText={errorMinPrice ? "Value is more than max budget" : NumberInWords(minBudget)}
						autoComplete="off"
					/>
					{/* <SubText>TO</SubText> */}
					<Text
						value={maxbudget === 0 ? "" : NumberToCurrencyPipe(maxbudget)}
						label={
							router.locale === "ar-AE"
								? "الحد الأقصى للسعر"
								: router.locale === "kn-IN"
									? "ಗರಿಷ್ಠ ಬಜೆಟ್"
									: "Max budget"
						}
						variant="outlined"
						inputProps={{
							min: 0,
						}}
						sx={{ width: "100%" }}
						onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
							handleUpdateMaxBudget(e);
						}}
						error={errorMaxPrice}
						// helperText={errorMinPrice ? "Value is less than min budget" : NumberInWords(maxBudget)}
						autoComplete="off"
					/>
				</CustomAccordionDetails>
			</Slide>
		</CustomAccordion>
	);
};
