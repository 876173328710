/* Common Imports */

import React from "react";

/* Component Imports */

import { styled } from "@mui/system";
import { Typography, Skeleton } from "@mui/material";

/* Styled Components */

const Heading = styled(Typography)(({ theme }) => ({
	fontWeight: 400,
	fontSize: "0.75rem",
	lineHeight: "1rem",
	margin: "0rem 0rem 0rem 0rem",
}));

const GridCardTitle = ({ loading, title }: { loading: boolean; title: string }) => {
	if (loading) {
		return (
			<Skeleton
				animation="wave"
				variant="text"
				width="75%"
				height="auto"
			/>
		);
	}

	return (
		<React.Fragment>
			<Heading variant="body1">
				{title
					? title.length < 20
						? (title)
						: (title)?.substring(0, 20) + "..."
					: ""}
			</Heading>
		</React.Fragment>
	);
};

export default GridCardTitle;
