/* Common Imports */

/* Redux Imports */

import StateInterface from "../../../redux-magic/state-interface";

/* Component Imports */

import { Divider, SvgIcon, Typography, styled, useTheme } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import BeegruButton from "../../common-components/buttons/BeegruButton";

/* Icons / Illustrations */

import { useRouter } from "next/router";
import React from "react";
import GoPremium from "../../../public/images/icons/home/banners/go_premium.svg";
import UnlockDoor from "../../../public/images/icons/home/banners/unlock_door.svg";
import UnlockDoorDesktop from "../../../public/images/icons/home/banners/unlock_door_desktop.svg";

/* Styled Components */

const MainContainer = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "row",
	justifyContent: "space-between",
	alignItems: "space-between",
	width: "100%",
	gap: "2rem",
	padding: "1rem 2rem 1rem 2rem",
	margin: "2.5rem 0rem 1rem 0rem",
	borderRadius: "16px",
	background: theme.palette.mode === "dark" ? "#624936" : "#F5EEE8",
	[theme.breakpoints.down("md")]: {
		padding: "1rem 1.5rem 1rem 1.5rem",
	},
}));

const SectionContainer = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "column",
	justifyContent: "center",
	alignItems: "flex-start",
	width: "100%",
	gap: "1rem",
	[theme.breakpoints.down("md")]: {
		gap: "0rem",
	},
}));

const MobileContainer = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "row",
	justifyContent: "space-between",
	alignItems: "center",
	width: "100%",
	gap: "1rem",
	[theme.breakpoints.up("md")]: {
		display: "none",
	},
}));

const Title = styled(Typography)(({ theme }) => ({
	fontSize: "1.5rem",
	lineHeight: "1.75rem",
	[theme.breakpoints.down("md")]: {
		fontSize: "1.25rem",
		lineHeight: "1.75rem",
	},
	color: theme.palette.mode === "dark" ? "#FFFFFF" : "#212121",
}));

const SubTtext = styled("li")(({ theme }) => ({
	color: theme.palette.mode === "dark" ? "#FFFFFF" : "#212121",
}));

const SecondBannerSection = ({
	session,
	profile_context,
}: {
	session: any;
	profile_context: StateInterface["profile_context"];
}) => {
	const matchesMd = useMediaQuery((theme: { breakpoints: { down: (arg0: string) => any } }) =>
		theme.breakpoints.down("md"),
	);

	const matchesSm = useMediaQuery((theme: { breakpoints: { down: (arg0: string) => any } }) =>
		theme.breakpoints.down("sm"),
	);

	const marginstyle = matchesSm ? "0.5rem 0rem 0rem 0rem" : "0rem 0rem 0rem 0rem";

	const theme = useTheme();

	const router = React.useRef(useRouter()).current;

	if (session !== null && session.user.dbUser.pages.length > 0) {
		return (
			<MainContainer>
				<SectionContainer>
					<Title variant="h6">Get premium benefits</Title>

					{/* Mobile Illustration */}

					<MobileContainer>
						<ul
							style={{
								display: "flex",
								flexDirection: "column",
								justifyContent: "center",
								alignItems: "flex-start",
								gap: "0.5rem",
								fontSize: "0.875rem",
								lineHeight: "1.45rem",
								margin: marginstyle,
								padding: "0rem 0rem 0rem 1.5rem",
							}}
						>
							<SubTtext>Unlimited listings</SubTtext>
							<SubTtext>Enhanced visibility</SubTtext>
							<SubTtext>More clients</SubTtext>
						</ul>

						{/* Mobile Illustration */}

						<SvgIcon
							component={GoPremium}
							inheritViewBox={false}
							viewBox="0 0 180 180"
							sx={{
								width: "90px",
								height: "110px",
								[theme.breakpoints.up("sm")]: {
									display: "none",
								},
							}}
						/>

						{/* Tablet Illustration */}

						<SvgIcon
							component={GoPremium}
							inheritViewBox={false}
							viewBox="0 0 190 180"
							sx={{
								width: "120px",
								height: "120px",
								[theme.breakpoints.down("sm")]: {
									display: "none",
								},
								[theme.breakpoints.up("lg")]: {
									display: "none",
								},
							}}
						/>
					</MobileContainer>

					<ul
						style={{
							display: matchesMd ? "none" : "flex",
							flexDirection: "row",
							justifyContent: "center",
							alignItems: "flex-start",
							gap: "1.5rem",
							margin: "0rem 0rem 0rem 0rem",
							padding: "0rem 0rem 0rem 0rem",
							listStyleType: "none",
						}}
					>
						<SubTtext>Unlimited listings</SubTtext>
						<Divider
							orientation="vertical"
							flexItem
						/>
						<SubTtext>Enhanced visibility</SubTtext>
						<Divider
							orientation="vertical"
							flexItem
						/>
						<SubTtext>More clients</SubTtext>
					</ul>

					<BeegruButton
						flavor="primary"
						variant="outlined"
						size="large"
						sx={{
							marginTop: "1rem",
							[theme.breakpoints.down("md")]: {
								marginTop: "0rem",
							},
							[theme.breakpoints.down("sm")]: {
								width: "100%",
								marginTop: "1rem",
							},
						}}
						href={"/" + router.locale + "/users/" + session.user.dbUser._id + "/account-settings?tab=2"}
					>
						Go premium
					</BeegruButton>
				</SectionContainer>

				{/* Desktop Illustration */}

				<SvgIcon
					component={GoPremium}
					inheritViewBox={false}
					viewBox="0 0 160 180"
					sx={{
						width: "8rem",
						height: "10rem",
						margin: "0rem 3rem 0rem 0rem",
						[theme.breakpoints.down("lg")]: {
							display: "none",
						},
					}}
				/>
			</MainContainer>
		);
	} else {
		return (
			<MainContainer>
				<SectionContainer>
					<Title variant="h6">Find your property</Title>

					{/* Mobile Illustration */}

					<MobileContainer>
						<ul
							style={{
								display: "flex",
								flexDirection: "column",
								justifyContent: "center",
								alignItems: "flex-start",
								gap: "0.5rem",
								fontSize: "0.875rem",
								lineHeight: "1.45rem",
								margin: marginstyle,
								padding: "0rem 0rem 0rem 1.5rem",
							}}
						>
							<SubTtext>Explore a wide range of properties</SubTtext>
							<SubTtext>Connect directly with property owners</SubTtext>
						</ul>

						<SvgIcon
							component={UnlockDoor}
							inheritViewBox={false}
							viewBox="0 0 180 140"
							sx={{
								width: "126px",
								height: "100px",
								[theme.breakpoints.up("sm")]: {
									display: "none",
								},
							}}
						/>
					</MobileContainer>

					<ul
						style={{
							display: matchesMd ? "none" : "flex",
							flexDirection: "row",
							justifyContent: "center",
							alignItems: "flex-start",
							gap: "1.5rem",
							margin: "0rem",
							padding: "0rem 0rem 0rem 0rem",
							listStyleType: "none",
						}}
					>
						<SubTtext>Explore a wide range of properties</SubTtext>
						<Divider
							orientation="vertical"
							flexItem
						/>
						<SubTtext>Connect directly with property owners</SubTtext>
					</ul>

					<BeegruButton
						flavor="primary"
						variant="outlined"
						size="large"
						sx={{
							marginTop: "1rem",
							width: matchesSm ? "100%" : "auto",
						}}
						href={"/" + router.locale + "/search"}
					>
						Search
					</BeegruButton>
				</SectionContainer>

				{/* Desktop Illustration */}

				<SvgIcon
					component={UnlockDoorDesktop}
					inheritViewBox={false}
					viewBox="0 0 220 210"
					sx={{
						width: "25rem",
						height: "12rem",
						[theme.breakpoints.down("md")]: {
							width: "22rem",
							height: "12rem",
						},
						[theme.breakpoints.down("sm")]: {
							display: "none",
						},
					}}
				/>
			</MainContainer>
		);
	}
};

export default SecondBannerSection;
