/* Common imports */
import React from "react";
/* mui imports */

import Brightness1Icon from "@mui/icons-material/Brightness1";
import ZoomOutMapOutlinedIcon from "@mui/icons-material/ZoomOutMapOutlined";
import { Divider, TextField, Typography, useTheme } from "@mui/material";
import { styled } from "@mui/system";
import { useRouter } from "next/router";

/* library imports */

import CurrencyToNumberPipe from "../../lib/CurrencyToNumberPipe";
import NumberToCurrencyPipe from "../../lib/NumberToCurrencyPipe";

/* reedux imports */
import StateInterface from "../../redux-magic/state-interface";
import { updateSearchFilterActiveOptionsThunk, updateSearchFilterSelectedOptionsThunk } from "../../redux-magic/store";
import { SearchFilterId } from "../../redux-magic/sub-interfaces/search-types";
import { updateSearchFilterAppliedThunk } from "../../redux-magic/thunks";

/* stylings */

const Container = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "column",
	gap: "0.5rem",
	padding: "1rem",
}));

const HeaderContainer = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "row",
	width: "100%",
	color: theme.palette.text.primary,
	gap: "0.5rem",
}));

const CustomZoomOutMapOutlinedIcon = styled(ZoomOutMapOutlinedIcon)(({ theme }) => ({
	// margin: "0rem 0.5rem 0rem 0rem",
	color: theme.palette.primary.main,
	fontSize: "1.25rem",
	[theme.breakpoints.up("md1190")]: {
		fontSize: "1.5rem",
	},
}));

const Heading = styled(Typography)(({ theme }) => ({
	fontSize: "0.875rem",
	color: theme.palette.text.primary,
	[theme.breakpoints.up("sm")]: {
		fontSize: "0.875rem",
	},
	[theme.breakpoints.up("md1190")]: {
		fontSize: "1rem",
	},
}));

const CustomSelectedIcon = styled(Brightness1Icon)(({ theme }) => ({
	color: theme.palette.primary.main,
	width: "8px",
	marginTop: "-0.25rem",
	marginLeft: "0.25rem",
}));

const Text = styled(TextField)(({ theme }) => ({
	width: "40%",
	"& .MuiOutlinedInput-root": {
		borderRadius: "0.5rem",
		fontSize: "0.825rem",
		[theme.breakpoints.up("sm")]: {
			fontSize: "0.875rem",
		},
		[theme.breakpoints.up("md1190")]: {
			fontSize: "1rem",
		},
		fontWeight: "400",
		lineHeight: "1rem",
		height: "2.125rem",
	},
}));

const SubText = styled(Typography)(({ theme }) => ({
	fontSize: "0.875rem",
	fontWeight: "400",
	lineHeight: "1rem",
	padding: "3px 6px",
}));

const CustomTypography = styled(Typography)(({ theme }) => ({
	color: theme.palette.mode == "light" ? "#666666" : "",
}));

const CustomSpan = styled("span")(({ theme }) => ({
	color: theme.palette.primary.main,
}));

const CheckBoxContainer = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "row",
	justifyContent: "flex-start",
	alignItems: "center",
	flexWrap: "wrap",
	margin: "0rem",
	gap: "0.5rem",
	[theme.breakpoints.up("md1190")]: {
		// padding: "0rem 1rem 1rem 1rem",
		gap: "1rem",
	},
}));

export const _i_area = ({
	search_id,
	dispatch,
	area,
}: {
	search_id: SearchFilterId;
	dispatch: Function;
	area: StateInterface["search_filters_state"]["area"];
}) => {
	const theme = useTheme();
	const router = useRouter();

	const [minArea, setMinArea] = React.useState<number>(0);
	const [maxArea, setMaxArea] = React.useState<number>(0);
	// const [slide, setSlide] = React.useState<boolean>(false);
	const [error, setError] = React.useState<string>("");
	const [errorMinPrice, setErrorMinPrice] = React.useState<boolean>(false);
	const [errorMaxPrice, setErrorMaxPrice] = React.useState<boolean>(false);

	const handleUpdateMinArea = (e: React.ChangeEvent<HTMLInputElement>) => {
		setMinArea(CurrencyToNumberPipe(e.target.value));
		if (maxArea !== 0 && CurrencyToNumberPipe(e.target.value) > maxArea) {
			setErrorMinPrice(true);
			setErrorMaxPrice(true);
		} else {
			setErrorMinPrice(false);
			setErrorMaxPrice(false);
		}
		dispatch(
			updateSearchFilterSelectedOptionsThunk({
				searchFilterId: search_id,
				newOptions:
					e.target.value === ""
						? [
								{
									id: "area",
									title: "Area",
									value: {
										min: 0,
										max: maxArea,
										unlock_max: true,
									},
								},
							]
						: [
								{
									id: "area",
									title: "Area",
									value: {
										min: CurrencyToNumberPipe(e.target.value),
										max: maxArea,
										unlock_max: true,
									},
								},
							],
			}),
		);

		dispatch(
			updateSearchFilterActiveOptionsThunk({
				searchFilterId: search_id,
				newOptions:
					e.target.value === ""
						? [
								{
									id: "area",
									title: "Area",
									value: {
										min: 0,
										max: maxArea,
										unlock_max: true,
									},
								},
							]
						: [
								{
									id: "area",
									title: "Area",
									value: {
										min: CurrencyToNumberPipe(e.target.value),
										max: maxArea,
										unlock_max: true,
									},
								},
							],
			}),
		);
	};

	const handleUpdateMaxArea = (e: React.ChangeEvent<HTMLInputElement>) => {
		setMaxArea(CurrencyToNumberPipe(e.target.value));
		if (
			(minArea !== 0 && CurrencyToNumberPipe(e.target.value) < minArea) ||
			isNaN(CurrencyToNumberPipe(e.target.value))
		) {
			setErrorMinPrice(true);
			setErrorMaxPrice(true);
		} else {
			setErrorMinPrice(false);
			setErrorMaxPrice(false);
		}
		dispatch(
			updateSearchFilterSelectedOptionsThunk({
				searchFilterId: search_id,
				newOptions:
					e.target.value === ""
						? [
								{
									id: "area",
									title: "Area",
									value: {
										min: minArea,
										max: 0,
										unlock_max: true,
									},
								},
							]
						: [
								{
									id: "area",
									title: "Area",
									value: {
										min: minArea,
										max: CurrencyToNumberPipe(e.target.value),
										unlock_max: true,
									},
								},
							],
			}),
		);

		dispatch(
			updateSearchFilterActiveOptionsThunk({
				searchFilterId: search_id,
				newOptions:
					e.target.value === ""
						? [
								{
									id: "area",
									title: "Area",
									value: {
										min: minArea,
										max: 0,
										unlock_max: true,
									},
								},
							]
						: [
								{
									id: "area",
									title: "Area",
									value: {
										min: minArea,
										max: CurrencyToNumberPipe(e.target.value),
										unlock_max: true,
									},
								},
							],
			}),
		);
	};

	React.useEffect(() => {
		const value = area.selected_options[0]?.value;
		if (typeof value === "object" && "min" in value) {
			// Now TypeScript knows that value is of type SearchFilterOptionValueRange
			const isApplied = value.min !== 0 || value.max !== 0;
			dispatch(
				updateSearchFilterAppliedThunk({
					searchFilterId: search_id,
					isApplied: isApplied,
				}),
			);
		}
	}, [area, search_id, dispatch]);

	return (
		<Container>
			<HeaderContainer>
				<CustomZoomOutMapOutlinedIcon
				// sx={{
				// 	margin: router.locale === "ar-AE" ? "0rem 0rem 0rem 0.5rem" : "0rem 0.5rem 0rem 0rem",
				// }}
				/>
				<CustomTypography>
					<Heading variant="body2">
						{router.locale === "ar-AE" ? "المساحة" : router.locale === "kn-IN" ? "ಏರಿಯಾ" : "Area"}
						<CustomSpan>
							{router.locale === "ar-AE" ? " (قدم مربع)" : router.locale === "kn-IN" ? "(ಸ್ಕ್ಫ್ಟ್)" : "(sqft)"}
						</CustomSpan>
					</Heading>
					{(minArea || maxArea) > 0 ? <CustomSelectedIcon /> : null}
				</CustomTypography>
			</HeaderContainer>
			<Divider
				sx={{
					borderBottom: "1px solid var(--Other-New-Divider, rgba(255, 255, 255, 0.12))",
				}}
			/>

			<CheckBoxContainer>
				<Text
					value={minArea === 0 ? "" : NumberToCurrencyPipe(minArea)}
					id="outlined-basic"
					placeholder={
						router.locale === "ar-AE" ? "أدنى مساحة" : router.locale === "kn-IN" ? "ಕನಿಷ್ಠ ಏರಿಯಾ" : "Min area"
					}
					variant="outlined"
					type="text"
					inputProps={{
						min: 0,
					}}
					onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
						handleUpdateMinArea(e);
					}}
					error={errorMinPrice}
					// helperText={errorMinPrice ? "Value is more than max area" : NumberInWords(minArea)}
					autoComplete="off"
				/>
				<SubText>{router.locale === "ar-AE" ? "إلى" : "to"}</SubText>
				<Text
					value={maxArea === 0 ? "" : NumberToCurrencyPipe(maxArea)}
					id="outlined-basic"
					placeholder={
						router.locale === "ar-AE" ? "أقصى مساحة" : router.locale === "kn-IN" ? "ಗರಿಷ್ಠ ಏರಿಯಾ" : "Max area"
					}
					variant="outlined"
					type="text"
					inputProps={{
						min: 0,
					}}
					onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
						handleUpdateMaxArea(e);
					}}
					error={errorMaxPrice}
					// helperText={errorMinPrice ? "Value is less than min area" : NumberInWords(maxArea)}
					autoComplete="off"
				/>
			</CheckBoxContainer>
		</Container>
	);
};
