/* Common imports */
import React from "react";
/* mui imports */

import BathtubOutlinedIcon from "@mui/icons-material/BathtubOutlined";
import Brightness1Icon from "@mui/icons-material/Brightness1";
import KeyboardArrowDownOutlinedIcon from "@mui/icons-material/KeyboardArrowDownOutlined";
import { AccordionDetails, AccordionSummary, Button, Divider, Typography } from "@mui/material";
import { styled } from "@mui/system";
import { useRouter } from "next/router";

/* redux imports */
import StateInterface from "../../redux-magic/state-interface";
import { updateSearchFilterActiveOptionsThunk, updateSearchFilterSelectedOptionsThunk } from "../../redux-magic/store";
import { SearchFilterId, SearchFilterOptionRange } from "../../redux-magic/sub-interfaces/search-types";
import { updateSearchFilterAppliedThunk } from "../../redux-magic/thunks";

/* stylings */

const Container = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "column",
	gap: "0.5rem",
	padding: "1rem",
}));

const HeaderContainer = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "row",
	width: "100%",
	color: theme.palette.text.primary,
	gap: "0.5rem",
}));

const CustomAccordionSummary = styled(AccordionSummary)(({ theme }) => ({
	padding: "0.5rem 0.75rem 0.5rem 0.75rem",
	margin: "0rem",
	fontSize: "1rem",
	[theme.breakpoints.up("sm")]: {
		fontSize: "1rem",
	},
	[theme.breakpoints.up("md1190")]: {
		fontSize: "1.125rem",
		padding: "1rem 1rem 1rem 1rem",
	},
	"& .MuiAccordionSummary-content": {
		display: "flex",
		flexDirection: "row",
		justifyContent: "flex-start",
		alignItems: "center",
		margin: "0rem",
		[theme.breakpoints.up("md1190")]: {
			margin: "0.75rem 0rem",
		},
	},
}));

const CustomBathtubOutlinedIcon = styled(BathtubOutlinedIcon)(({ theme }) => ({
	// margin: "0rem 0.5rem 0rem 0rem",
	color: theme.palette.primary.main,
	fontSize: "1.25rem",
	[theme.breakpoints.up("md1190")]: {
		fontSize: "1.5rem",
	},
}));

const Heading = styled(Typography)(({ theme }) => ({
	fontSize: "0.875rem",
	[theme.breakpoints.up("sm")]: {
		fontSize: "0.875rem",
	},
	[theme.breakpoints.up("md1190")]: {
		fontSize: "1rem",
	},
}));

const CustomArrowIcon = styled(KeyboardArrowDownOutlinedIcon)(({ theme }) => ({
	width: "1.5rem",
	height: "1.5rem",
	borderRadius: "4px",
	color: "#6e3037",
	backgroundColor: "#ffe9d9",
}));

const CustomSelectedIcon = styled(Brightness1Icon)(({ theme }) => ({
	color: theme.palette.primary.main,
	width: "8px",
	marginTop: "-0.25rem",
	marginLeft: "0.25rem",
}));

const CustomAccordionDetails = styled(AccordionDetails)(({ theme }) => ({
	display: "flex",
	flexDirection: "row",
	justifyContent: "flex-start",
	alignItems: "center",
	flexWrap: "wrap",
	margin: "0rem",
	gap: "0.5rem",
	padding: "0rem 0.75rem 1rem 0.75rem",
	[theme.breakpoints.up("md1190")]: {
		padding: "0rem 1rem 1rem 1rem",
		gap: "1rem",
	},
}));

const CheckBoxDiv = styled(Button)(({ theme }) => ({
	display: "flex",
	flexDirection: "row",
	justifyContent: "space-between",
	alignItems: "center",
	borderRadius: "0.5rem",
	padding: "var(--Spacing-spacing-xs, 4px)",
	gap: "var(--Spacing-spacing-xs, 2px)",
	cursor: "pointer",
}));

const Text = styled(Typography)(({ theme }) => ({
	fontSize: "0.825rem",
	[theme.breakpoints.up("sm")]: {
		fontSize: "0.875rem",
	},
	[theme.breakpoints.up("md1190")]: {
		fontSize: "1rem",
	},
	fontWeight: "400",
	lineHeight: "1rem",
	padding: "3px 6px",
	textTransform: "capitalize",
	color: theme.palette.text.primary,
}));

const CheckBoxContainer = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "row",
	justifyContent: "flex-start",
	alignItems: "center",
	flexWrap: "wrap",
	margin: "0rem",
	gap: "0.5rem",
	[theme.breakpoints.up("md1190")]: {
		// padding: "0rem 1rem 1rem 1rem",
		gap: "1rem",
	},
}));
export const _j_bathrooms = ({
	search_id,
	dispatch,
	bathrooms,
}: {
	search_id: SearchFilterId;
	bathrooms: StateInterface["search_filters_state"]["bathrooms"];
	dispatch: Function;
}) => {
	const router = useRouter();

	// lets create a state to store the selected data

	const minBathroom: number = bathrooms
		? (bathrooms.selected_options[0] as SearchFilterOptionRange)
			? (bathrooms.selected_options[0] as SearchFilterOptionRange).value
				? (bathrooms.selected_options[0] as SearchFilterOptionRange).value.unlock_max === true
					? 5
					: (bathrooms.selected_options[0] as SearchFilterOptionRange).value.min
				: 0
			: 0
		: 0;

	const maxBathroom: number = bathrooms
		? (bathrooms.selected_options[0] as SearchFilterOptionRange)
			? (bathrooms.selected_options[0] as SearchFilterOptionRange).value
				? (bathrooms.selected_options[0] as SearchFilterOptionRange).value.unlock_max === true
					? 5
					: (bathrooms.selected_options[0] as SearchFilterOptionRange).value.max
				: 0
			: 0
		: 0;

	const [state, setState] = React.useState<Array<number>>(
		minBathroom === 0 && maxBathroom === 0 ? [] : [minBathroom, maxBathroom],
	);

	// const [slide, setSlide] = React.useState<boolean>(false);
	const bathroom = [
		{
			id: "1",
			title: "1 ",
			value: 1,
		},
		{
			id: "2",
			title: "2 ",
			value: 2,
		},
		{
			id: "3",
			title: "3 ",
			value: 3,
		},
		{
			id: "4",
			title: "4 ",
			value: 4,
		},
		{
			id: "4+",
			title: "4 + ",
			value: 5,
		},
	];

	// lets call the useEffect hook to update the state

	React.useEffect(() => {
		dispatch(
			updateSearchFilterSelectedOptionsThunk({
				searchFilterId: search_id,
				newOptions:
					state.length === 0
						? []
						: [
								{
									id: "bathrooms",
									title: "Bathrooms",
									value: {
										min:
											state.length === 0
												? 0
												: state.sort((a, b) => a - b)[0] === 5
													? 4
													: state.sort((a, b) => a - b)[0],
										max:
											state.length === 0
												? 0
												: state.sort((a, b) => a - b)[state.sort((a, b) => a - b).length - 1] === 5
													? 4
													: state.sort((a, b) => a - b)[state.sort((a, b) => a - b).length - 1],
										unlock_max: state.includes(5) ? true : false,
									},
								},
							],
			}),
		);

		dispatch(
			updateSearchFilterActiveOptionsThunk({
				searchFilterId: search_id,
				newOptions: [
					{
						id: "bathrooms",
						title: "Bathrooms",
						value: {
							min: state.length === 0 ? 0 : state.sort((a, b) => a - b)[0] === 5 ? 4 : state.sort((a, b) => a - b)[0],
							max:
								state.length === 0
									? 0
									: state.sort((a, b) => a - b)[state.sort((a, b) => a - b).length - 1] === 5
										? 4
										: state.sort((a, b) => a - b)[state.sort((a, b) => a - b).length - 1],
							unlock_max: state.includes(5) ? true : false,
						},
					},
				],
			}),
		);
	}, [state, dispatch, search_id]);

	React.useEffect(() => {
		const value = bathrooms.selected_options[0]?.value;
		if (typeof value === "object" && "min" in value) {
			// Now TypeScript knows that value is of type SearchFilterOptionValueRange
			const isApplied = value.min !== 0 || value.max !== 0;
			dispatch(
				updateSearchFilterAppliedThunk({
					searchFilterId: search_id,
					isApplied: isApplied,
				}),
			);
		}
	}, [bathrooms, search_id, dispatch]);

	/* lets create a function to remove the data from the array */

	const handleRemoveData = (value: number) => {
		//lets remove the value from the array

		// lets find the index of the value

		const index = state.indexOf(value);

		// lets remove the value

		if (index > -1) {
			const newState = [...state];
			newState.splice(index, 1);
			setState(newState);
		}
	};

	return (
		<Container>
			<HeaderContainer>
				<CustomBathtubOutlinedIcon
				// sx={{
				// 	margin: router.locale === "ar-AE" ? "0rem 0rem 0rem 0.5rem" : "0rem 0.5rem 0rem 0rem",
				// }}
				/>
				<Heading variant="body2">
					{router.locale === "ar-AE" ? "عدد الحمامات" : router.locale === "kn-IN" ? "ಬಾತ್‌ರೂಮ್‌ಗಳು" : "Bathrooms"}
				</Heading>
				{/* {state.length !== 0 && <CustomSelectedIcon />} */}
			</HeaderContainer>
			<Divider
				sx={{
					borderBottom: "1px solid var(--Other-New-Divider, rgba(255, 255, 255, 0.12))",
				}}
			/>
			<CheckBoxContainer>
				{bathroom.map((item: any) => {
					const inRange = item.value >= minBathroom && item.value <= maxBathroom;
					return (
						<CheckBoxDiv
							key={item.id}
							onClick={() => {
								inRange ? handleRemoveData(item.value) : setState([...state, item.value].sort((a, b) => a - b));
							}}
							sx={{
								background: inRange ? "#FC801933" : "",
								border: inRange ? "1px solid #FC8019" : "1px solid #C0C0C0",
							}}
						>
							<Text variant="body1">{item.title}</Text>
						</CheckBoxDiv>
					);
				})}
			</CheckBoxContainer>
		</Container>
	);
};
