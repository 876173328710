/*

? First, let's import the required components.

*/

import { ArrowBack, ArrowForward, KeyboardArrowLeft, KeyboardArrowRight } from "@mui/icons-material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import TuneIcon from "@mui/icons-material/Tune";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";

import CloseIcon from "@mui/icons-material/Close";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import {
	Badge,
	Button,
	Chip,
	FormControl,
	FormControlLabel,
	FormLabel,
	IconButton,
	Radio,
	RadioGroup,
	Slide,
	Snackbar,
	SnackbarContent,
	Stack,
	SwipeableDrawer,
	Typography,
	useTheme,
} from "@mui/material";
import { styled } from "@mui/system";
import { useRouter } from "next/router";
import React from "react";
import {
	ofb_property_category_options_active,
	property_category_options_active,
} from "../../../../lib/data/search-filters/_d_property_category";
import StateInterface from "../../../../redux-magic/state-interface";
import { SearchFilterId, SearchFilterOptionValueRange } from "../../../../redux-magic/sub-interfaces/search-types";
import {
	setSearchAnimationDetailsThunk,
	setSearchFilterMetricesThunk,
	updateSearchFilterActiveOptionsThunk,
	updateSearchFilterAppliedThunk,
	updateSearchFilterIsAccordionOpenThunk,
	updateSearchFilterSelectedOptionsThunk,
	updateSearchFilterVisibilityThunk,
} from "../../../../redux-magic/thunks";
import BeegruButton from "../../../common-components/buttons/BeegruButton";
import FilterMobileComponent from "../../../search/FilterMobileComponent";
import BudgetComponent from "../SearchComponent/BudgetComponent";
import PropertyCategoryMobile from "./PropertyCategoryMobile";
import PropertyTypeMobile from "./PropertyTypeMobile";
import ServiceCategoryMobile from "./ServiceCategoryMobile";
import ServiceSubTypeMobile from "./ServiceSubTypeMobile";
import TransactionType from "./TransactionTypeMobile";

const SearchResultsContainer = styled("div")(({ theme }) => ({
	flexDirection: "column",
	justifyContent: "center",
	alignItems: "center",
	width: "100%",
	[theme.breakpoints.up("md1190")]: {
		display: "none",
	},
}));

const ContainerNewFilters = styled("div")(({ theme }) => ({
	gap: "0.5rem",
	flexDirection: "row",
	justifyContent: "center",
	alignItems: "center",
	width: "100%",
	background: "#FAFAFA",
	height: "4rem",
	[theme.breakpoints.down("sm")]: {
		position: "fixed",
		gap: "0.25rem",
		zIndex: 4,
		background: theme.palette.background.paper,
		width: "94.3%",
		height: "auto",
	},
	[theme.breakpoints.up("md1190")]: {
		display: "none",
	},
}));

const FilterChip = styled(IconButton)(({ theme }) => ({
	display: "flex",
	flexDirection: "row",
	justifyContent: "flex-start",
	alignItems: "center",
	padding: "0rem 0.5rem 0rem 0.5rem",
	height: "1.75rem",
	gap: "0.25rem",
	borderRadius: "8px",
	fontWeight: 500,
	color: theme.palette.text.primary,
	background: theme.palette.background.paper,
	[theme.breakpoints.down("md1220")]: { padding: "1rem" },
	[theme.breakpoints.down("sm")]: { padding: "0rem 0.5rem 0rem 0.5rem" },
}));

const DrawerContainer = styled("div")(({ theme }) => ({
	display: "flex",
	width: "100%",
	gap: "0.5rem",
	borderRadius: "16px",
}));

const CustomSwipeableDrawer = styled(SwipeableDrawer)(({ theme }) => ({
	"& .MuiDrawer-paper": {
		display: "flex",
		flexDirection: "column",
		justifyContent: "flex-start",
		alignItems: "space-between",
		// borderRadius: "16px 16px 0px 0px",
		width: "100%",
		height: "100%",
		background: theme.palette.background.default,
	},
}));

const PropertyChips = styled(Chip)(({ theme }) => ({
	display: "flex",
	flexDirection: "row",
	justifyContent: "flex-start",
	alignItems: "center",
	padding: "0rem 0.25rem 0rem 0.5rem",
	height: "1.75rem",
	gap: "0.25rem",
	borderRadius: "8px",
	fontWeight: 500,
	color: theme.palette.text.primary,
	background: theme.palette.background.paper,
	"& .MuiChip-label": {
		padding: "0rem",
		fontWeight: 400,
	},
	"& .MuiChip-deleteIcon": {
		margin: "0rem 0rem 0rem 0rem",
		color: theme.palette.text.primary,
		fontSize: "1rem",
		fontWeight: 400,
	},
	[theme.breakpoints.down("md1220")]: { padding: "1rem" },
	[theme.breakpoints.down("sm")]: { padding: "0rem 0.25rem 0rem 0.5rem" },
})) as typeof Chip;

const LocationChips = styled(Chip)(({ theme }) => ({
	display: "flex",
	alignSelf: "stretch",
	borderRadius: "8px",
	gap: "0.5rem",
})) as typeof Chip;

// const StyledTabs = styled(Tabs)(({ theme }) => ({
// 	alignSelf: "start",
// 	borderRadius: "8px 8px 0px 0px",
// 	backgroundColor: "transparent",
// 	width: "100%",
// 	height: "100%",
// 	margin: "0rem 0rem 0rem 0rem",
// 	gap: "1rem",
// 	"& .MuiTabs-root > .MuiTabs-scroller > .MuiTabs-indicator": {
// 		display: "none",
// 	},
// 	"& .MuiTabs-scrollButtons.Mui-disabled": {
// 		display: "none",
// 	},
// 	"& button": {
// 		borderRadius: "1rem",
// 		height: "100%",
// 		padding: "1rem",
// 		width: "50%",
// 	},
// })) as typeof Tabs;

// const StyledTab = styled(Tab)(({ theme, label }) => ({
// 	textTransform: "none",
// 	fontSize: "0.825rem",
// 	fontWeight: 500,
// 	gap: "0.5rem",
// 	letterSpacing: "0.025rem",
// 	padding: "0.5rem 0rem",
// 	color: theme.palette.mode === "dark" ? "#ffffff" : "#212121",
// 	"&.Mui-selected": {
// 		background: "#ffe9d9",
// 		color: theme.palette.primary.light,
// 		borderRadius: "1rem",
// 	},
// })) as typeof Tab;

const HeaderContainer = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "column",
	justifyContent: "space-between",
	alignItems: "flex-start",
	gap: "0.5rem",
	backgroundColor: theme.palette.background.paper,
	padding: "1rem ",
	// boxShadow: "0px 1px 3px 0px rgba(0, 0, 0, 0.12)",
	zIndex: 3000,
}));

const TitleContainer = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "row",
	justifyContent: "flex-sart",
	alignItems: "center",
	gap: "0.5rem",
}));

const ButtonCompoent = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "row",
	alignItems: "center",
	justifyContent: "space-between",
	paddingLeft: "1rem",
	paddingRight: "1rem",
	zIndex: 3,
}));

const TypographyHeading = styled(Typography)(({ theme }) => ({
	fontWeight: 500,
}));
const LocationContainer = styled("div")(({ theme }) => ({
	display: "flex",
	width: "100%",
	height: "100%",
	alignItems: "center",
	[theme.breakpoints.up("sm")]: {
		display: "none",
	},
}));
const Search = ({
	search_filter_state,
	dispatch,
	searchanimationsettings,
	search_filters_metrices,
	source,
}: {
	search_filter_state: StateInterface["search_filters_state"];
	dispatch: Function;
	searchanimationsettings: StateInterface["search_animation_settings"];
	search_filters_metrices: StateInterface["search_filter_metrices"];
	source?: string;
}) => {
	const theme = useTheme();

	const [trigger, setTrigger] = React.useState(false);

	const DrawerOpen = () => {
		setTrigger(true);
	};

	const DrawerClose = () => {
		setTrigger(false);
	};

	/*

		* Buy, rent ofb ,sservices and business users

	*/

	const [value, setValue] = React.useState(
		search_filter_state.search_type.selected_options[0]?.id === "properties"
			? search_filter_state.transaction_type.selected_options[0]?.id === "buy"
				? "Buy"
				: search_filter_state.transaction_type.selected_options[0]?.id === "rent"
					? "Rent"
					: "Open for business"
			: search_filter_state.search_type.selected_options[0]?.id === "services"
				? "Services"
				: "Business Users",
	);

	/*

	* Function to handle the business users agent, developers, landowners, professionals, users

	*/

	const handleChangeBusinessUsers = (value: string) => {
		dispatch(updateSearchFilterAppliedThunk({ searchFilterId: "property_category", isApplied: false }));
		dispatch(updateSearchFilterAppliedThunk({ searchFilterId: "property_type", isApplied: false }));

		dispatch(updateSearchFilterVisibilityThunk({ searchFilterId: "property_category", isVisible: false }));
		dispatch(updateSearchFilterVisibilityThunk({ searchFilterId: "property_type", isVisible: false }));
		dispatch(updateSearchFilterVisibilityThunk({ searchFilterId: "property_status", isVisible: false }));

		dispatch(updateSearchFilterAppliedThunk({ searchFilterId: "service_category", isApplied: false }));
		dispatch(updateSearchFilterVisibilityThunk({ searchFilterId: "service_category", isVisible: false }));
		dispatch(updateSearchFilterAppliedThunk({ searchFilterId: "service_subtype", isApplied: false }));

		dispatch(updateSearchFilterVisibilityThunk({ searchFilterId: "service_subtype", isVisible: false }));
		dispatch(updateSearchFilterAppliedThunk({ searchFilterId: "service_type", isApplied: false }));
		dispatch(updateSearchFilterVisibilityThunk({ searchFilterId: "service_type", isVisible: false }));

		dispatch(updateSearchFilterAppliedThunk({ searchFilterId: "business_profile_type", isApplied: true }));
		dispatch(updateSearchFilterVisibilityThunk({ searchFilterId: "business_profile_type", isVisible: true }));
		dispatch(
			updateSearchFilterSelectedOptionsThunk({
				searchFilterId: "transaction_type",
				newOptions: [],
			}),
		);
		dispatch(
			updateSearchFilterAppliedThunk({
				searchFilterId: "transaction_type",
				isApplied: false,
			}),
		);
		dispatch(
			updateSearchFilterSelectedOptionsThunk({
				searchFilterId: "search_type",
				newOptions: [
					{
						id: "business_profiles",
						title: "Business Profiles",
						value: "business_profiles",
					},
				],
			}),
		);
		dispatch(
			updateSearchFilterSelectedOptionsThunk({
				searchFilterId: "business_profile_type",
				newOptions: [
					{
						id: value === undefined ? "agents" : value.toLocaleLowerCase(),
						title: value === undefined ? "Agents" : value,
						value: value === undefined ? "agents" : value.toLocaleLowerCase(),
					},
				],
			}),
		);

		const business_profile_type = search_filter_state.business_profile_type.active_options.map((item) => ({
			...item,
			is_selected: item.title === value ? true : false,
		}));

		dispatch(
			updateSearchFilterActiveOptionsThunk({
				searchFilterId: "business_profile_type",
				newOptions: business_profile_type,
			}),
		);
		dispatch(
			updateSearchFilterAppliedThunk({
				searchFilterId: "search_type",
				isApplied: true,
			}),
		);

		dispatch(
			updateSearchFilterIsAccordionOpenThunk({
				searchFilterId: "business_profile_type",
				isAccordionOpen: true,
			}),
		);
	};

	const handleChange = (newValue: string) => {
		if (value !== newValue) {
			let filtersapplied: string[] = Object.keys(search_filter_state).filter((key) => {
				return (
					search_filter_state[key as SearchFilterId].is_applied === true &&
					key !== "transaction_type" &&
					key !== "search_type" &&
					key !== "location"
				);
			});
			filtersapplied.map((filter) => {
				dispatch(updateSearchFilterAppliedThunk({ searchFilterId: filter as SearchFilterId, isApplied: false }));
				dispatch(
					updateSearchFilterSelectedOptionsThunk({
						searchFilterId: filter as SearchFilterId,
						newOptions: [],
					}),
				);
			});
		}

		setValue(newValue);
		/*

		* Setting the search filter selected options based on the transaction type

		*/
		if (newValue === "Buy" || newValue === "Open for business" || newValue === "Rent" || newValue === "Resale") {
			dispatch(
				updateSearchFilterActiveOptionsThunk({
					searchFilterId: "property_category",
					newOptions: property_category_options_active,
				}),
			);
			/*

			* updating service is applied and is visible

			*/
			dispatch(updateSearchFilterAppliedThunk({ searchFilterId: "service_category", isApplied: false }));
			dispatch(updateSearchFilterAppliedThunk({ searchFilterId: "service_type", isApplied: false }));
			dispatch(updateSearchFilterAppliedThunk({ searchFilterId: "service_subtype", isApplied: false }));

			dispatch(updateSearchFilterVisibilityThunk({ searchFilterId: "service_category", isVisible: false }));
			dispatch(updateSearchFilterVisibilityThunk({ searchFilterId: "service_type", isVisible: false }));
			dispatch(updateSearchFilterVisibilityThunk({ searchFilterId: "service_subtype", isVisible: false }));
			/*

			* updating properties is applied and is visible

			*/
			dispatch(updateSearchFilterVisibilityThunk({ searchFilterId: "property_category", isVisible: true }));
			dispatch(updateSearchFilterVisibilityThunk({ searchFilterId: "property_type", isVisible: true }));

			dispatch(updateSearchFilterAppliedThunk({ searchFilterId: "property_category", isApplied: true }));
			dispatch(updateSearchFilterAppliedThunk({ searchFilterId: "property_type", isApplied: true }));

			/*

			 * need to update transaction type as well as search type same with isApplied true.

				! default value is done in index.tsx page for transaction_type

			*/
			dispatch(
				updateSearchFilterSelectedOptionsThunk({
					searchFilterId: "transaction_type",
					newOptions: [
						{
							id: newValue === "Open for business" ? "ofb" : newValue.toLocaleLowerCase(),
							title: newValue,
							value: newValue === "Open for business" ? "ofb" : newValue.toLocaleLowerCase(),
						},
					],
				}),
			);

			dispatch(
				updateSearchFilterAppliedThunk({
					searchFilterId: "transaction_type",
					isApplied: true,
				}),
			);
			dispatch(
				updateSearchFilterSelectedOptionsThunk({
					searchFilterId: "search_type",
					newOptions: [
						{
							id: "properties",
							title: "Properties",
							value: "properties",
						},
					],
				}),
			);
			dispatch(
				updateSearchFilterAppliedThunk({
					searchFilterId: "search_type",
					isApplied: true,
				}),
			);

			if (newValue === "Rent") {
				dispatch(
					updateSearchFilterActiveOptionsThunk({
						searchFilterId: "property_category",
						newOptions: property_category_options_active,
					}),
				);
				dispatch(
					updateSearchFilterSelectedOptionsThunk({
						searchFilterId: "transaction_type",
						newOptions: [
							{
								id: "rent",
								title: "Rent",
								value: "rent",
							},
						],
					}),
				);
				dispatch(
					updateSearchFilterAppliedThunk({
						searchFilterId: "transaction_type",
						isApplied: true,
					}),
				);
				dispatch(updateSearchFilterVisibilityThunk({ searchFilterId: "security_deposit", isVisible: true }));
			}
			if (newValue === "Open for business") {
				dispatch(
					updateSearchFilterSelectedOptionsThunk({
						searchFilterId: "transaction_type",
						newOptions: [
							{
								id: "ofb",
								title: "Open for business",
								value: "ofb",
							},
						],
					}),
				);
				dispatch(
					updateSearchFilterAppliedThunk({
						searchFilterId: "transaction_type",
						isApplied: true,
					}),
				);
				dispatch(
					updateSearchFilterActiveOptionsThunk({
						searchFilterId: "property_category",
						newOptions: ofb_property_category_options_active,
					}),
				);
			}
			if (newValue === "Resale") {
				dispatch(
					updateSearchFilterSelectedOptionsThunk({
						searchFilterId: "transaction_type",
						newOptions: [
							{
								id: "resale",
								title: "Resale",
								value: "resale",
							},
						],
					}),
				);
				dispatch(
					updateSearchFilterAppliedThunk({
						searchFilterId: "transaction_type",
						isApplied: true,
					}),
				);
				dispatch(
					updateSearchFilterActiveOptionsThunk({
						searchFilterId: "property_category",
						newOptions: property_category_options_active,
					}),
				);
			}
		} else if (newValue === "Services") {
			dispatch(
				updateSearchFilterSelectedOptionsThunk({
					searchFilterId: "search_type",
					newOptions: [
						{
							id: newValue.toLocaleLowerCase(),
							title: newValue,
							value: newValue.toLocaleLowerCase(),
						},
					],
				}),
			);
			dispatch(
				updateSearchFilterAppliedThunk({
					searchFilterId: "search_type",
					isApplied: true,
				}),
			);
			dispatch(updateSearchFilterAppliedThunk({ searchFilterId: "service_category", isApplied: true }));

			dispatch(updateSearchFilterVisibilityThunk({ searchFilterId: "service_category", isVisible: true }));
			dispatch(updateSearchFilterVisibilityThunk({ searchFilterId: "service_type", isVisible: true }));
			dispatch(
				updateSearchFilterIsAccordionOpenThunk({
					searchFilterId: "service_type",
					isAccordionOpen: true,
				}),
			);

			dispatch(updateSearchFilterAppliedThunk({ searchFilterId: "property_category", isApplied: false }));
			dispatch(updateSearchFilterAppliedThunk({ searchFilterId: "property_type", isApplied: false }));

			dispatch(updateSearchFilterVisibilityThunk({ searchFilterId: "property_category", isVisible: false }));
			dispatch(updateSearchFilterVisibilityThunk({ searchFilterId: "property_type", isVisible: false }));

			dispatch(updateSearchFilterAppliedThunk({ searchFilterId: "business_profile_type", isApplied: false }));
			dispatch(updateSearchFilterVisibilityThunk({ searchFilterId: "business_profile_type", isVisible: false }));
			dispatch(
				updateSearchFilterSelectedOptionsThunk({
					searchFilterId: "transaction_type",
					newOptions: [],
				}),
			);
			dispatch(
				updateSearchFilterAppliedThunk({
					searchFilterId: "transaction_type",
					isApplied: false,
				}),
			);
		} else if (newValue === "Business Users") {
			handleChangeBusinessUsers(search_filter_state?.business_profile_type?.selected_options[0]?.title);
		}
	};
	/*

	* SubCategory i.e (Budget, Bedroom, Status, Listed by, Facing)

	*/
	const [SubCategoryChecked, setSubCategoryChecked] = React.useState<string>("");

	React.useEffect(() => {
		if (
			search_filter_state.property_category.selected_options[0]?.id ||
			search_filter_state.property_type.selected_options[0]?.id
		) {
			setSubCategoryChecked("");
		}
	}, [search_filter_state.property_category, search_filter_state.property_type]);

	const router = useRouter();
	const [openBottomDrawerFilters, setOpenBottomDrawerFilters] = React.useState(false);
	const [open, setOpen] = React.useState(false);

	React.useEffect(() => {
		if (open && search_filter_state.location.is_applied === true) {
			setOpen(false);
		}
		/* eslint-disable-next-line react-hooks/exhaustive-deps*/
	}, [search_filter_state]);

	const [isLoading, setIsLoading] = React.useState(false);

	const handleClickSearchButton = () => {
		if (search_filter_state.location.is_applied === false) {
			setOpen(true);
			setOpenBottomDrawerFilters(false);
		} else {
			if (isLoading) return;
			setIsLoading(true);

			if (!searchanimationsettings.StartAnimate) {
				router
					.push("/search", "", { shallow: true })
					.then(() => {
						dispatch(
							setSearchAnimationDetailsThunk({
								StartAnimate: true,
								TriggerSearch: true,
								ResetSearch: false,
								PropertySubCategory: searchanimationsettings.PropertySubCategory,
								HomeAccordion: false,
								OpenLocationDrawer: false,
							}),
						);
						setTrigger(false);
					})
					.catch((error) => {
						console.error("Navigation error:", error);
						setIsLoading(false);
					});
			} else {
				setTrigger(false);
				dispatch(
					setSearchAnimationDetailsThunk({
						StartAnimate: searchanimationsettings.StartAnimate,
						TriggerSearch: true,
						ResetSearch: true,
						PropertySubCategory: searchanimationsettings.PropertySubCategory,
						HomeAccordion: false,
						OpenLocationDrawer: false,
					}),
				);
				dispatch(
					setSearchFilterMetricesThunk({
						boosted: [0],
						non_boosted: [0],
						filter_hash: "",
						no_of_boosted_results: 0,
						no_of_non_boosted_results: 0,
						total_no_of_results: 0,
						boosted_from_other_locations: [0],
						no_of_boosted_from_other_locations: 0,
					}),
				);
			}
			setIsLoading(false);
			setOpenBottomDrawerFilters(false);
		}
	};

	const [slide, setSlide] = React.useState<boolean>(search_filter_state.location.is_applied === true ? true : false);
	React.useEffect(() => {
		setSlide(search_filter_state.location.is_applied === true ? true : false);
	}, [search_filter_state.location.is_applied]);

	const ClearAllFilters = () => {
		/*

		* let's get all the applied filters and store it as string array

		*/
		let filtersapplied: string[] = Object.keys(search_filter_state).filter((key) => {
			return (
				search_filter_state[key as SearchFilterId].is_applied === true &&
				key !== "transaction_type" &&
				key !== "search_type" &&
				key !== "location"
			);
		});

		/*

		* let's map the filters applied and update the search filter applied to false and selected options to empty array

		*/
		filtersapplied.map((filter) => {
			dispatch(updateSearchFilterAppliedThunk({ searchFilterId: filter as SearchFilterId, isApplied: false }));
			dispatch(
				updateSearchFilterSelectedOptionsThunk({
					searchFilterId: filter as SearchFilterId,
					newOptions: [],
				}),
			);
		});
	};
	function SlideTransition(props: any) {
		return (
			<Slide
				{...props}
				direction="up"
			/>
		);
	}

	let filterTypes = [
		search_filter_state.transaction_type.selected_options.length > 0
			? search_filter_state.transaction_type.selected_options[0].title
			: null,
		search_filter_state.search_type.selected_options[0].id === "services"
			? "Service type"
			: search_filter_state.search_type.selected_options[0].id === "properties"
				? "Property type"
				: "Business user type",
		search_filter_state.search_type.selected_options[0].title === "Business Profiles" ? null : "Budget",
	].filter(Boolean);

	const [filterType, setFilterType] = React.useState<string>("");

	const filterTypesHandleClick = (data: string) => {
		setFilterType(data);
		setOpenBottomDrawerFilters(true);
	};

	const [bottomDrawerKey, setBottomDrawerKey] = React.useState<number>(0);

	const DrawerRenderFunctions = (value: any) => {
		if (filterTypes.length === 3) {
			switch (value) {
				case 0:
					return (
						<React.Fragment>
							<div
								style={{
									display: "flex",
									flexDirection: "row",
									justifyContent: "space-between",
								}}
							>
								<Typography variant="subtitle2">What are you looking for?</Typography>
								<CloseIcon
									fontSize="small"
									sx={{ color: "rgba(0, 0, 0, 0.54)" }}
									onClick={() => setOpenBottomDrawerFilters(false)}
								/>
							</div>
							<TransactionType
								search_id={"transaction_type"}
								search_filter_state={search_filter_state.transaction_type}
								dispatch={dispatch}
							/>
							<BeegruButton
								flavor="primary"
								variant="contained"
								size="medium"
								sx={{ fontSize: "1rem", alingnItems: "flex-start", height: "100%", width: "100%" }}
								onClick={handleClickSearchButton}
							>
								{router.locale === "ar-AE" ? "بحث" : router.locale === "kn-IN" ? "ಹುಡುಕಿ" : "Search"}
							</BeegruButton>
						</React.Fragment>
					);
				case 1:
					return (
						<React.Fragment>
							{/* Property Filters */}

							{search_filter_state.search_type.selected_options[0]?.title === "Properties" &&
							(search_filter_state.transaction_type.selected_options[0]?.title === "Buy" ||
								search_filter_state.transaction_type.selected_options[0]?.title === "Rent") &&
							filterTypes[1] === "Property type" ? (
								<div
									style={{
										display: "flex",
										flexDirection: "column",
										width: "100%",
										gap: "0.5rem",
									}}
								>
									<div
										style={{
											padding: "0rem 0rem 0.5rem 0rem",
										}}
									>
										<div
											style={{
												display: "flex",
												flexDirection: "row",
												justifyContent: "space-between",
											}}
										>
											<TypographyHeading variant="subtitle2">
												{router.locale === "ar-AE"
													? "اختر فئة العقار"
													: router.locale === "kn-IN"
														? "ನಿಮಗೆ ಸೂಕ್ತವಾದನ್ನು ಆಯ್ಕೆ ಮಾಡಿ"
														: "Select property category"}
											</TypographyHeading>
											<CloseIcon
												fontSize="small"
												sx={{ color: "rgba(0, 0, 0, 0.54)" }}
												onClick={() => setOpenBottomDrawerFilters(false)}
											/>
										</div>
										<PropertyCategoryMobile
											search_id={"property_category"}
											search_filter_state={search_filter_state.property_category}
											dispatch={dispatch}
											TabValue={value}
											searchanimationsettings={searchanimationsettings}
										/>

										{search_filter_state.property_category.is_applied &&
										search_filter_state.property_category.selected_options[0]?.id !== "warehousing" ? (
											<>
												<TypographyHeading
													sx={{
														paddingTop: "1.5rem",
													}}
													variant="subtitle2"
												>
													{router.locale === "ar-AE"
														? "اختر نوع العقار"
														: router.locale === "kn-IN"
															? "ಆಸ್ತಿ ಪ್ರಕಾರವನ್ನು ಆಯ್ಕೆಮಾಡಿ"
															: "Select property type"}
												</TypographyHeading>

												<PropertyTypeMobile
													search_id="property_type"
													property_category_value={
														search_filter_state.property_category?.selected_options[0]?.value as string
													}
													dispatch={dispatch}
													search_filter_state={search_filter_state.property_type}
													searchanimationsettings={searchanimationsettings}
													TabValue={value}
												/>
											</>
										) : null}
									</div>

									<BeegruButton
										flavor="primary"
										variant="contained"
										size="medium"
										sx={{ fontSize: "1rem", alingnItems: "flex-start", height: "100%", width: "100%" }}
										onClick={handleClickSearchButton}
									>
										{router.locale === "ar-AE" ? "بحث" : router.locale === "kn-IN" ? "ಹುಡುಕಿ" : "Search"}
									</BeegruButton>
								</div>
							) : null}

							{/* OFB Filters */}

							{search_filter_state.search_type.selected_options[0]?.title === "Properties" &&
							search_filter_state.transaction_type.selected_options[0]?.title === "Open for business" &&
							filterTypes[1] === "Property type" ? (
								<div
									style={{
										// display: value === "Open for business" ? "flex" : "none",
										flexDirection: "column",
										width: "100%",
										gap: "0.5rem",
									}}
								>
									<div
										style={{
											padding: "0rem 0rem 0.5rem 0rem",
										}}
									>
										<div
											style={{
												display: "flex",
												flexDirection: "row",
												justifyContent: "space-between",
											}}
										>
											<TypographyHeading variant="subtitle2">
												{router.locale === "ar-AE"
													? "اختر فئة أو إف بي"
													: router.locale === "kn-IN"
														? "OFB ವರ್ಗವನ್ನು ಆಯ್ಕೆಮಾಡಿ"
														: "Select OFB category"}
											</TypographyHeading>
											<CloseIcon
												fontSize="small"
												sx={{ color: "rgba(0, 0, 0, 0.54)" }}
												onClick={() => setOpenBottomDrawerFilters(false)}
											/>
										</div>

										<PropertyCategoryMobile
											search_id={"property_category"}
											search_filter_state={search_filter_state.property_category}
											dispatch={dispatch}
											TabValue={value}
											searchanimationsettings={searchanimationsettings}
										/>

										{search_filter_state.property_category?.selected_options[0]?.id === "warehousing" ||
										search_filter_state.property_category?.selected_options[0]?.id === "public_infrastructure" ||
										search_filter_state.property_category?.selected_options[0]?.id === "joint_development" ||
										search_filter_state.property_category?.selected_options[0]?.id === "joint_venture" ||
										search_filter_state.property_category?.selected_options[0]?.id === undefined ? null : (
											<>
												<TypographyHeading
													sx={{
														paddingTop: "1.5rem",
													}}
													variant="subtitle2"
												>
													{router.locale === "ar-AE"
														? "اختر نوع أو إف بي"
														: router.locale === "kn-IN"
															? "OFB ಪ್ರಕಾರವನ್ನು ಆಯ್ಕೆಮಾಡಿ"
															: "Select OFB type"}
												</TypographyHeading>
												<PropertyTypeMobile
													search_id="property_type"
													property_category_value={
														search_filter_state.property_category?.selected_options[0]?.value as string
													}
													dispatch={dispatch}
													search_filter_state={search_filter_state.property_type}
													searchanimationsettings={searchanimationsettings}
													TabValue={value}
												/>
											</>
										)}
									</div>
									<BeegruButton
										flavor="primary"
										variant="contained"
										size="medium"
										sx={{ fontSize: "1rem", alingnItems: "flex-start", height: "100%", width: "100%" }}
										onClick={handleClickSearchButton}
									>
										{router.locale === "ar-AE" ? "بحث" : router.locale === "kn-IN" ? "ಹುಡುಕಿ" : "Search"}
									</BeegruButton>
								</div>
							) : null}

							{/* Business User Filters */}
						</React.Fragment>
					);
				case 2:
					return (
						<React.Fragment>
							<div
								style={{
									marginBottom: "1rem",
								}}
							>
								<div
									style={{
										display: "flex",
										flexDirection: "row",
										justifyContent: "space-between",
									}}
								>
									<TypographyHeading
										variant="subtitle2"
										sx={{
											marginBottom: "0.5rem",
										}}
									>
										{router.locale === "ar-AE"
											? "أدخل السعر"
											: router.locale === "kn-IN"
												? "ಬಜೆಟ್ ನಮೂದಿಸಿ"
												: "Enter Budget"}
									</TypographyHeading>
									<CloseIcon
										fontSize="small"
										sx={{ color: "rgba(0, 0, 0, 0.54)" }}
										onClick={() => setOpenBottomDrawerFilters(false)}
									/>
								</div>

								<BudgetComponent
									search_id="budget"
									dispatch={dispatch}
									search_filter_state={search_filter_state.budget}
								/>
							</div>
							<BeegruButton
								flavor="primary"
								variant="contained"
								size="medium"
								sx={{ fontSize: "1rem", alingnItems: "flex-start", height: "100%", width: "100%" }}
								onClick={handleClickSearchButton}
							>
								{router.locale === "ar-AE" ? "بحث" : router.locale === "kn-IN" ? "ಹುಡುಕಿ" : "Search"}
							</BeegruButton>
						</React.Fragment>
					);
			}
		} else if (filterTypes.length === 2) {
			switch (value) {
				case 0:
					return (
						<>
							{/* Service Filters */}

							{search_filter_state.search_type.selected_options[0]?.title === "Services" &&
							filterTypes[0] === "Service type" ? (
								<div
									style={{
										// display: value === "Services" ? "flex" : "none",
										flexDirection: "column",
										width: "100%",
										gap: "0.5rem",
									}}
								>
									<div
										style={{
											padding: "0rem 0rem 0.5rem 0rem",
										}}
									>
										<div
											style={{
												display: "flex",
												flexDirection: "row",
												justifyContent: "space-between",
											}}
										>
											<TypographyHeading variant="subtitle2">
												{router.locale === "ar-AE"
													? "اختر فئة الخدمة"
													: router.locale === "kn-IN"
														? "ಸೇವಾ ವರ್ಗವನ್ನು ಆಯ್ಕೆಮಾಡಿ"
														: "Select service category"}
											</TypographyHeading>
											<CloseIcon
												fontSize="small"
												sx={{ color: "rgba(0, 0, 0, 0.54)" }}
												onClick={() => setOpenBottomDrawerFilters(false)}
											/>
										</div>
										<ServiceCategoryMobile
											search_id={"service_type"}
											search_filter_state={search_filter_state.service_type}
											dispatch={dispatch}
											search_type={search_filter_state.search_type.selected_options[0]?.id as string}
										/>
									</div>

									{search_filter_state.service_type.is_applied === true && (
										<div
											style={{
												paddingBottom: "0.5rem",
											}}
										>
											<TypographyHeading variant="subtitle2">
												{router.locale === "ar-AE"
													? "اختر نوع الخدمة"
													: router.locale === "kn-IN"
														? "ಸೇವಾ ಪ್ರಕಾರವನ್ನು ಆಯ್ಕೆಮಾಡಿ"
														: "Select service type"}
											</TypographyHeading>

											<ServiceSubTypeMobile
												search_id={"service_subtype"}
												dispatch={dispatch}
												search_filter_state={search_filter_state.service_subtype}
												search_type={search_filter_state.search_type.selected_options[0]?.id as string}
												service_type={search_filter_state.service_type.selected_options[0]?.id as string}
											/>
										</div>
									)}
									<BeegruButton
										flavor="primary"
										variant="contained"
										size="medium"
										sx={{ fontSize: "1rem", alingnItems: "flex-start", height: "100%", width: "100%" }}
										onClick={handleClickSearchButton}
									>
										{router.locale === "ar-AE" ? "بحث" : router.locale === "kn-IN" ? "ಹುಡುಕಿ" : "Search"}
									</BeegruButton>
								</div>
							) : null}
						</>
					);
				case 1:
					return (
						<>
							<React.Fragment>
								<div
									style={{
										marginBottom: "1rem",
									}}
								>
									<div
										style={{
											display: "flex",
											flexDirection: "row",
											justifyContent: "space-between",
										}}
									>
										<TypographyHeading
											variant="subtitle2"
											sx={{
												marginBottom: "0.5rem",
											}}
										>
											{router.locale === "ar-AE"
												? "أدخل السعر"
												: router.locale === "kn-IN"
													? "ಬಜೆಟ್ ನಮೂದಿಸಿ"
													: "Enter Budget"}
										</TypographyHeading>
										<CloseIcon
											fontSize="small"
											sx={{ color: "rgba(0, 0, 0, 0.54)" }}
											onClick={() => setOpenBottomDrawerFilters(false)}
										/>
									</div>

									<BudgetComponent
										search_id="budget"
										dispatch={dispatch}
										search_filter_state={search_filter_state.budget}
									/>
								</div>
								<BeegruButton
									flavor="primary"
									variant="contained"
									size="medium"
									sx={{ fontSize: "1rem", alingnItems: "flex-start", height: "100%", width: "100%" }}
									onClick={handleClickSearchButton}
								>
									{router.locale === "ar-AE" ? "بحث" : router.locale === "kn-IN" ? "ಹುಡುಕಿ" : "Search"}
								</BeegruButton>
							</React.Fragment>
						</>
					);
			}
		} else if (filterTypes.length === 1) {
			switch (value) {
				case 0:
					return (
						<React.Fragment>
							{search_filter_state.search_type.selected_options[0]?.title === "Business Profiles" &&
							filterTypes[0] === "Business user type" ? (
								<div
									style={{
										flexDirection: "column",
										width: "100%",
										gap: "0.5rem",
										padding: "0rem 0rem 0.5rem 0rem",
									}}
								>
									<div
										style={{
											display: "flex",
											flexDirection: "row",
											justifyContent: "space-between",
										}}
									>
										<TypographyHeading variant="subtitle2">
											{router.locale === "ar-AE"
												? "اختر نوع مستخدم الأعمال"
												: router.locale === "kn-IN"
													? "ವ್ಯವಸಾಯ ಬಳಕೆದಾರರ ಪ್ರಕಾರವನ್ನು ಆಯ್ಕೆಮಾಡಿ"
													: "Select business user type"}
										</TypographyHeading>
										<CloseIcon
											fontSize="small"
											sx={{ color: "rgba(0, 0, 0, 0.54)" }}
											onClick={() => setOpenBottomDrawerFilters(false)}
										/>
									</div>

									<FormControl>
										<FormLabel id="demo-row-radio-buttons-group-label"></FormLabel>
										<RadioGroup
											row
											aria-labelledby="demo-row-radio-buttons-group-label"
											name="row-radio-buttons-group"
											sx={{ gap: "0.5rem", fontSize: "1rem", fontWeight: 400 }}
											value={search_filter_state.business_profile_type.selected_options[0]?.value}
										>
											{search_filter_state.business_profile_type.active_options.map((data, id) => (
												<FormControlLabel
													key={id}
													value={data.value}
													control={<Radio />}
													label={
														data.title === "Landowners"
															? "Owners"
															: data.title === "Professionals"
																? "Service Professionals"
																: data.title
													}
													onChange={() => handleChangeBusinessUsers(data.title)}
												/>
											))}
										</RadioGroup>
									</FormControl>
									<BeegruButton
										flavor="primary"
										variant="contained"
										size="medium"
										sx={{ fontSize: "1rem", alingnItems: "flex-start", height: "100%", width: "100%" }}
										onClick={handleClickSearchButton}
									>
										{router.locale === "ar-AE" ? "بحث" : router.locale === "kn-IN" ? "ಹುಡುಕಿ" : "Search"}
									</BeegruButton>
								</div>
							) : null}
						</React.Fragment>
					);
			}
		}
	};
	/*

	* Tab scroll button component

	*/

	const ScrollButtonComponent = (props: any) => {
		const { direction, ...other } = props;
		return direction === "left" ? (
			router.locale === "ar-AE" ? (
				<KeyboardArrowRight
					{...other}
					sx={{
						height: "100%",
					}}
				/>
			) : (
				<KeyboardArrowLeft
					{...other}
					sx={{
						height: "100%",
					}}
				/>
			)
		) : router.locale === "ar-AE" ? (
			<KeyboardArrowLeft
				{...other}
				sx={{
					height: "100%",
				}}
			/>
		) : (
			<KeyboardArrowRight
				{...other}
				sx={{
					height: "100%",
				}}
			/>
		);
	};

	/*

		* Badge calculation for the filter types

	*/

	const [badge, setBadge] = React.useState<number>(0);

	const BadgeCalculation = () => {
		let filtersapplied: string[] = Object.keys(search_filter_state).filter((key) => {
			return search_filter_state[key as SearchFilterId].is_applied === true;
		});
		setBadge(filtersapplied.length);
	};
	React.useEffect(() => {
		BadgeCalculation();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [search_filter_state]);

	/*

	* Budget Filters display

	*/

	let budgetToDisplay = search_filter_state.budget.selected_options[0]?.value as SearchFilterOptionValueRange;

	const [locationTextValue, setLocationTextValue] = React.useState<string>("");

	React.useEffect(() => {
		if (search_filter_state?.location?.selected_options.length !== 0) {
			const selectedOption = search_filter_state?.location?.selected_options[0]?.value;
			if (typeof selectedOption === "object" && "title" in selectedOption) {
				setLocationTextValue(selectedOption.title);
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [search_filter_state]);

	const getBorderStyle: Function = (data: string, id: number, search_filter_state: any, filterTypes: number) => {
		if (filterTypes === 3) {
			return data === search_filter_state.transaction_type.selected_options[0]?.title &&
				id === 0 &&
				search_filter_state.transaction_type.is_applied
				? "1px solid rgba(252, 128, 25, 0.48)"
				: (data === "Property type" || data === "Service type") &&
					  (search_filter_state.property_category.is_applied ||
							search_filter_state.service_type.is_applied ||
							search_filter_state.business_profile_type.is_applied) &&
					  id === 1
					? "1px solid rgba(252, 128, 25, 0.48)"
					: data === "Budget" && search_filter_state.budget.is_applied && id === 2
						? "1px solid rgba(252, 128, 25, 0.48)"
						: "1px solid rgba(0, 0, 0, 0.12)";
		} else if (filterTypes === 2) {
			return (data === "Property type" || data === "Service type") &&
				(search_filter_state.property_category.is_applied ||
					search_filter_state.service_type.is_applied ||
					search_filter_state.business_profile_type.is_applied) &&
				id === 0
				? "1px solid rgba(252, 128, 25, 0.48)"
				: data === "Budget" && search_filter_state.budget.is_applied && id === 1
					? "1px solid rgba(252, 128, 25, 0.48)"
					: "1px solid rgba(0, 0, 0, 0.12)";
		} else if (filterTypes === 1) {
			return data === "Business user type" && search_filter_state.business_profile_type.is_applied && id === 0
				? "1px solid rgba(252, 128, 25, 0.48)"
				: "1px solid rgba(0, 0, 0, 0.12)";
		}
		return null;
	};

	const getbackgroundColor: Function = (data: string, id: number, search_filter_state: any, filterTypes: any) => {
		if (filterTypes === 3) {
			return id === 0 && search_filter_state.transaction_type.is_applied === true
				? "rgba(252, 128, 25, 0.16)"
				: (data === "Property type" || "Service type") &&
					  (search_filter_state.property_category.is_applied === true ||
							search_filter_state.service_type.is_applied === true ||
							search_filter_state.business_profile_type.is_applied === true) &&
					  id === 1
					? "rgba(252, 128, 25, 0.16)"
					: data === "Budget" && search_filter_state.budget.is_applied === true && id === 2
						? "rgba(252, 128, 25, 0.16)"
						: theme.palette.background.paper;
		} else if (filterTypes === 2) {
			return (data === "Property type" || "Service type") &&
				(search_filter_state.property_category.is_applied === true ||
					search_filter_state.service_type.is_applied === true ||
					search_filter_state.business_profile_type.is_applied === true) &&
				id === 0
				? "rgba(252, 128, 25, 0.16)"
				: data === "Budget" && search_filter_state.budget.is_applied === true && id === 1
					? "rgba(252, 128, 25, 0.16)"
					: theme.palette.background.paper;
		} else if (filterTypes === 1) {
			return data === "Business user type" && search_filter_state.business_profile_type.is_applied && id === 0
				? "rgba(252, 128, 25, 0.16)"
				: theme.palette.background.paper;
		}
		return null;
	};

	return (
		<React.Fragment>
			<CustomSwipeableDrawer
				anchor="bottom"
				open={trigger}
				onClose={() => setTrigger(false)}
				onOpen={() => setTrigger(true)}
				swipeAreaWidth={156}
				disableSwipeToOpen={true}
				ModalProps={{
					keepMounted: true,
				}}
				sx={{
					"& .MuiDrawer-paper": {
						direction: router.locale === "ar-AE" ? "rtl" : "ltr",
						background: theme.palette.background.paper,
					},
				}}
			>
				<Snackbar
					open={open}
					anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
					sx={{
						bottom: "70px",
					}}
					TransitionComponent={SlideTransition}
				>
					<SnackbarContent
						sx={{
							borderRadius: "0.85rem",
							color: "#5F2B01",
							background: "#FDF0E5",
							boxShadow: "none",
							display: "flex",
							alignItems: "center",
							fontWeight: 500,
							fontSize: "1rem",
						}}
						message={
							<span style={{ display: "flex", alignItems: "center" }}>
								<WarningAmberIcon style={{ marginRight: "0.75rem" }} />
								{router.locale === "ar-AE"
									? "يرجى إدخال الموقع"
									: router.locale === "kn-IN"
										? "ದಯವಿಟ್ಟು ಸ್ಥಳವನ್ನು ನಮೂದಿಸಿ"
										: "Please enter a location"}
							</span>
						}
					/>
				</Snackbar>

				{/* Transaction Type Tabs */}

				<HeaderContainer>
					<TitleContainer>
						<IconButton
							onClick={(e: any) => {
								DrawerClose();
								e.stopPropagation();
							}}
							sx={{
								padding: "0.25rem",
							}}
						>
							{router.locale === "ar-AE" ? (
								<ArrowForward
									sx={{
										color: theme.palette.text.secondary,
										fontSize: "1.25rem",
										[theme.breakpoints.up("md1190")]: {
											fontSize: "1.5rem",
										},
									}}
								/>
							) : (
								<ArrowBack
									sx={{
										color: theme.palette.text.secondary,
										fontSize: "1.25rem",
										[theme.breakpoints.up("md1190")]: {
											fontSize: "1.5rem",
										},
									}}
								/>
							)}
						</IconButton>

						<Typography variant="body1">
							{router.locale === "ar-AE" ? "الفلاتر" : router.locale === "kn-IN" ? "ಫಿಲ್ಟರ್‌ಗಳು" : "Filters"}
						</Typography>
					</TitleContainer>
					{/* <DrawerContainer
						sx={{
							color: theme.palette.mode === "dark" ? "rgba(255, 255, 255,0.23)" : "rgba(102, 102, 102, 1)",
						}}
					>
						<StyledTabs
							variant="fullWidth"
							// scrollButtons="auto"
							// allowScrollButtonsMobile

							value={search_type}
							// onChange={handleChange}
							// ScrollButtonComponent={ScrollButtonComponent}
						>
							<StyledTab
								value="Properties"
								label={router.locale === "ar-AE" ? "شراء" : router.locale === "kn-IN" ? "ಖರೀದಿಸಿ" : "Properties"}
							/>

							<StyledTab
								value="Serivces"
								label={router.locale === "ar-AE" ? "إيجار" : router.locale === "kn-IN" ? "ಬಾಡಿಗೆ" : "Serivces"}
							/>

							 <StyledTab
								value="Business Users"
								label={
									router.locale === "ar-AE"
										? "مستخدمي الأعمال"
										: router.locale === "kn-IN"
											? "ವ್ಯಾಪಾರ ಬಳಕೆದಾರರು"
											: "Business users"
								}
							/>
						</StyledTabs>
					</DrawerContainer> */}
					{!search_filter_state.business_profile_type.is_applied && (
						<DrawerContainer
							sx={{
								// color: theme.palette.mode === "dark" ? "rgba(255, 255, 255,0.23)" : "rgba(102, 102, 102, 1)",
								border: "1px solid rgba(0, 0, 0, 0.12)",
								borderRadius: "16px",
								height: "4rem",
								padding: "0.5rem",
							}}
						>
							<Button
								variant={value === "Buy" ? "contained" : "text"}
								onClick={() => handleChange("Buy")}
								sx={{
									background: value === "Buy" ? "rgba(252, 128, 25, 0.16)" : "transparent",
									width: "50%",
									boxShadow: "none",
									color: value === "Buy" ? "#B05911" : theme.palette.text.primary,
									"&:hover": {
										boxShadow: "none",
									},
									textTransform: "none",
									fontWeight: "400",
									borderRadius: "0.75rem",
								}}
							>
								Sale
							</Button>
							<Button
								variant={value === "Rent" ? "contained" : "text"}
								onClick={() => handleChange("Rent")}
								sx={{
									background: value === "Rent" ? "rgba(252, 128, 25, 0.16)" : "transparent",
									color: value === "Rent" ? "#B05911" : theme.palette.text.primary,
									width: "50%",
									boxShadow: "none",
									"&:hover": {
										boxShadow: "none",
									},
									textTransform: "none",
									fontWeight: "400",
									borderRadius: "0.75rem",
								}}
							>
								Rent
							</Button>
						</DrawerContainer>
					)}
				</HeaderContainer>

				{/* Location AutoComplete */}

				<div
					style={{
						display: "flex",
						flexDirection: "column",
						width: "100%",
						height: "100%",
						overflowY: "scroll",
						gap: "1.5rem",
						padding: "0rem 0.5rem 0.25rem 0.5rem",
					}}
				>
					<Slide
						direction="up"
						in={slide}
						mountOnEnter
						unmountOnExit
					>
						<DrawerContainer
							sx={{
								display: "flex",
								alignItems: "center",
								justifyContent: "center",
								alignContent: "center",
								gap: "0.5rem",
							}}
						>
							{!searchanimationsettings.StartAnimate ? null : (
								<FilterMobileComponent
									search_filter_state={search_filter_state}
									dispatch={dispatch}
									searchanimationsettings={searchanimationsettings}
									search_filters_metrices={search_filters_metrices}
								/>
							)}
						</DrawerContainer>
					</Slide>
				</div>

				{search_filter_state.location.is_applied ? (
					<ButtonCompoent
						sx={{
							height: "4rem",
							width: "100%",
							backgroundColor: theme.palette.mode === "dark" ? "#424242" : theme.palette.background.paper,
							borderRadius: "16px 16px 0px 0px",
							boxShadow: "0px 1px 3px 0px rgba(0, 0, 0, 0.12)",
						}}
					>
						<BeegruButton
							flavor="primary"
							variant="text"
							size="medium"
							sx={{
								fontSize: "0.875rem",
								fontWeight: 400,
								alignItems: "center",
								height: "100%",
								padding: "0rem 0rem 0rem 0rem",
								width: "35%",
							}}
							onClick={() => {
								ClearAllFilters();
							}}
						>
							{router.locale === "ar-AE"
								? "إعادة تعيين الفلاتر"
								: router.locale === "kn-IN"
									? "ಫಿಲ್ಟರ್‌ಗಳನ್ನು ಮರುಹೊಂದಿಸಿ"
									: "Reset filters"}
						</BeegruButton>
						<BeegruButton
							flavor="primary"
							variant="contained"
							size="medium"
							sx={{
								fontSize: "0.875rem",
								padding: "0.35rem 0.rem 0.35rem 0.5rem",
								width: "65%",
							}}
							onClick={handleClickSearchButton}
						>
							{router.locale === "ar-AE" ? "بحث" : router.locale === "kn-IN" ? "ಹುಡುಕಿ" : "Search"}
						</BeegruButton>
					</ButtonCompoent>
				) : null}
			</CustomSwipeableDrawer>
			{/* </Container> */}

			{/* Quick Filters */}

			<SearchResultsContainer
				sx={{
					direction: router.locale === "ar-AE" ? "rtl" : "ltr",
				}}
			>
				<ContainerNewFilters
					sx={{
						display: searchanimationsettings.StartAnimate ? "flex" : "none",
						flexDirection: "column",
					}}
				>
					<LocationContainer
						onClick={() => {
							dispatch(
								setSearchAnimationDetailsThunk({
									StartAnimate: searchanimationsettings.StartAnimate,
									TriggerSearch: searchanimationsettings.TriggerSearch,
									ResetSearch: false,
									PropertySubCategory: searchanimationsettings.PropertySubCategory,
									HomeAccordion: searchanimationsettings.HomeAccordion,
									OpenLocationDrawer: true,
								}),
							);
						}}
					>
						<div
							style={{
								display: "flex",
								width: "100%",
								height: "100%",
								alignItems: "center",
								gap: "0.5rem",
								padding: "0.25rem 0rem",
							}}
						>
							<div
								style={{
									display: "flex",
									padding: "0.5rem",
								}}
							>
								<LocationOnOutlinedIcon
									sx={{
										color: "#FC8019",
										fontSize: "1.25rem",
									}}
								/>
							</div>
							<div
								style={{
									display: "flex",
									flexDirection: "column",
								}}
							>
								<Typography
									variant="subtitle2"
									sx={{
										fontSize: "14px",
										fontWeight: 500,
										color: theme.palette.text.primary,
									}}
								>
									{locationTextValue}
								</Typography>
								<Typography
									sx={{
										fontSize: "0.625rem",
										color: "#FC8019",
									}}
								>
									Change location
								</Typography>
							</div>
						</div>
						<div
							style={{
								display: "flex",
								padding: "0.5rem",
							}}
						>
							<KeyboardArrowDownIcon
								sx={{
									fontSize: "1.25rem",
									color: "#FC8019",
								}}
							/>
						</div>
					</LocationContainer>
					<Stack
						sx={{
							display: "flex",
							flexDirection: "row",
							gap: "0.5rem",
							overflowX: "scroll",
							width: "100%",
							zIndex: 6,
							height: "100%",
							margin: router.locale === "ar-AE" ? "0rem 0rem 0rem 0.6rem" : "0rem 0rem 0rem 0rem",
							background: "transparent",
							padding: "0.65rem 0.5rem 0.5rem 0.5rem",
							[theme.breakpoints.down("sm")]: {
								background: theme.palette.background.paper,
							},
							"&::-webkit-scrollbar": {
								display: "none",
							},
						}}
					>
						<Badge
							badgeContent={badge}
							color="primary"
							variant="standard"
						>
							<FilterChip
								onClick={(e: any) => {
									DrawerOpen();
									e.stopPropagation();
								}}
								sx={{
									height: "2rem",
									border: badge !== 0 ? "1px solid rgba(252, 128, 25, 0.48)" : "1px solid rgba(0, 0, 0, 0.12)",
									background: badge !== 0 ? "rgba(252, 128, 25, 0.16)" : theme.palette.background.paper,
								}}
							>
								<TuneIcon
									sx={{
										fontSize: "1rem",
									}}
								/>
							</FilterChip>
						</Badge>
						{/* Location */}

						{/* <FilterChip
							onClick={(e: any) => {
								dispatch(
									setSearchAnimationDetailsThunk({
										StartAnimate: searchanimationsettings.StartAnimate,
										TriggerSearch: searchanimationsettings.TriggerSearch,
										ResetSearch: false,
										PropertySubCategory: searchanimationsettings.PropertySubCategory,
										HomeAccordion: searchanimationsettings.HomeAccordion,
										OpenLocationDrawer: true,
									}),
								);
								e.stopPropagation();
							}}
							sx={{
								height: "2rem",
								border:
									search_filter_state.location.is_applied === true
										? "1px solid rgba(252, 128, 25, 0.48)"
										: "1px solid rgba(0, 0, 0, 0.12)",
								background:
									search_filter_state.location.is_applied === true
										? "rgba(252, 128, 25, 0.16)"
										: theme.palette.background.paper,
								[theme.breakpoints.down("sm")]: {
									padding: router.locale === "ar-AE" ? "0rem 0.25rem 0rem 0.5rem" : "0rem 0.5rem 0rem 0.25rem",
								},
							}}
						>
							<FmdGoodOutlined
								sx={{
									fontSize: "1rem",
									color: theme.palette.primary.main,
								}}
							/>

							<Typography
								variant="body2"
								sx={{
									fontSize: "0.825rem",
								}}
							>
								{search_filter_state.location.selected_options.length > 0
									? typeof search_filter_state.location.selected_options[0].value === "object" &&
										"title" in search_filter_state.location.selected_options[0].value
										? search_filter_state.location.selected_options[0].value.title
										: ""
									: router.locale === "ar-AE"
										? "الموقع"
										: router.locale === "kn-IN"
											? "ಸ್ಥಳ"
											: "Location"}
							</Typography>
						</FilterChip> */}

						{/* Type and budget filters */}

						{filterTypes.map((data: any, id: any) =>
							data === null ? null : (
								<PropertyChips
									key={id}
									sx={{
										height: "2rem",
										border: getBorderStyle(data, id, search_filter_state, filterTypes.length),
										background: getbackgroundColor(data, id, search_filter_state, filterTypes.length),
									}}
									label={
										data === "Property type"
											? search_filter_state.property_category.selected_options.length > 0 ||
												search_filter_state.property_type.selected_options.length > 0
												? `${search_filter_state.property_category.selected_options[0]?.title}${
														search_filter_state.property_type.selected_options.length > 0
															? ` - ${search_filter_state.property_type.selected_options[0]?.title}`
															: ""
													}`
												: router.locale === "ar-AE"
													? "نوع العقار"
													: router.locale === "kn-IN"
														? "ಆಸ್ತಿ ಪ್ರಕಾರ"
														: data
											: data === "Service type"
												? search_filter_state.service_type.selected_options.length > 0 ||
													search_filter_state.service_subtype.selected_options.length > 0
													? `${search_filter_state.service_type.selected_options[0]?.title}${
															search_filter_state.service_subtype.selected_options.length > 0
																? ` - ${search_filter_state.service_subtype.selected_options[0]?.title}`
																: ""
														}`
													: router.locale === "ar-AE"
														? "نوع الخدمة"
														: router.locale === "kn-IN"
															? "ಸೇವೆಯ ರೀತಿ"
															: data
												: data === "Business user type"
													? search_filter_state.business_profile_type.selected_options.length > 0 &&
														search_filter_state.business_profile_type.selected_options[0]?.title === "Landowners"
														? "Owners"
														: search_filter_state.business_profile_type.selected_options.length > 0 &&
															  search_filter_state.business_profile_type.selected_options[0]?.title === "Professionals"
															? "Service Professionals"
															: search_filter_state.business_profile_type.selected_options.length > 0
																? `${search_filter_state.business_profile_type.selected_options[0]?.title}`
																: router.locale === "ar-AE"
																	? "نوع مستخدم الأعمال"
																	: router.locale === "kn-IN"
																		? "ವ್ಯವಸಾಯ ಬಳಕೆದಾರರ ರೀತಿ"
																		: data
													: data === "Budget"
														? search_filter_state.budget.is_applied
															? `₹${budgetToDisplay.min}  ${budgetToDisplay.max === 0 ? "- Max limit" : `- ₹${budgetToDisplay.max}`}`
															: router.locale === "ar-AE"
																? "السعر"
																: router.locale === "kn-IN"
																	? "ಬಜೆಟ್"
																	: data
														: data === "Buy"
															? router.locale === "ar-AE"
																? "للبيع"
																: router.locale === "kn-IN"
																	? "ಮಾರಾಟಕ್ಕೆ"
																	: "For Sale"
															: data === "Rent"
																? router.locale === "ar-AE"
																	? "للإيجار"
																	: router.locale === "kn-IN"
																		? "ಬಾಡಿಗೆಗೆ"
																		: "For Rent"
																: data === "Resale"
																	? router.locale === "ar-AE"
																		? "إعادة البيع"
																		: router.locale === "kn-IN"
																			? "ಮರುಮಾರಾಟ"
																			: "For Resale"
																	: data
									}
									variant="outlined"
									onDelete={() => {
										setBottomDrawerKey(id);
										filterTypesHandleClick(data);
									}}
									deleteIcon={
										filterType === data && openBottomDrawerFilters ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />
									}
									onClick={() => {
										setBottomDrawerKey(id);
										filterTypesHandleClick(data);
									}}
								/>
							),
						)}
					</Stack>

					<CustomSwipeableDrawer
						anchor="bottom"
						open={openBottomDrawerFilters}
						onClose={() => setOpenBottomDrawerFilters(false)}
						onOpen={() => setOpenBottomDrawerFilters(true)}
						swipeAreaWidth={156}
						disableSwipeToOpen={true}
						ModalProps={{
							keepMounted: true,
						}}
						sx={{
							"& .MuiDrawer-paper": {
								background: theme.palette.background.paper,
								padding: "1rem",
								height: "auto",
								direction: router.locale === "ar-AE" ? "rtl" : "ltr",
							},
						}}
					>
						{DrawerRenderFunctions(bottomDrawerKey)}
					</CustomSwipeableDrawer>
					{/* </div> */}
				</ContainerNewFilters>
				{/* </Slide> */}

				<Typography
					variant="body2"
					sx={{
						fontSize: "0.875rem",
						fontWeight: 500,
						color: theme.palette.text.secondary,
						lineHeight: "1rem",
						margin: "0.5rem 0rem 0.25rem 0rem",
						[theme.breakpoints.down("sm")]: {
							paddingTop: "6.5rem",
						},
					}}
				>
					{router.locale === "ar-AE"
						? // Arabic
							`${
								search_filter_state.search_type.selected_options[0]?.title === "Properties"
									? "عقارات"
									: search_filter_state.search_type.selected_options[0]?.title === "Services"
										? "خدمات"
										: search_filter_state.search_type.selected_options[0]?.title === "Business Profiles"
											? "ملفات الأعمال"
											: search_filter_state.search_type.selected_options[0]?.title === "Users"
												? "المستخدمين"
												: search_filter_state.search_type.selected_options[0]?.title.toLowerCase()
							} ${
								search_filter_state.search_type.selected_options[0]?.title === "Properties"
									? search_filter_state.transaction_type.selected_options[0]?.title === "Buy"
										? "للبيع"
										: search_filter_state.transaction_type.selected_options[0]?.title === "Rent"
											? "للإيجار"
											: ""
									: ""
							}`
						: router.locale === "kn-IN"
							? // Kannada
								`${
									search_filter_state.search_type.selected_options[0]?.title === "Properties"
										? "ಆಸ್ತಿಗಳು"
										: search_filter_state.search_type.selected_options[0]?.title === "Services"
											? "ಸೇವೆಗಳು"
											: search_filter_state.search_type.selected_options[0]?.title === "Business Profiles"
												? "ವ್ಯಾಪಾರ ಪ್ರೊಫೈಲ್‌ಗಳು"
												: search_filter_state.search_type.selected_options[0]?.title === "Users"
													? "ಬಳಕೆದಾರರು"
													: search_filter_state.search_type.selected_options[0]?.title.toLowerCase()
								} ${
									search_filter_state.search_type.selected_options[0]?.title === "Properties"
										? search_filter_state.transaction_type.selected_options[0]?.title === "Buy"
											? "ಮಾರಾಟಕ್ಕೆ"
											: search_filter_state.transaction_type.selected_options[0]?.title === "Rent"
												? "ಭಾಡೆಗೆ"
												: ""
										: ""
								}`
							: // English

								`Showing ${search_filter_state.search_type.selected_options[0]?.title.toLowerCase()}
					${
						search_filter_state.search_type.selected_options[0]?.title === "Properties"
							? `to
					${search_filter_state.transaction_type.selected_options[0]?.title.toLowerCase()}`
							: ""
					}`}
				</Typography>
			</SearchResultsContainer>
		</React.Fragment>
	);
};

export default Search;
