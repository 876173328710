/* Common imports */

import React from "react";

/* mui imports */

import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
import KeyboardArrowDownOutlinedIcon from "@mui/icons-material/KeyboardArrowDownOutlined";
import RemoveCircleOutlineOutlinedIcon from "@mui/icons-material/RemoveCircleOutlineOutlined";
import { Accordion, AccordionDetails, AccordionSummary, Button, Divider, Typography, useTheme } from "@mui/material";
import { styled } from "@mui/system";
import { useRouter } from "next/router";
import PreparePriceString from "../../lib/PreparePriceString";
import BeegruButton from "../common-components/buttons/BeegruButton";

/* Redux Imports */

import StateInterface from "../../redux-magic/state-interface";
import {
	SearchFilter,
	SearchFilterId,
	SearchFilterOption,
	SearchFilterOptionValueLocation,
	SearchFilterOptionValueRange,
	SearchFiltersState,
} from "../../redux-magic/sub-interfaces/search-types";
import {
	setSearchAnimationDetailsThunk,
	setSearchFilterMetricesThunk,
	updateSearchFilterActiveOptionsThunk,
	updateSearchFilterAppliedThunk,
	updateSearchFilterSelectedOptionsThunk,
	updateSearchFilterVisibilityThunk,
} from "../../redux-magic/thunks";

/* stylings */

const CustomAccordion = styled(Accordion)(({ theme }) => ({
	borderRadius: "16px",
	boxShadow: "none",
	"&:: before": {
		display: "none",
	},
}));

const CustomAccordionSummary = styled(AccordionSummary)(({ theme }) => ({
	padding: "0.5rem 0.75rem 0.5rem 0.75rem",
	margin: "0rem",
	fontSize: "1rem",
	[theme.breakpoints.up("sm")]: {
		fontSize: "1rem",
	},
	[theme.breakpoints.up("md1190")]: {
		fontSize: "1.125rem",
		padding: "1rem 1rem 1rem 1rem",
	},
	"& .MuiAccordionSummary-content": {
		display: "flex",
		flexDirection: "row",
		justifyContent: "flex-start",
		alignItems: "center",
		margin: "0rem",
		[theme.breakpoints.up("md1190")]: {
			margin: "0.75rem 0rem",
		},
	},
}));

const CustomFilterIcon = styled(FilterAltOutlinedIcon)(({ theme }) => ({
	margin: "0rem 0.25rem 0rem 0rem",
	color: theme.palette.primary.main,
	fontSize: "1.25rem",
	[theme.breakpoints.up("md1190")]: {
		fontSize: "1.5rem",
	},
}));

const Heading = styled(Typography)(({ theme }) => ({
	fontSize: "0.875rem",
	[theme.breakpoints.up("sm")]: {
		fontSize: "0.875rem",
	},
	[theme.breakpoints.up("md1190")]: {
		fontSize: "1rem",
	},
}));

const CustomArrowIcon = styled(KeyboardArrowDownOutlinedIcon)(({ theme }) => ({
	width: "1.5rem",
	height: "1.5rem",
	borderRadius: "4px",
	color: "#6e3037",
	backgroundColor: "#ffe9d9",
}));

const CustomAccordionDetails = styled(AccordionDetails)(({ theme }) => ({
	display: "flex",
	flexDirection: "column",
	justifyContent: "flex-start",
	alignItems: "flex-start",
	gap: "1rem",
	padding: "0rem 0.75rem 0.5rem 0.75rem",
	[theme.breakpoints.up("md1190")]: {
		padding: "0rem 1rem 1rem 1rem",
	},
}));

const CustomButtonDiv = styled("div")(({ theme }) => ({
	display: "flex",
	width: "100%",
	flexDirection: "column",
	justifyContent: "flex-end",
	alignItems: "flex-end",
	gap: "0.5rem",
}));

const CheckBoxButton = styled(Button)(({ theme }) => ({
	display: "flex",
	flexDirection: "row",
	justifyContent: "space-between",
	alignItems: "center",
	borderRadius: "0.5rem",
	padding: "var(--Spacing-spacing-xs, 4px)",
	boxShadow: "none",
	gap: "var(--Spacing-spacing-xs, 2px)",
	cursor: "pointer",
	background: "#ffe9d9",
}));

const Text = styled(Typography)(({ theme }) => ({
	fontSize: "0.825rem",
	[theme.breakpoints.up("sm")]: {
		fontSize: "0.875rem",
	},
	[theme.breakpoints.up("md1190")]: {
		fontSize: "1rem",
	},
	fontWeight: "400",
	lineHeight: "1rem",
	padding: "3px 6px",
	textTransform: "capitalize",
	color: "#6e3037",
}));

const RemoveIcon = styled(RemoveCircleOutlineOutlinedIcon)(({ theme }) => ({
	color: "#6e3037",
	fontSize: "1.25rem",
	[theme.breakpoints.up("md1190")]: {
		fontSize: "1.5rem",
	},
}));

const CustomDiv = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "row",
	justifyContent: "flex-start",
	alignItems: "center",
	flexWrap: "wrap",
	margin: "0rem",
	gap: "0.5rem",
	[theme.breakpoints.up("md1190")]: {
		gap: "1rem",
	},
}));

const AppliedFilters = ({
	search_filter_state,
	dispatch,
	searchanimationsettings,
	search_type,
}: {
	search_filter_state: StateInterface["search_filters_state"];
	dispatch: Function;
	searchanimationsettings: StateInterface["search_animation_settings"];
	search_type: string;
}) => {
	const theme = useTheme();
	const router = useRouter();

	const [slide, setSlide] = React.useState<boolean>(false);

	const handleRemoveFilter = (filter_id: SearchFilterId, item: SearchFilter) => {
		// Create a new array of active options with 'is_selected' set to false
		const activeOptions = item.active_options.map((option: SearchFilterOption) => ({
			...option, // Spread the properties of the current option
			is_selected: false, // Set 'is_selected' to false
		}));

		// Dispatch an action to update the selected options for the given filter_id to an empty array
		dispatch(
			updateSearchFilterSelectedOptionsThunk({
				searchFilterId: filter_id,
				newOptions: [], // Clear the selected options
			}),
		);

		// Dispatch an action to update the active options for the given filter_id with the new active options
		dispatch(
			updateSearchFilterActiveOptionsThunk({
				searchFilterId: filter_id,
				newOptions: activeOptions, // Use the modified active options
			}),
		);

		// Dispatch an action to mark the filter as not applied
		dispatch(
			updateSearchFilterAppliedThunk({
				searchFilterId: filter_id,
				isApplied: false, // Set 'isApplied' to false
			}),
		);

		// Check if the item has any affects to process
		if (item.affects.length > 0) {
			// Iterate over each affected filter
			item.affects.map((affect: SearchFilterId) => {
				// Dispatch an action to clear the selected options for the affected filter
				dispatch(
					updateSearchFilterSelectedOptionsThunk({
						searchFilterId: affect,
						newOptions: [], // Clear the selected options
					}),
				);

				// Dispatch an action to update the visibility of the affected filter based on its selected options
				dispatch(
					updateSearchFilterVisibilityThunk({
						searchFilterId: affect,
						isVisible: search_filter_state[affect].selected_options.length > 0 ? true : false, // Set visibility based on whether there are selected options
					}),
				);

				// Dispatch an action to mark the affected filter as not applied
				dispatch(
					updateSearchFilterAppliedThunk({
						searchFilterId: affect,
						isApplied: false, // Set 'isApplied' to false
					}),
				);

				// Dispatch an action to update the active options for the affected filter
				dispatch(
					updateSearchFilterActiveOptionsThunk({
						searchFilterId: affect,
						newOptions: search_filter_state[affect].active_options.map((option: SearchFilterOption) => ({
							...option, // Spread the properties of the current option
							is_selected: false, // Set 'is_selected' to false for each active option
						})),
					}),
				);
			});
		}
	};

	React.useEffect(() => {
		// Define an object to hold action functions for different search types
		const actions: { [key: string]: () => void } = {
			// Actions for 'properties' search type
			properties: () => {
				// Update selected options for the 'service_type' filter
				// dispatch(updateSearchFilterSelectedOptionsThunk({ searchFilterId: "service_type", newOptions: [] }));

				// Mark 'service_type' filter as not applied
				dispatch(updateSearchFilterAppliedThunk({ searchFilterId: "service_type", isApplied: false }));

				// Update selected options for the 'service_subtype' filter
				// dispatch(updateSearchFilterSelectedOptionsThunk({ searchFilterId: "service_subtype", newOptions: [] }));

				// Mark 'service_subtype' filter as not applied
				dispatch(updateSearchFilterAppliedThunk({ searchFilterId: "service_subtype", isApplied: false }));

				// Set 'service_subtype' filter visibility to false
				// dispatch(updateSearchFilterVisibilityThunk({ searchFilterId: "service_subtype", isVisible: false }));
			},

			// Actions for 'services' search type
			services: () => {
				// Update selected options for the 'property_category' filter
				// dispatch(updateSearchFilterSelectedOptionsThunk({ searchFilterId: "property_category", newOptions: [] }));

				// Mark 'property_category' filter as not applied
				dispatch(updateSearchFilterAppliedThunk({ searchFilterId: "property_category", isApplied: false }));

				// Update selected options for the 'property_type' filter
				// dispatch(updateSearchFilterSelectedOptionsThunk({ searchFilterId: "property_type", newOptions: [] }));

				// Mark 'property_type' filter as not applied
				dispatch(updateSearchFilterAppliedThunk({ searchFilterId: "property_type", isApplied: false }));

				// Set 'property_type' filter visibility to false
				// dispatch(updateSearchFilterVisibilityThunk({ searchFilterId: "property_type", isVisible: false }));
			},
		};

		// Check if the actions object has a function for the current search type
		if (actions[search_type]) {
			// Execute the action corresponding to the current search type
			actions[search_type]();
		}
	}, [search_type, dispatch]);

	let business = search_filter_state.business_profile_type.is_applied ? "business_profile_type" : "search_type";

	return (
		<CustomAccordion
			disableGutters={true}
			square={true}
			// onChange={() => {
			// 	setSlide(!slide);
			// }}
			defaultExpanded={true}
		>
			<CustomAccordionSummary
				expandIcon={<CustomArrowIcon />}
				aria-controls="panel1a-content"
				id="panel1a-header"
			>
				<CustomFilterIcon
					sx={{
						margin: router.locale === "ar-AE" ? "0rem 0rem 0rem 0.25rem" : "0rem 0.25rem 0rem 0rem",
					}}
				/>
				<Heading variant="body2">
					{router.locale === "ar-AE"
						? "الفلاتر المُطبقة"
						: router.locale === "kn-IN"
							? "ಅನ್ವಯಿಸಿದ ಫಿಲ್ಟರ್‌ಗಳು"
							: "Applied Filters"}
				</Heading>
			</CustomAccordionSummary>
			<Divider
				sx={{
					borderBottom: "1px solid var(--Other-New-Divider, rgba(255, 255, 255, 0.12))",
				}}
			/>

			<CustomAccordionDetails>
				<CustomDiv>
					{/* Check if only "transaction_type", "location", and "search_type" are applied or no filters are applied */}
					{Object.keys(search_filter_state).every(
						(key) =>
							["transaction_type", "location", "search_type", "service_category"].includes(key) ||
							!search_filter_state[key as keyof SearchFiltersState].is_applied,
					) ? (
						<Text
							sx={{
								marginLeft: "0.75rem",
							}}
						>
							No filters applied
						</Text>
					) : (
						Object.keys(search_filter_state).map((key: SearchFilterId) => {
							if (business === "business_profile_type" && key === "search_type") return null;

							const item: SearchFilter = search_filter_state[key];
							if (item.is_applied) {
								let disp = "";
								switch (item.option_type) {
									case "location":
										disp = (item.selected_options[0]?.value as SearchFilterOptionValueLocation)?.title;
										break;
									case "range":
										let text = "";
										switch (item.id) {
											case "bedrooms":
												const minBHK = (
													item.selected_options[0]?.value as SearchFilterOptionValueRange
												)?.min.toString();
												const maxBHK = (
													item.selected_options[0]?.value as SearchFilterOptionValueRange
												)?.max.toString();
												text = minBHK + " - " + maxBHK + " BHK";
												break;
											case "bathrooms":
												const minBath = (
													item.selected_options[0]?.value as SearchFilterOptionValueRange
												)?.min.toString();
												const maxBath = (
													item.selected_options[0]?.value as SearchFilterOptionValueRange
												)?.max.toString();
												text = minBath + " - " + maxBath + " Bath";
												break;
											case "area":
												const minArea = (item.selected_options[0]?.value as SearchFilterOptionValueRange)?.min;
												const maxArea = (item.selected_options[0]?.value as SearchFilterOptionValueRange)?.max;
												text = minArea + " - " + maxArea + " sqft";
												break;
											case "balconies":
												const minBalcony = (item.selected_options[0]?.value as SearchFilterOptionValueRange)?.min;
												const maxBalcony = (item.selected_options[0]?.value as SearchFilterOptionValueRange)?.max;
												text = minBalcony + " - " + maxBalcony + " Balcony";
												break;
											case "possession_date":
												text =
													new Date(
														(item.selected_options[0]?.value as SearchFilterOptionValueRange)?.min,
													).toLocaleDateString("en-US", { year: "numeric", month: "long", day: "numeric" }) +
													" - " +
													new Date(
														(item.selected_options[0]?.value as SearchFilterOptionValueRange)?.max,
													).toLocaleDateString("en-US", { year: "numeric", month: "long", day: "numeric" });
												break;
											case "available_from":
												text =
													new Date(
														(item.selected_options[0]?.value as SearchFilterOptionValueRange)?.min,
													).toLocaleDateString("en-US", { year: "numeric", month: "long", day: "numeric" }) +
													" - " +
													new Date(
														(item.selected_options[0]?.value as SearchFilterOptionValueRange)?.max,
													).toLocaleDateString("en-US", { year: "numeric", month: "long", day: "numeric" });
												break;
											case "floors":
												text =
													(item.selected_options[0]?.value as SearchFilterOptionValueRange)?.min.toString() +
													" Floor" +
													" - " +
													(item.selected_options[0]?.value as SearchFilterOptionValueRange)?.max.toString() +
													" Floor";
												break;
											case "budget":
												const minNumber = (item.selected_options[0]?.value as SearchFilterOptionValueRange)?.min;
												const maxNumber = (item.selected_options[0]?.value as SearchFilterOptionValueRange)?.max;
												text = "₹ " + PreparePriceString(minNumber) + " - " + PreparePriceString(maxNumber);
												break;
											default:
												break;
										}
										disp = text;
										break;
									case "text":
										if (item.id === "plot_dimensions") {
											disp = item.selected_options[0]?.title.toString();
										} else if (item.selected_options.length > 1) {
											disp = item.selected_options[0].title + " (+" + (item.selected_options.length - 1) + ")";
										} else if (item.id === business && item.is_applied) {
											disp = item.selected_options[0]?.title;
										} else {
											disp = item.selected_options[0]?.title.toString();
										}
										break;
									default:
										break;
								}

								return (
									item.selected_options.length > 0 &&
									key !== "transaction_type" &&
									key !== "location" &&
									key !== "search_type" &&
									key !== "service_category" && (
										<CheckBoxButton
											key={key}
											sx={
												key === business
													? {
															background: "rgba(0, 0, 0, 0.12)",
															cursor: "not-allowed",
														}
													: null
											}
											onClick={() => {
												handleRemoveFilter(key, item);
											}}
										>
											<Text>
												{disp === "Professionals"
													? "Service Professionals"
													: disp === "Professional"
														? "Service Professional"
														: disp === "Landowners"
															? "Owner"
															: disp === "Landowner"
																? "Owner"
																: disp}
											</Text>
											<RemoveIcon />
										</CheckBoxButton>
									)
								);
							} else {
								return null;
							}
						})
					)}
				</CustomDiv>
				<CustomButtonDiv>
					<BeegruButton
						variant="contained"
						color="primary"
						size="small"
						sx={{
							width: "6.875rem",
							[theme.breakpoints.down("lg")]: { display: "none" },
						}}
						onClick={() => {
							dispatch(
								setSearchFilterMetricesThunk({
									boosted: [0],
									non_boosted: [0],
									filter_hash: "",
									no_of_boosted_results: 0,
									no_of_non_boosted_results: 0,
									total_no_of_results: 0,
									boosted_from_other_locations: [0],
									no_of_boosted_from_other_locations: 0,
								}),
							);
							dispatch(
								setSearchAnimationDetailsThunk({
									StartAnimate: searchanimationsettings.StartAnimate,
									TriggerSearch: true,
									ResetSearch: true,
									PropertySubCategory: searchanimationsettings.PropertySubCategory,
									HomeAccordion: searchanimationsettings.HomeAccordion,
									OpenLocationDrawer: searchanimationsettings.OpenLocationDrawer,
								}),
							);
						}}
					>
						{router.locale === "ar-AE" ? "" : router.locale === "kn-IN" ? "ಫಿಲ್ಟರ್‌ಗಳನ್ನು ಅನ್ವಯಿಸಿ" : "Apply Filters"}
					</BeegruButton>
				</CustomButtonDiv>
			</CustomAccordionDetails>
		</CustomAccordion>
	);
};
export default AppliedFilters;
