/* React Imports */

import { NextRouter, withRouter } from "next/router";
import React from "react";
import { Helmet } from "react-helmet";

/* Redux Imports */

import StateInterface from "../../redux-magic/state-interface";
import { notifications_page } from "../../redux-magic/sub-state-data/sub-state-data";

/* Component Imports */

import Container from "./Container";
import Header from "./Header";
import LayoutFooter from "./LayoutFooter";
import LayoutHeader from "./LayoutHeader";

import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";
import { Theme } from "@mui/material/styles";
import BeegruButton from "../common-components/buttons/BeegruButton";
/* Library Imports */

import { useInView } from "react-intersection-observer";

const Layout = ({
	keywords,
	title,
	description,
	canonicalUrl,
	ogImageParams,
	pinnedFooter,
	noLayoutHeader,
	noLayoutFooter,
	showChatFab,
	chatStates,
	noContain,
	children,
	router,
	session,
	redux_session_updated,
	showInactivityDialog,
	theme,
	onChangeLayoutFooterVisibility,
	dispatch,
	profile_context,
	new_business_profile_url,
	new_listing_url,
	propertyData,
	serviceData,
	notifications,
	new_notification,
	bottom_nav_type,
	MainOgDescription,
	newPropertyData,
	studio,
	newServiceData,
	search_filter_state,
	searchanimationsettings,
	searchLocation,
}: {
	keywords?: string;
	title?: string;
	description?: string;
	canonicalUrl?: string;
	ogImageParams?: {
		ogImageSquareUrl: string;
		ogImageRectangleUrl: string;
		ogImageSquareType: string;
		ogImageRectangleType: string;
		ogImageSquareWidth: number;
		ogImageSquareHeight: number;
		ogImageRectangleWidth: number;
		ogImageRectangleHeight: number;
	};
	pinnedFooter?: boolean;
	noLayoutHeader?: boolean;
	noLayoutFooter?: boolean;
	showChatFab?: boolean;
	chatStates?: StateInterface["chat_states"];
	noContain?: Array<number>;
	children?: React.ReactNode;
	router: NextRouter;
	session?: any;
	redux_session_updated?: boolean;
	showInactivityDialog?: boolean;
	theme?: Theme;
	onChangeLayoutFooterVisibility?: Function;
	dispatch: Function;
	profile_context: StateInterface["profile_context"];
	new_business_profile_url?: StateInterface["new_business_profile_url"];
	new_listing_url: StateInterface["new_listing_url"];
	propertyData?: StateInterface["property"];
	serviceData?: StateInterface["single_service_page"];
	notifications?: StateInterface["notifications_page"];
	new_notification?: StateInterface["notification"] | null;
	bottom_nav_type?: string;
	MainOgDescription?: string;
	newPropertyData?: StateInterface["new_property_data"];
	studio?: StateInterface["is_studio"];
	newServiceData?: StateInterface["new_service_data"];
	search_filter_state?: StateInterface["search_filters_state"];
	searchanimationsettings?: StateInterface["search_animation_settings"];
	searchLocation?: any;
}) => {
	const { ref: layoutFooterRef, inView: isLayoutFooterVisible } = useInView({
		threshold: 0,
	});

	React.useEffect(() => {
		if (typeof onChangeLayoutFooterVisibility === "function") {
			onChangeLayoutFooterVisibility(isLayoutFooterVisible);
		}
	}, [isLayoutFooterVisible, onChangeLayoutFooterVisibility]);

	return (
		<React.Fragment>
			<div id="rootDiv">
				<Header
					keywords={
						keywords
							? keywords
							: "marketplace,boost,property,real,estate,beegru,advisory,crowd,source,geography,geodata,geospatial,aggregator,bhk,apartment,villa,plot,land,buy,jd,jv,warehouse,officespace,commercial,residential,agricultural,farmland,farmplot,service,agent,developer,builder,landowner,professional,consultant,architect,engineer,interior,designer,contractor,contracting,construction,construction,material,material,manufacturer,manufacturer,wholesaler,wholesaler,retailer,retailer,service,provider,service,provider,property,management,property,management,property,valuation,property,valuation,property,legal,property,legal,property,finance,property,finance,property,insurance,property,insurance,property,advisory,property,advisory,property,consultancy,property,consultancy,property,marketing,property,marketing,property,advertising,property,advertising,property,technology,property,technology,property,software,property,software,property,crm,property,crm,property,erp,property,erp,property,website,property,website,property,portal,property,portal,property,listing,property,listing,property,search,property,search,prop,startup"
					}
					description={description ? description : "Beegru gets properties"}
					title={title ? title : "Beegru"}
					canonicalUrl={canonicalUrl ? canonicalUrl : process.env.PRODUCTION_URL + router.asPath.substring(1)}
					ogImageParams={
						ogImageParams
							? ogImageParams
							: {
									ogImageSquareUrl: process.env.CDN_URL + "images/icons/logo/og_512.jpg",
									ogImageRectangleUrl: process.env.CDN_URL + "images/icons/logo/og_1200_630.jpg",
									ogImageSquareType: "image/jpeg",
									ogImageRectangleType: "image/jpeg",
									ogImageSquareWidth: 512,
									ogImageSquareHeight: 512,
									ogImageRectangleWidth: 1200,
									ogImageRectangleHeight: 630,
								}
					}
					MainOgDescription={MainOgDescription}
				/>

				{/* Helmet */}

				<Helmet>
					<html />
				</Helmet>

				{/* Layout Header / Navbar */}

				{noLayoutHeader ? undefined : (
					<LayoutHeader
						session={session}
						redux_session_updated={redux_session_updated !== undefined ? redux_session_updated : false}
						dispatch={dispatch}
						profile_context={profile_context}
						new_business_profile_url={new_business_profile_url ? new_business_profile_url : ""}
						new_listing_url={new_listing_url ? new_listing_url : ""}
						notification_data={notifications ? notifications : notifications_page}
						new_notification={new_notification ? new_notification : null}
						newPropertyData={newPropertyData as StateInterface["new_property_data"]}
						studio={studio as StateInterface["is_studio"]}
						newServiceData={newServiceData as StateInterface["new_service_data"]}
					/>
				)}

				{children && noContain ? (
					React.Children.map(children, (child, key) =>
						noContain?.includes(key) ? (
							<React.Fragment>
								<Container
									key={key}
									noContain={noContain?.length > 0}
								>
									{child}
								</Container>
							</React.Fragment>
						) : (
							<React.Fragment>
								<Container
									key={key}
									noContain={false}
								>
									{child}
								</Container>
							</React.Fragment>
						),
					)
				) : (
					<React.Fragment>
						<Container noContain={false}>{children}</Container>
					</React.Fragment>
				)}

				{/* Inactivity Dialog */}

				<Dialog
					open={Boolean(showInactivityDialog)}
					aria-labelledby="Are you still there?"
					aria-describedby="You have been inactive for a while. Please click the button below to continue."
					disableEscapeKeyDown={true}
				>
					<DialogTitle id="Are you still there?">{"Are you still there?"}</DialogTitle>
					<DialogContent>
						<DialogContentText id="You have been inactive for a while. Please click the button below to continue.">
							{"You have been inactive for a while. Please click the button below to continue."}
						</DialogContentText>
					</DialogContent>
					<DialogActions>
						<BeegruButton
							variant="contained"
							flavor={theme?.palette.mode === "dark" ? "primary" : "primary"}
							size="large"
							onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
								window.location.reload();
							}}
							name="continue"
							type="button"
						>
							{"Continue"}
						</BeegruButton>
					</DialogActions>
				</Dialog>

				{/* {showChatFab ? (
          <ChatFab
            session={session}
            dispatch={dispatch}
            profile_context={profile_context}
            chat_states={chatStates ? chatStates : chat_states}
          />
        ) : null} */}

				{noLayoutFooter ? undefined : (
					<div ref={layoutFooterRef}>
						<LayoutFooter
							variant={pinnedFooter ? "pinned" : undefined}
							session={session}
							dispatch={dispatch}
							profile_context={profile_context}
							new_listing_url={new_listing_url ? new_listing_url : ""}
							new_business_profile_url={new_business_profile_url ? new_business_profile_url : ""}
							propertyData={propertyData}
							serviceData={serviceData}
							bottom_nav_type={bottom_nav_type ? bottom_nav_type : ""}
							newPropertyData={newPropertyData as StateInterface["new_property_data"]}
							studio={studio as StateInterface["is_studio"]}
							newServiceData={newServiceData as StateInterface["new_service_data"]}
							search_filter_state={search_filter_state as StateInterface["search_filters_state"]}
							searchanimationsettings={searchanimationsettings as StateInterface["search_animation_settings"]}
							searchLocation={searchLocation ? searchLocation : null}
							theme={theme as Theme}
						/>
					</div>
				)}
			</div>
		</React.Fragment>
	);
};

export default withRouter(Layout);
